import { useQuery } from "react-query";
import { apiClient } from "../config/axios";

const fetchTwitterProfileStatus = async () => {
  const { data } = await apiClient.get(
    "api/auth/twitter/connect-profile-status"
  );
  return data?.success;
};

export const useTwitterProfileStatus = () => {
  return useQuery("[twitterProfileStatus]", fetchTwitterProfileStatus, {
    staleTime: 1000,
  });
};
