import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../config/useAuth";

const ProtectedRoute = () => {
  const user = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inviteToken = queryParams.get("inviteToken");

  useEffect(() => {
    if (inviteToken) {
      localStorage.setItem("inviteToken", inviteToken);
      localStorage.setItem("redirectTo", location.pathname);
    }
  }, [inviteToken]);

  if (!user) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return <Outlet />;
};

export { ProtectedRoute };
