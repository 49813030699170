import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { GrDownload } from 'react-icons/gr';
import { FaApple, FaWindows } from 'react-icons/fa';
import RecantLogo from '../../assets/images/RecantNewLogo.png';

const BeeperComponent = ({ handleCloseModal, showModal }) => {
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      className="flex items-center justify-center z-50"
      backdrop="static"
      keyboard={false}
    >
      <div className="w-full flex flex-col items-center justify-center bg-gray-100 rounded-lg p-6 shadow-lg">
        {/* Logo */}
        <div className="mb-6">
          <img src={RecantLogo} alt="Logo" className="h-12" />
        </div>

        {/* Download Icon */}
        <div className="mb-4 text-primaryColor">
          <GrDownload fontSize={40} />
        </div>

        {/* Heading */}
        <div className="text-center px-8 mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Get Started</h2>
          <p className="text-sm text-gray-600 mt-2 font-normal">
            To begin, download and set up Recant on your desktop. Recant is
            compatible with Mac and Windows.
          </p>
        </div>

        {/* Download Instructions */}
        <div className="text-sm text-gray-700 text-left px-6">
          <ol className="space-y-3 list-decimal list-inside">
            <li>
              Download the app for your platform:
              <div className="flex justify-center space-x-4 mt-4">
                {/* Mac Download Button */}
                <a
                  href="path-to-your-mac-app.dmg"
                  download
                  className="py-3 px-6 bg-blue-500 text-primaryColor rounded-lg font-medium shadow-lg hover:bg-blue-600 transition duration-300 flex items-center space-x-3"
                >
                  <FaApple className="text-primaryColor text-2xl" />
                  <span>Download for Mac (.dmg)</span>
                </a>
                {/* Windows Download Button */}
                <a
                  href="path-to-your-windows-app.exe"
                  download
                  className="py-3 px-6 bg-green-500 text-primaryColor rounded-lg font-medium shadow-lg hover:bg-green-600 transition duration-300 flex items-center space-x-3"
                >
                  <FaWindows className="text-primaryColor text-2xl" />
                  <span>Download for Windows (.exe)</span>
                </a>
              </div>
            </li>
            <li>Sign in and connect your chat apps to Recant.</li>
            <li>Return here and login again.</li>
          </ol>
        </div>

        {/* Footer Button */}
        <div className="mt-8 w-full flex justify-center px-12">
          <button
            onClick={handleCloseModal}
            className="py-2 px-6 bg-purple-600 text-white rounded-lg font-bold shadow-lg hover:bg-purple-700 transition duration-300 bg-primaryColor w-full"
          >
            &larr; Back to Dashboard
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BeeperComponent;
