import React from "react";
import PaymentCompletedCard from "../../common/PaymentCompletedCard";
import { Link } from "react-router-dom";
import PlainHeader from "../../common/PlainHeader";

const PaymentSuccessful = () => {
  return (
    <>
      <PlainHeader />
      <div className="flex flex-col h-screen font-DMSans justify-center items-center lg:px-72 px-14 pb-14 pt-20">
        <div className="relative z-10 max-w-[700px] ">
          <div className="Rectangle23 rounded-3xl shadow border border-purple-500 backdrop-blur-2xl   p-4">
            <div className="md:p-20">
              <PaymentCompletedCard />
              <button
                className="bg-primaryColor flex py-2 px-5 text-white justify-center rounded shadow w-fit m-auto my-3 uppercase hover:bg-[#000]"
                onClick={() =>
                  window.open(
                    "https://docs.google.com/forms/d/e/1FAIpQLSfHGhESee37DqbgYSKMAULvjoTXDYeC5YBOUkVe-ajtWWOTww/viewform",
                    "_blank"
                  )
                }
              >
                Take a mini course
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessful;
