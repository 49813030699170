import React from "react";
import { NavLink } from "react-router-dom";
import { RiApps2Fill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { MdOutlineContactSupport, MdLogout } from "react-icons/md";
import { IoMdCloudUpload } from "react-icons/io";
import recantLogo from "../../../assets/images/RecantNewLogo.png";
import { useNavigate } from "react-router-dom";
import useLogout from "../../../hooks/useLogoutUsers";
import { useUser } from "../../../components/DataContext";

export const onBoardingSidebaritems = [
  {
    key: 1,
    link: "/dashboard",
    icon: RiApps2Fill,
    title: "Dashboard",
  },
  {
    title: "Report to WWYMS",
    icon: IoMdCloudUpload,
    link: "/report-to-wwyms",
  },
  {
    key: 3,
    link: "/user-information",
    icon: FaUser,
    title: "User Information",
  },
  {
    key: 4,
    link: "/support",
    icon: MdOutlineContactSupport,
    title: "Support",
  },
].filter(Boolean);

const LeftSideBar = ({ selected }) => {
  const navigate = useNavigate();
  const logoutUser = useLogout();
  const { user } = useUser();

  return (
    <div className="left-sidebar fixed top-0 left-0 h-full flex flex-col bg-[#eef5f8] w-64 md:w-20 lg:w-64 p-4 items-center">
      <ul className="!p-0 flex flex-col gap-4">
        <div className="mt-20 mb-3">
          <img
            src={recantLogo}
            alt="Recant Logo"
            className="w-24 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        {onBoardingSidebaritems.map((item, i) => (
          <li key={i} className="w-full">
            <NavLink
              to={item.link}
              className={({ isActive }) =>
                `flex items-center gap-3 text-primaryColor p-2 rounded-lg ${
                  isActive
                    ? "bg-[#2c505f] text-white"
                    : "hover:bg-primaryColor hover:text-white"
                }`
              }
            >
              <item.icon className="w-4 h-4" />
              <span className="hidden lg:inline">{item.title}</span>
            </NavLink>
          </li>
        ))}
      </ul>
      {console.log("user", user)}
      {user && (
        <div className="absolute bottom-6 w-full  border-t-[1px] border-[#dadada]  mt-4 lg:mt-0">
          <button
            onClick={() => logoutUser()}
            className="flex items-center gap-3 font-bold underline text-primaryColor hover:bg-gray-100 p-3 rounded-lg w-full lg:w-[220px]"
          >
            <MdLogout className="w-5 h-5" />
            <span className="hidden lg:inline">Logout</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default LeftSideBar;
