export const FAQData = [
  {
    question: "What about privacy concerns and data control?",
    answer:
      "We take privacy very seriously. Recant.ai only filters for content you deem to be abusive or inappropriate and does not store any personal or sensitive data beyond what is necessary for the functionality of Recant.ai. All data is encrypted and handled in accordance with privacy laws. Anything not flagged but fetched in order for the AI to contextualise the conversation, is completely anonymised. Recant.ai cannot simply eavesdrop on an individual’s personal communications.  ",
  },
  {
    question: "Will this app access and monetise my messages?",
    answer:
      "No. This app does not monetise user data. Its sole purpose is to protect members from abusive communications. Unlike some platforms, we do not track or sell your data for profit. We only track the rate at which communications are abusive, and check the quarantined messages for threats to our members’ safety.",
  },
  {
    question: "Can the AI make mistakes and flag non-abusive messages?",
    answer:
      "While AI has advanced significantly, it is not perfect. There may be instances where non-abusive messages are flagged. However, Recant.ai allows for easy manual review, giving members the final say on what is filtered.",
  },
  {
    question:
      "How does Recant.ai handle cultural sensitivity in communications?",
    answer:
      "Recant.ai has been trained to consider various cultural contexts to reduce false positives. It also allows members to set specific parameters on a scale around that which they deem to be appropriate or not. It is continuously updated to improve its understanding and adapt to nuances and diverse forms of communication.",
  },
  {
    question:
      "Can platforms flag or shadowban me if I log in from multiple IP addresses using this app?",
    answer:
      "Logging in from multiple IP addresses may sometimes trigger platform security checks, but this is unrelated to Recant.ai itself. We recommend avoiding frequent IP changes to minimize this risk. Upon connecting, you may get a notification from the platform that a login attempt occurred from a region in which our servers operate and to verify the login attempt was you. This region can vary but will usually be from Ashburton, Virginia, USA, where Amazon Web Services hosts the cloud server.",
  },
  {
    question:
      "Are there any ethical concerns or oversight with the use of this technology?",
    answer:
      "Recant.ai complies with ethical guidelines and has oversight processes to ensure it is not misused. We are committed to transparency and accountability and all flagged content can be reviewed by the member if they wish.",
  },
  {
    question:
      "How does Recant.ai address compliance with various platform policies?",
    answer:
      "Recant.ai is designed to comply with all major platform policies and is built on their development platforms. It is designed to enhance user safety by filtering abusive content within the guidelines set by the platforms.",
  },
  {
    question:
      "Could I miss important messages or opportunities if Recant.ai incorrectly flags communications as abusive?",
    answer:
      "Recant.ai uses advanced algorithms to filter communications based on abusive patterns, but all flagged messages can still be reviewed manually. This ensures no important communication is lost.",
  },
  {
    question:
      "What if it flags messages from people I know, such as my partner?",
    answer:
      "Recant.ai allows members to ‘whitelist’ people from whom any form of communication is acceptable. For example, a member might send pictures to their partner or content subscribers. Once a user handle is whitelisted, our filters will never see any communications from these accounts. ",
  },
  {
    question: "Will I risk losing my account by using this app?",
    answer:
      "No, Recant.ai operates within the terms and conditions of the platforms you use. However, members are advised to follow all platform guidelines to avoid issues such as account suspension.",
  },
];
