import React, { useState } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";

const Accordion = ({ question, answer, title, footer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen((prev) => !prev);
  };

  const renderAnswer = () => {
    if (Array.isArray(answer)) {
      return (
        <ul className="list-disc pl-5">
          {answer.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }

    if (Array.isArray(answer?.answer)) {
      return (
        <ul className="pl-5 flex flex-col gap-4">
          {answer.answer.map((item, index) => {
            const [boldText, regularText] = item.points.split(":");
            return (
              <div key={index} className="mt-2 flex flex-col gap-2">
                <li>
                  {index + 1}. <span className="font-bold">{boldText} : </span>
                  {regularText}
                </li>
                <p className="mt-1 ml-4 lg:ml-16 text-gray-700 ">
                  {item.description}
                </p>
              </div>
            );
          })}
        </ul>
      );
    }

    return <p>{answer}</p>;
  };

  return (
    <div
      className={`border p-4 rounded-lg shadow-md ${
        isOpen ? "text-[#184c58] border-[#0c2f38] " : ""
      }`}
    >
      <div className="flex justify-between items-center">
        <h3 className={`text-lg ${isOpen && "font-bold"}`}>{question}</h3>
        <button
          onClick={toggleAnswer}
          className="text-xl focus:outline-none"
          aria-label="Toggle Answer"
        >
          {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </button>
      </div>

      {isOpen && (
        <div className="mt-2 text-gray-700">
          {title && <p className="mb-2">{title}</p>}
          {renderAnswer()}
          {footer && <p className="mt-8 ">{footer}</p>}
        </div>
      )}
    </div>
  );
};

export default Accordion;
