import React, { useState, useEffect } from "react";

const ChatBubbleDropdown = ({
  options,
  onSelect,
  initialSelected,
  disabled,
  setDropDownOpen,
}) => {
  const [selectedOption, setSelectedOption] = useState(initialSelected || "");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeParent, setActiveParent] = useState(null);
  const [activeChild, setActiveChild] = useState(null);
  const [activeGrandChild, setActiveGrandChild] = useState(null);

  useEffect(() => {
    if (options.length > 0 && !initialSelected) {
      setSelectedOption(options[0].label);
    } else {
      setSelectedOption(initialSelected);
    }
  }, [options, initialSelected]);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
    if (setDropDownOpen) setDropDownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    if (disabled) return;

    if (option.children) {
      setActiveParent((prev) => (prev === option.key ? null : option.key));
      setActiveChild(null);
      setActiveGrandChild(null);
      return;
    }

    setSelectedOption(option.label);
    if (onSelect) onSelect({ key: option.key, label: option.label });
    setIsDropdownOpen(false);
  };

  const handleChildClick = (childOption) => {
    if (childOption.key === "nested_no") {
      setActiveParent("no");
      return;
    }
    setSelectedOption(childOption.label);
    if (onSelect) onSelect({ key: childOption.key, label: childOption.label });

    if (childOption.key === "nested_yes") {
      setActiveChild(childOption.key);
      setActiveGrandChild(null);
    } else {
      if (childOption.children) {
        setActiveChild(childOption.key);
        setActiveGrandChild(null);
      } else {
        setActiveChild(null);
        setActiveParent(null);
        setIsDropdownOpen(false);
      }
    }
  };

  const handleGrandChildClick = (grandChildOption) => {
    setSelectedOption(grandChildOption.label);
    if (onSelect)
      onSelect({ key: grandChildOption.key, label: grandChildOption.label });

    setActiveGrandChild(grandChildOption.key);
    setActiveChild(null);
    setActiveParent(null);
    setIsDropdownOpen(false);
  };

  // Render the parent options
  const renderParentOptions = (options) => {
    return options.map((option) => {
      const isParentSelected =
        activeParent === option.key ||
        activeChild === option.key ||
        activeGrandChild === option.key;
      const isParentOpen = activeParent === option.key;

      return (
        <div key={option.key}>
          <div
            onClick={() => handleOptionClick(option)}
            className={`px-4 py-2 cursor-pointer hover:bg-[#85969B] hover:text-white ${
              disabled ? "cursor-not-allowed" : ""
            } flex justify-between items-center ${
              option.key === selectedOption ? "bg-primaryColor text-white" : ""
            }`}
          >
            <span className="text-sm sm:text-base">{option.label}</span>
            {option.children && (
              <svg
                className={`w-4 h-4 ml-2 text-primaryColor transform transition-transform duration-300 ${
                  isParentOpen ? "rotate-90" : "rotate-0"
                }${option.key === selectedOption ? " text-white" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </div>
          {option.children && isParentOpen && (
            <div className="ml-4">{renderChildOptions(option.children)}</div>
          )}
        </div>
      );
    });
  };

  const renderChildOptions = (children) => {
    return children.map((child) => {
      const isChildOpen = activeChild === child.key;

      return (
        <div key={child.key}>
          {child.key === "nested_yes" && (
            <div className="px-4 py-2 text-xs sm:text-sm text-gray-600">
              By selecting this option, you are offering to remove their
              message and refrain from reporting it in any way if they pay.
              Do you accept and wish to continue?
            </div>
          )}

          <div
            onClick={() => handleChildClick(child)}
            className={`px-4 py-2 cursor-pointer hover:bg-[#85969B] hover:text-white ${
              disabled ? "cursor-not-allowed" : ""
            } ${
              child.label === selectedOption ? "bg-primaryColor text-white" : ""
            } flex justify-between items-center`}
          >
            <span className="text-sm sm:text-base">{child.label}</span>

            {child.children && (
              <svg
                className={`w-4 h-4 ml-2 text-primaryColor transform transition-transform duration-300 ${
                  isChildOpen ? "rotate-90" : "rotate-0"
                }${child.label === selectedOption ? " text-white" : ""}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </div>

          {child.children && isChildOpen && (
            <div className="ml-4">
              {renderGrandChildOptions(child.children)}
            </div>
          )}
        </div>
      );
    });
  };

  const renderGrandChildOptions = (grandChildren) => {
    return (
      <>
        <div className="px-4 py-2 text-xs sm:text-sm text-gray-600">
          We offer you tools for reporting abuse. Would you like
          them to be warned about potential reporting?
        </div>
        {grandChildren.map((grandChild) => (
          <div key={grandChild.key}>
            <div
              onClick={() => handleGrandChildClick(grandChild)}
              className={`px-4 py-2 cursor-pointer hover:bg-[#85969B] hover:text-white ${
                disabled ? "cursor-not-allowed" : ""
              } ${
                grandChild.label === selectedOption
                  ? "bg-primaryColor text-white"
                  : ""
              }`}
            >
              <span className="text-sm sm:text-base">{grandChild.label}</span>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="relative max-w-xl mx-auto mt-4">
      <div
        className={`relative bg-gradient-mild text-white p-4 rounded-full font-semibold text-center cursor-pointer flex items-center justify-between ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={disabled ? undefined : toggleDropdown}
      >
        <span>{selectedOption}</span>

        <svg
          className={`w-5 h-5 ml-2 text-white transform transition-transform duration-300 ${
            isDropdownOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>

      {isDropdownOpen && (
        <div
          className="absolute top-full left-0 w-full z-50 bg-white text-primaryColor mt-1 rounded shadow-lg transition-all duration-300 ease-in-out max-h-60"
          style={{
            overflowY: "auto",
          }}
        >
          {renderParentOptions(options)}
        </div>
      )}
    </div>
  );
};

export default ChatBubbleDropdown;
