import React from "react";
import { FiveElements, WhyAmIGettingThisOptions } from "./index.ts";
import MainLayout from "../../common/MainLayout/index.js";
import Accordion from "../../common/Accordion/index.js";

const WhyAmIGettingThis = () => {
  return (
    <MainLayout containerMarginTop="190px">
      <div className="rounded-[3px] p-4 max-w-5xl mx-auto ">
        <h4 className="mb-4 text-center text-2xl font-bold">
          Why am i getting this?
        </h4>
        {WhyAmIGettingThisOptions.map((option, index) => (
          <div key={index} className="mb-2">
            <Accordion
              question={option.question}
              answer={option.answer}
              title={option.answer.title}
              footer={option.answer.footer}
            />
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default WhyAmIGettingThis;
