import { useQuery } from "react-query";
import { apiClient } from "../config/axios";

export const useUserDetails = () => {
  return useQuery({
    queryKey: ["userDetails"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/user");
      return data;
    },
    onError: () => {
      console.log("Error fetching user details");
    },
    staleTime: 300000,
    cacheTime: 600000,
  });
};
