export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const ageOptions = [
  { value: "13-16", label: "13-16" },
  { value: "16-24", label: "16-24" },
  { value: "24-32", label: "24-32" },
  { value: "32-40", label: "32-40" },
  { value: "40-48", label: "40-48" },
  { value: "48+", label: "48+" },
];

export const socialMediaOptions = [
  { value: "facebook", label: "Facebook" },
  { value: "instagram", label: "Instagram" },
  { value: "twitter", label: "Twitter/X" },
  { value: "linkedin", label: "Linkedin" },
  { value: "snapchat", label: "Snapchat" },
  { value: "tikTok", label: "TikTok" },
  // { value: "onlyfans", label: "Onlyfans" },
  { value: "other", label: "Other" },
  // {
  //   value: "nosocial",
  //   label:
  //     "I don't have any social media or publishing accounts (please discontinue survey)",
  // },
];

export const mediaUsesOptions = [
  {
    value: "Work - self promotion in my career or of my work",
    label: "Work - self promotion in my career or of my work",
  },
  {
    value: "Personal - for social interactions",
    label: "Personal - for social interactions",
  },
  {
    value: "Work - just to keep up with what's relevant for my career",
    label: "Work - just to keep up with what's relevant for my career",
  },
  { value: "other", label: "Other" },
  {
    value: "My business - we promote our products on social accounts",
    label: "My business - we promote our products on social accounts",
  },
];

export const followerOptions = [
  { value: "Less than 1k followers", label: "Less than 1k followers" },
  { value: "1k - 5k total followers", label: "1k - 5k total followers" },
  { value: "5k - 20k total followers", label: "5k - 20k total followers" },
  { value: "20k - 100k total followers", label: "20k - 100k total followers" },
  {
    value: "100k - 500k total followers",
    label: "100k - 500k total followers",
  },
  {
    value: "500k + total followers",
    label: "500k + total followers",
  },
  {
    value: "I have no aspirations for or meaningful 'following'",
    label: "I have no aspirations for or meaningful 'following'",
  },
];

export const socialMediaPeople = [
  { value: "from_stranger", label: "From a stranger?" },
  { value: "from_someone_you_know", label: "Someone you know?" },
  {
    value: "from_someone_you_work_with",
    label: "Someone you work with or know through professional networks?",
  },
  {
    value: "from_someone_anonymously",
    label: "Someone anonymously/obscuring their identity?",
  },
  { value: "of_sexual_nature", label: "Sexual nature?" },
  {
    value: "of_bullying_nature",
    label: "Violent, threatening or bullying nature?",
  },
];

export const directMessageOption = ["Yes", "No", "Rather not say"];

export const messageType = [
  {
    value: "identifiable_stranger",
    label: "Identifiable stranger?",
  },
  {
    value: "known_person",
    label: "Known person?",
  },
  {
    value: "someone_anonymously",
    label: "Someone anonymously?",
  },
];

export const messageFrequency = [
  "Never",
  "Once or twice",
  "Rarely",
  "Regularly",
  "Often",
];

export const responseMessage = [
  {
    value: "hate",
    label:
      "I hate it - it makes me angry, sad, icky, depressed, grossed out or violated and I generally...",
  },
  {
    value: "dislike",
    label: "I dislike it - but it doesn't really affect me and I",
  },
  { value: "flattering", label: "I find it flattering - and I generally..." },
  {
    value: "infrequent",
    label:
      "I don't even have the headspace to consider it or it doesn't happen often enough to care about and I generally...",
  },
  { value: "indifferent", label: "Indifferent" },
  {
    value: "not_applicable",
    label: "It doesn't at all so this isn't applicable",
  },
];

export const responseAction = [
  "Ignore it",
  "Block the account",
  "Respond assertively and warn them off",
  "Indicate I might publish or share their comms",
  "Actually have published, shared or shamed the sender",
  "Respond positively and entertain the interactions",
];

export const filterResponse = [
  { value: "yes_and", label: "Yes, and" },
  { value: "no_and", label: "No, and " },
  { value: "yes_but", label: "Yes, but" },
  { value: "no_but", label: "No, but" },
];

export const filterReason = [
  "It did not work",
  "It worked well",
  "I'm still using it",
  "I think I probably should/will",
  "I think I probably should/will",
  "I wouldn't bother in future",
];
