import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useMutation } from 'react-query';
import { apiClient } from '../../config/axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import OnBoardingLayout from '../../layouts/OnBoardingLayout';
import FileUpload from '../../common/FileUpload';
import ButtonField from '../../common/ButtonField';

const reportFormSchema = z.object({
  name: z.string().optional(),
  socialMedia: z.string().optional(),
  platform: z.string().min(1, { message: 'Platform is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  date: z.string().optional(),
});

const ReportToWWYMS = () => {
  const [uploadedProfilePicture, setUploadedProfilePicture] = useState([]);
  const [photosError, setPhotosError] = useState('');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(new Date());
  const [isMutationLoading, setIsMutationLoading] = useState(false);
  const navigate = useNavigate();

  const generateReportMessage = async ({
    recipientName,
    userHandle,
    messageNature,
    date,
  }) => {
    const prompt = `
      On ${date.toDateString()}, a report by ${
      recipientName || 'anon'
    } alleges that @${userHandle} sent a message of "${messageNature}" nature on Instagram.
Despite being presented with an opportunity to recant their message and make amends by contributing a small donation, @${userHandle} declined to take action. As a result, ${
      recipientName || 'anon'
    } has filed this report. Also summarize the message within 100 words.`;

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer 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`,
      },
      body: JSON.stringify({
        model: 'gpt-4',
        messages: [{ role: 'user', content: prompt }],
      }),
    });

    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }

    const data = await response.json();

    if (!data?.choices?.[0]?.message?.content) {
      throw new Error('Unexpected API response structure');
    }

    return data.choices[0].message.content;
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(reportFormSchema),
  });
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const onSaveSubmit = async (data) => {
    try {
      setLoading(true);
      const reportMessage = await generateReportMessage({
        recipientName: data.name || 'anon',
        userHandle: data.socialMedia,
        messageNature: data.description,
        date: new Date(),
      });

      if (reportMessage) {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('socialMedia', data.socialMedia);
        formData.append('platform', data.platform);
        formData.append('generatedMessage', reportMessage);
        formData.append('description', data.description);
        formData.append('date', new Date().toISOString().split('T')[0]);

        // Append multiple images for 'trollsPhotos'
        if (uploadedProfilePicture && uploadedProfilePicture.length > 0) {
          uploadedProfilePicture.forEach((photo, index) => {
            formData.append(`trollsPhotos`, photo);
          });
        }

        mutation.mutate(formData);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Error generating report message: ' + error.message);
    }
  };

  const mutation = useMutation(
    (formData) => apiClient.post(`/api/troll-register`, formData),
    {
      onSuccess: () => {
        toast.success('Troll registered successfully');
        navigate('/');
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || 'An error occurred');
      },
    }
  );

  useEffect(() => {
    if (!loading && mutation.isLoading) {
      setIsMutationLoading(true);
    } else {
      setIsMutationLoading(false);
    }
  }, [loading, mutation.isLoading]);

  return (
    <OnBoardingLayout selected={1}>
      <form
        onSubmit={handleSubmit(onSaveSubmit)}
        className="py-10 sm:px-4 md:px-10 xl:px-64 flex flex-col gap-3"
      >
        <div className="px-2">
          <div className="flex flex-wrap gap-4 mb-4">
            <div className="flex-1">
              <label>Your name (optional):</label>
              <input
                name="name"
                {...register('name')}
                placeholder="Enter Name"
                className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
              />
            </div>
            <div className="flex-1">
              <label>Offenders social media handle:</label>
              <input
                name="socialMedia"
                {...register('socialMedia', {
                  required: 'Handle is required',
                })}
                placeholder="Offender's Social Media Handle"
                className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 mb-4">
            <div className="flex-1">
              <div>Date of offence</div>
              <DatePicker
                onChange={handleChange}
                value={value}
                className="h-[48px] w-[100%] rounded-[10px] bg-[#f3f3f0] text-[14px] mt-2"
                calendarClassName="rounded-lg shadow-lg"
                clearIcon={null}
                style={{ border: 'none' }}
              />
              {errors.date && (
                <span className="text-red !mt-3">{errors.date.message}</span>
              )}
            </div>
            <div className="flex-1">
              <label>Platform:</label>
              <input
                name="platform"
                {...register('platform')}
                placeholder="Enter Platform where offence took place"
                className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
              />
              {errors.platform && (
                <span className="text-red !mt-3">
                  {errors.platform.message}
                </span>
              )}
            </div>
          </div>

          <div>
            <div className="mb-2">
              Upload Offender’s Profile Picture/Picture of Their Page
            </div>
            <FileUpload
              onFilesChange={setUploadedProfilePicture}
              photosError={photosError}
              placeholder={'Drag & drop a profile picture'}
              isMultiplePicture={true}
            />
          </div>

          <div>
            <div className="mb-2">Describe what happened</div>
            <textarea
              {...register('description', {
                required: 'Description is required',
              })}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            />
            {errors.description && (
              <span className="text-red !mt-3">
                {errors.description.message}
              </span>
            )}
          </div>

          <ButtonField
            label={'SUBMIT'}
            isLoading={loading || isMutationLoading}
          />
        </div>
      </form>
    </OnBoardingLayout>
  );
};

export default ReportToWWYMS;
