import React from "react";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SupportUserSchema } from "../../validation/supportUser";
import { useMutation } from "react-query";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const SupportUser = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(SupportUserSchema) });
  const navigate = useNavigate();

  const mutation = useMutation((formData) => {
    return apiClient.post(`api/user`, { support: formData?.support });
  });

  const onIssueSubmit = async (data) => {
    try {
      await mutation.mutateAsync(data);
      navigate("/dashboard");
      toast.success("Data added successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <OnBoardingLayout selected={1}>
      <div className="bg-white w-full px-3 pt-3 h-[90.8vh]">
        <h1 className="text-grey text-2xl font-sans font-semibold mt-[55px] flex items-center justify-center mb-28">
          User Support
        </h1>
        <form
          className="lg:mx-48  mx-10 mt-6 flex flex-col "
          onSubmit={handleSubmit(onIssueSubmit)}
        >
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="">
              Please describe how we can help
            </Form.Label>
            <Form.Control
              name="support"
              className="mt-2 !placeholder-[#cfcdcd]"
              as="textarea"
              {...register("support")}
              rows={3}
              placeholder="Please describe your issue or question in detail..."
            />
            {errors?.support && (
              <div className="mt-2 text-[#CC2828]">
                {errors?.support?.message}
              </div>
            )}
          </Form.Group>
          <div className="relative w-full flex items-center justify-center">
            {mutation?.isLoading && (
              <div className="absolute inset-0 flex items-center justify-center right-3 top-3">
                <Loader />
              </div>
            )}
            <button
              disabled={mutation?.isLoading}
              className={`login-btn  flex justify-center items-center bg-primaryColor  hover:bg-hoverPrimary ${
                mutation?.isLoading ? "cursor-not-allowed opacity-50" : ""
              }`}
              type="submit"
            >
              <div>Submit</div>
            </button>
          </div>
        </form>
      </div>
    </OnBoardingLayout>
  );
};

export default SupportUser;
