import React from "react";
import { CustomMessageData } from "../../constant/CustomeMessage";

const CustomMessage = ({ setSelectedMessage }) => {
  const handleMessageSelect = (event) => {
    setSelectedMessage(event.target.innerText);
  };
  return (
    <div className="flex flex-wrap">
      {CustomMessageData.map((message, index) => (
        <div key={index} className="mb-4 flex w-1/3 p-2">
          <button
            className="text-sm  border-[1px] border-[#e1e0e0] p-3 rounded-[4px] bg-white hover:border-[#a9a9a9]"
            onClick={handleMessageSelect}
          >
            {message}
          </button>
        </div>
      ))}
    </div>
  );
};

export default CustomMessage;
