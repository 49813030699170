import React from "react";
import "./../Login/Login.css";
import { Link } from "react-router-dom";
import PlainHeader from "../../../common/PlainHeader";
import { useForm } from "react-hook-form";
import { apiClient } from "../../../config/axios";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { useMutation } from "react-query";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const forgotPasswordMutation = useMutation((formData) =>
    apiClient.post(`api/auth/forgot-password`, formData)
  );

  const onSubmit = async (formData) => {
    try {
      await forgotPasswordMutation.mutateAsync(formData);
      toast.success("Reset password email sent successfully!");
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Failed to send reset email"
      );
    }
  };

  return (
    <>
      <PlainHeader />
      <div className="login-container">
        <div className="login-wrapper">
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-8">
              <div className="flex flex-col items-center mb-4">
                <h2 className="mb-3">Forgot your password?</h2>
                <p className="mb-2">You can reset your password here</p>
              </div>
              <div className="w-full max-w-sm mx-auto">
                <input
                  name="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  })}
                  placeholder="Enter your email"
                  className="h-12 w-full p-2 rounded-md bg-[#f3f3f0]"
                />
                {errors?.email?.message && (
                  <p className="text-red-600 mt-2">{errors?.email?.message}</p>
                )}
              </div>
              <div className="mt-4 w-full max-w-sm mx-auto">
                <div className="relative flex items-center justify-center">
                  {forgotPasswordMutation.isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Loader />
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={forgotPasswordMutation.isLoading}
                    className={`h-12 w-full p-2 rounded-md text-white bg-primaryColor hover:bg-hoverPrimary ${
                      forgotPasswordMutation.isLoading
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="mt-4">
            Back to{" "}
            <Link
              className="text-primaryColor font-semibold cursor-pointer no-underline"
              to="/login"
            >
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
