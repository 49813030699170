import React, { useState } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import LeftSideBar from "./LeftSideBar";
import ResponsiveDrawer from "../../components/Admin/Drawer";
import TopNavBar from "./TopNavBar";
import { onBoardingSidebaritems } from "./LeftSideBar";
import { RxHamburgerMenu } from "react-icons/rx";
import { toast } from "react-toastify";
import { useUserDetails } from "../../hooks/useUserDetails";
import axios from "axios";
import { useUser } from "../../components/DataContext";
import { CiLink } from "react-icons/ci";
import { useMutation } from "react-query";
import { apiClient } from "../../config/axios";
import { useLocation } from "react-router-dom";

axios.defaults.withCredentials = true;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

const OnBoardingLayout = ({ selected, children, userId }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("1024"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useUser();
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { data: connectedProfile } = useUserDetails();

  const mutation = useMutation(
    () =>
      apiClient.post(
        `${process.env.REACT_APP_BACKEND_URI}/invites/create/${connectedProfile.id}`,
        {
          invitedTo: location.pathname.slice(
            location.pathname.lastIndexOf("/") + 1
          ),
        }
      ),
    {
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong!");
      },
    }
  );

  const handleInvite = async () => {
    if (!user) return;

    try {
      const response = await mutation.mutateAsync();
      const generatedLink = response?.data?.inviteLink;

      try {
        await navigator.clipboard.writeText(generatedLink);
        toast.success("Invitation link copied to clipboard!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } catch (err) {
        toast.error("Failed to copy the invite link: " + err.message);
      }
    } catch (error) {
      toast.error(error?.message || "Failed to send invitation.");
    }
  };

  const InviteButton = () => (
    <button
      onClick={handleInvite}
      className={`py-[6px] px-6  bg-primaryColor text-white rounded-[10px] cursor-pointer  flex gap-2 items-center justify-center${
        !user && "opacity-30"
      }`}
    >
      <CiLink fontSize={30} /> Invite a mate
    </button>
  );

  return (
    <div>
      <div
        className={`flex py-3 ${
          isDesktop ? "px-8" : "px-[14px]"
        } justify-between fixed bg-white border-b-2 border-greyLight w-full z-40 items-center ${
          !isDesktop ? "flex-row-reverse" : ""
        }`}
      >
        <div className="flex gap-3">
          {!isDesktop ? (
            <RxHamburgerMenu
              className="text-primaryColor mt-1"
              size={23}
              onClick={handleDrawerToggle}
            />
          ) : (
            <InviteButton />
          )}
        </div>
        <div>{!isDesktop ? <InviteButton /> : <TopNavBar />}</div>
      </div>

      <div className="w-full flex">
        {isDesktop ? (
          <LeftSideBar selected={selected} />
        ) : (
          <ResponsiveDrawer
            open={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            sideBarItems={onBoardingSidebaritems}
          />
        )}
        <div className="flex flex-col w-full pt-[73px]">
          <div className="lg:ml-64">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingLayout;
