import React from "react";
import defaultImage from "../../assets/images/defaultImage.png";
import { useMediaQuery, useTheme } from "@mui/material";

const MessageUserList = ({ userDetail, getMessages, conversationDetail }) => {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("1024"));
  return (
    <div className="lg:mx-2 mt-[9px]">
      {userDetail.users.length === 0 ? (
        <div
          className={`side-h flex flex-row justify-between items-center text-center rounded-lg p-2 m-3 mt-1`}
        >
          <div
            className={`flex flex-row justify-center rounded-lg p-2 m-3 mt-1 !bg-primaryColor text-white text-sm w-full`}
          >
            No messages found
          </div>
        </div>
      ) : (
        <div className="h-[calc(100vh-10rem)] overflow-y-auto">
          {userDetail.users.map((user, index) => (
            <div
              className={`flex flex-row gap-2  items-center lg:rounded-lg px-[11px] lg:!px-2 py-3 cursor-pointer mb-2 !text-[#000] ${
                conversationDetail.selectedUser.id === user.id
                  ? "bg-primaryColor text-white"
                  : ""
              }`}
              onClick={() => getMessages(user)}
              key={index}
            >
              <div className="">
                <img
                  className="rounded-full w-8 h-8"
                  src={user.profile_pic || defaultImage}
                  alt="User"
                />
              </div>
              {isDesktop && (
                <div className="flex flex-col flex-grow">
                  <div className="font-semibold">
                    {user.first_name} {user.last_name}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MessageUserList;
