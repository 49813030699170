import React from "react";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import { TERMS_AND_CONDITION } from "../../constant/TermsAndCondition";
import { termsAndConditionFormattedContent } from "../../utils/termsAndConditionFormattedContent";
import PlainHeader from "../../common/PlainHeader";

const TermsCondition = () => {
  return (
    <div>
      <PlainHeader />
      <div className="container pt-36 pb-6 text-baseColor">
        <div className="">
          <h5 className="mb-6 flex font-extrabold">
            Recant.ai Terms and Conditions of Service
          </h5>
          <section className="flex flex-col gap-5">
            {TERMS_AND_CONDITION.map((item, outerIndex) => {
              return (
                <div key={outerIndex} className="flex flex-col gap-3">
                  <h6 className="font-bold">
                    {outerIndex + 1}. {item.title}
                  </h6>
                  {item.content.map((content, index) => {
                    return (
                      <div className="ml-3" key={index}>
                        <span className="font-semibold">
                          {outerIndex !== 0 &&
                            `${outerIndex + 1}.${index + 1}.`}
                        </span>
                        {"  "}
                        {termsAndConditionFormattedContent(content)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
