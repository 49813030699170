import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/RecantNewLogo.png";
import TopNavBar from "../../layouts/OnBoardingLayout/TopNavBar";

const PlainHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="flex py-[4px] justify-between  fixed  w-full z-20 px-3 bg-white border-b-[2px] border-greyLight ">
      <div className="!pt-[11px] lg:!px-[74px] md:!px-11 flex justify-between w-full">
        <div className="flex">
          <a
            className="nav-logo text-yellow  cursor-pointer mt-[5px] "
            onClick={() => navigate("/")}
          >
            <img src={logo} width={115} />
          </a>
        </div>
        <div>
          <TopNavBar />
        </div>
      </div>
    </div>
  );
};

export default PlainHeader;
