import React from "react";
import { MdCheck } from "react-icons/md";
import defaultImage from "../../assets/images/defaultImage.png";
import { useMediaQuery, useTheme } from "@mui/material";

const SocialMediaPage = ({ socialMediaPage, imageUrl }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div
      className={` flex items-center w-full justify-between  gap-2 mb-3 lg:px-10 sm:!px-[18px] py-3 bg-[#eff6f6] hover:shadow-md
      `}
    >
      <div className={`flex flex-row gap-3  items-center`}>
        <img src={imageUrl || defaultImage} className="h-16 w-16" alt="" />
        <div>
          <h6 className="text-[#000]">{socialMediaPage}</h6>
          <span className="text-sm text-[#9e9ea3]">@{socialMediaPage}</span>
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center font-bold">
        <div className="px-[26px] py-2  text-[#54bc64]  text-[17px]  gap-2 flex items-center border-[1px] border-[#54bc64]">
          {isDesktop ? " Open Messages" : " Open "}
        </div>
      </div>
    </div>
  );
};

export default SocialMediaPage;
