import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ButtonField from "../../common/ButtonField";
import FileUpload from "../../common/FileUpload";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import PlainHeader from "../../common/PlainHeader";

const reportFormSchema = z.object({});

const UploadTroll = () => {
  const [uploadedProfilePicture, setUploadedProfilePicture] = useState([]);
  const [photosError, setPhotosError] = useState("");
  const navigate = useNavigate();

  const { handleSubmit } = useForm({
    resolver: zodResolver(reportFormSchema),
  });

  useEffect(() => {
    if (uploadedProfilePicture.length === 0) {
      setPhotosError("Photos are required");
    } else {
      setPhotosError("");
    }
  }, [uploadedProfilePicture]);

  const onSaveSubmit = () => {
    // Convert a file to Base64
    function fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }

    // Store files as Base64 in localStorage
    async function storeFilesInLocalStorage(key, files) {
      const filesData = await Promise.all(
        files.map(async (file) => {
          const base64 = await fileToBase64(file);
          return { name: file.name, type: file.type, base64 };
        })
      );
      localStorage.setItem(key, JSON.stringify(filesData));
    }

    // Ensure files are selected before calling the store function
    if (uploadedProfilePicture.length > 0) {
      storeFilesInLocalStorage("uploadedProfilePicture", uploadedProfilePicture)
        .then(() => {
          // Redirect after storing file
          navigate("/signup");
        })
        .catch((error) => {
          console.error("Error storing file:", error);
        });
    } else {
      console.warn("No file selected for upload.");
    }
  };

  return (
    <>
      <PlainHeader />
      <div className="container pt-[134px] lg:pt-[180px] ">
        <form
          onSubmit={handleSubmit(onSaveSubmit)}
          className="max-w-xl mx-auto p-6 bg-white rounded-lg lg:shadow-lg"
        >
          <h5 className="mb-12 text-center text-xl font-semibold">
            Upload screenshots of creepy or abusive texts you've recieved and
            we'll publish them on our public reporting register.
          </h5>

          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Upload screenshots here
              </label>
              <FileUpload
                onFilesChange={setUploadedProfilePicture}
                photosError={photosError}
                placeholder="Drag & drop a profile picture"
                isMultiplePicture={true}
              />
            </div>

            <div className="mt-6">
              <ButtonField label="SUBMIT" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadTroll;
