import React from "react";
import { IoFileTrayOutline } from "react-icons/io5";

export const EmptyData = ({ message }) => {
  return (
    <div className="mt-40 text-center flex justify-center flex-col items-center">
      <div>
        <h4 className="text-[#939391]">{message}</h4>
      </div>
      <div>
        <IoFileTrayOutline fontSize={100} className="text-[#939391] " />
      </div>
    </div>
  );
};
