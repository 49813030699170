import React, { useEffect, useState } from "react";
import { check } from "../../../assets/images/images";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { apiClient } from "../../../config/axios";
import Loader from "../../Loader";

const Verified = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname.includes("verified")) {
      const token = queryParams.get("token");
      if (token) {
        setToken(token);
      }
    }
  }, [location]);

  const checkIfEmailIsVerified = async () => {
    if (!token) return;
    const { data } = await apiClient.get(`api/auth/verify?token=${token}`);
    return data;
  };

  const { data, isLoading, error } = useQuery(
    ["verifiedProfile", token],
    checkIfEmailIsVerified,
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
    }
  );

  return (
    <div className="bg-[#def7ef] py-3 flex justify-center items-center min-h-screen">
      <div className=" border border-transparent rounded-3xl shadow-[0px_4px_30px_0px_rgba(0,0,0,0.25)] p-8 z-[9999] bg-[rgba(233,232,232,0.199)] backdrop-blur-[10px] !bg-[#fff] min-w-[300px] lg:min-w-[500px] max-w-[500px] lg:max-w-[700px]">
        {isLoading ? (
          <Loader />
        ) : !data || error?.response?.data?.error ? (
          <div className="flex flex-col items-center text-sm lg:!text-lg">
            <p className="text-sm font-semibold lg:!text-[15px]">
              {error?.response.status == 409
                ? "Email Already Verified"
                : error?.response?.data?.error}
            </p>
            <button
              className="px-6 py-2 lg:px-12 rounded-[10px] bg-primaryColor hover:bg-hoverPrimary text-[#fff] font-normal mt-6 text-sm lg:!text-lg lg:font-semibold"
              onClick={() => navigate("/login")}
            >
              GO TO LOGIN
            </button>
          </div>
        ) : (
          <form className="flex flex-col items-center">
            <p className="text-sm font-semibold lg:!text-lg">
              Welcome to Recant.ai
            </p>
            <div className="w-[60px] lg:w-[90px]">
              <img src={check} alt="icon" className="check-icon my-4" />
            </div>
            <p className="text-sm font-semibold lg:!text-[15px]">
              Email Verified Successfully!
            </p>

            <button
              className="px-6 py-2 lg:px-12 rounded-[10px] bg-primaryColor hover:bg-hoverPrimary text-[#fff] font-normal mt-6 text-sm lg:!text-lg lg:font-semibold"
              onClick={() => navigate("/login")}
            >
              GO TO LOGIN
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Verified;
