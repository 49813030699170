import React from "react";
import { FAQData } from "./index.ts";
import Accordion from "../../common/Accordion/index.js";
import MainLayout from "../../common/MainLayout/index.js";

const FAQs = () => {
  return (
    <MainLayout containerMarginTop="190px">
      <div className="rounded-[3px] p-4 max-w-5xl mx-auto">
        <h4 className="mb-4 text-center text-2xl font-bold">FAQs</h4>
        {FAQData.map((option, index) => (
          <div key={index} className="mb-2">
            <Accordion question={option.question} answer={option.answer} />
          </div>
        ))}
      </div>
    </MainLayout>
  );
};

export default FAQs;
