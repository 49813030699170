import React, { useEffect, useRef, useState } from "react";
import "../Messages/Messages.css";
import { format } from "date-fns";
import { RiQuestionAnswerLine, RiArrowLeftLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import SendMessageBox from "../../components/Dashboard/SendMessageBox";
import Loader from "../../components/Loader";
import { apiClient } from "../../config/axios";

const PrivateInstagramMessages = () => {
  const queryClient = useQueryClient();
  const messageListRef = useRef(null);
  const [inputMessage, setInputMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [forceRefetch, setForceRefetch] = useState(false);
  const [connectedProfile, setConnectedProfile] = useState(null);
  const navigate = useNavigate();

  const fetchUserDetails = async () => {
    try {
      const { data } = await apiClient.get("api/user");
      setConnectedProfile(data);
    } catch (err) {
      console.log("err");
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const instagramProfile = connectedProfile?.userProfile?.find(
    (profile) => profile.platform === "instagram"
  );

  const platformId = instagramProfile ? instagramProfile.platformId : null;

  const {
    isLoading: isTwitterProfileLoading,
    data: userMessages,
    error,
  } = useQuery({
    queryKey: ["instagram-users"],
    queryFn: async () => {
      return await apiClient.get("api/instagram-private-api/get-user-threads");
    },
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    error && toast(error.response.data.message);
  }, [error]);

  useEffect(() => {
    if (userMessages?.data && userMessages?.data.length > 0) {
      setSelectedUser(userMessages.data[0]);
    }
  }, [userMessages]);

  const TWITTER_QUERY_KEY = ["twitter-message", selectedUser?.thread_id];

  const {
    error: isTwitterMessageError,
    data: twitterMessage,
    refetch,
  } = useQuery({
    queryKey: TWITTER_QUERY_KEY,
    queryFn: async () => {
      return await apiClient.get(
        `/api/instagram-private-api/threads/${selectedUser?.thread_id}/messages`
      );
    },
  });

  useEffect(() => {
    if (selectedUser?.thread_id) {
      refetch();
    }
  }, [selectedUser, forceRefetch, refetch]);

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setForceRefetch((prev) => !prev);
  };

  useEffect(() => {
    isTwitterMessageError && toast(isTwitterMessageError.response.data.message);
  }, [isTwitterMessageError]);

  const gettingInboxMessage = async () => {
    await apiClient.post("/api/auth/twitter/send-message", {
      message: inputMessage,
      participantId: selectedUser?.id,
    });
  };

  const sendMessageMutation = useMutation({
    mutationFn: () => gettingInboxMessage(),
  });

  useEffect(() => {
    if (sendMessageMutation.isSuccess) {
      setInputMessage("");
      queryClient.invalidateQueries({ queryKey: TWITTER_QUERY_KEY });
    }
    sendMessageMutation.error && toast.error("Error sending message");
  }, [sendMessageMutation.isSuccess, sendMessageMutation.error]);

  useEffect(() => {
    isTwitterMessageError && toast(isTwitterMessageError.response.data.message);
  }, [isTwitterMessageError]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [selectedUser, twitterMessage]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="mid-content flex flex-row w-full bg-[#eaeeff]">
        <div className="flex flex-col min:w-[275px] w-[275px] overflow-y-auto">
          <div className="flex items-center  mt-3  mb-3">
            <button
              className="back-btn flex items-center fflex-row py-1 px-2 hover:text-[#000] "
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine className="text-[20px]" />
            </button>

            <div className="text-[17px] ml-3 py-4">Messages</div>
          </div>
          {isTwitterProfileLoading ? (
            <div className="side-h flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div>
              {userMessages?.data?.length === 0 ? (
                <div
                  className={`side-h flex flex-row justify-between items-center text-center rounded-lg p-2 m-3 mt-1`}
                >
                  <div
                    className={`flex flex-row justify-center rounded-lg p-2 m-3 mt-1 bg-white text-rose text-sm w-full`}
                  >
                    No messages found
                  </div>
                </div>
              ) : (
                <div className="mx-2">
                  {userMessages?.data?.map(
                    (participant, index) =>
                      // Only render participants with a non-empty array
                      participant?.participants?.length > 0 && (
                        <div
                          className={`flex flex-row gap-2 justify-between items-center rounded-lg p-2 cursor-pointer mb-2 mr-2 ${
                            selectedUser?.thread_id === participant?.thread_id
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => handleUserSelection(participant)}
                          key={index}
                        >
                          {participant?.participants &&
                            (participant.participants.length === 1
                              ? participant.participants.map((user) => (
                                  <div
                                    className="flex flex-row gap-2"
                                    key={user?.id}
                                  >
                                    <div>
                                      <img
                                        className="rounded-full w-9 h-9"
                                        src={user?.profile_pic_url}
                                        alt="user-profile"
                                      />
                                    </div>

                                    <div className="flex flex-col flex-grow">
                                      <div className="font-bold text-sm">
                                        {user?.full_name}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : participant.participants
                                  .filter((user) => user.id !== platformId)
                                  .map((filteredUser) => (
                                    <div
                                      className="flex flex-row gap-2"
                                      key={filteredUser?.id}
                                    >
                                      <div>
                                        <img
                                          className="rounded-full w-9 h-9"
                                          src={filteredUser?.profile_pic_url}
                                          alt="user-profile"
                                        />
                                      </div>

                                      <div className="flex flex-col flex-grow">
                                        <div className="font-bold text-sm">
                                          {filteredUser?.full_name}
                                        </div>
                                      </div>
                                    </div>
                                  )))}
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col flex-grow">
          {isTwitterProfileLoading ? (
            <div className="screen-h flex flex-col items-center justify-center text-xl bg-white">
              <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
              <div className="text-black font-medium text-base uppercase">
                No chats selected
              </div>
            </div>
          ) : (
            <div>
              {!selectedUser?.thread_id ? (
                <div className="screen-h flex flex-col items-center justify-center text-xl bg-white">
                  <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
                  <div className="text-black font-medium text-base uppercase">
                    No chats selected
                  </div>
                </div>
              ) : (
                <div>
                  {selectedUser?.thread_id && (
                    <div className="flex flex-col flex-grow bg-white justify-between ">
                      {/* Selected User details */}
                      <div className="flex flex-row justify-between items-center p-3 border-b border-[#e8e7e7]">
                        <div className="flex flex-row gap-2 justify-between items-start rounded-lg p-2 cursor-pointer">
                          {selectedUser?.participants?.length === 1 ? (
                            <div className="flex gap-4">
                              <img
                                className="rounded-full w-12 h-12"
                                src={selectedUser.participants.profile_pic_url}
                                alt={selectedUser.participants[0].username}
                              />
                              <div className="flex flex-col flex-grow">
                                <div className="font-bold">
                                  {selectedUser.participants[0].full_name}
                                </div>
                                <div className="text-grey text-sm">
                                  last seen today at 12:02 PM
                                </div>
                              </div>
                            </div>
                          ) : (
                            selectedUser?.participants
                              ?.filter(
                                (participant) => participant.id !== platformId
                              )
                              .map((filteredParticipant) => (
                                <div
                                  key={filteredParticipant.id}
                                  className="flex gap-4"
                                >
                                  <img
                                    className="rounded-full w-12 h-12"
                                    src={filteredParticipant.profile_pic_url}
                                    alt={filteredParticipant.username}
                                  />
                                  <div className="flex flex-col flex-grow">
                                    <div className="font-bold">
                                      {filteredParticipant.full_name}
                                    </div>
                                    <div className="text-grey text-sm">
                                      last seen today at 12:02 PM
                                    </div>
                                  </div>
                                </div>
                              ))
                          )}
                        </div>
                      </div>

                      {/* Message List */}
                      <div
                        className="message-content overflow-y-auto"
                        ref={messageListRef}
                      >
                        <div className="flex flex-col flex-grow p-3 justify-end">
                          <div className="text-center mb-2">
                            Today, October 11
                          </div>

                          {twitterMessage?.data?.map((message) => (
                            <div key={message.item_id}>
                              {message.user.id === platformId ? (
                                <div className="mb-3">
                                  <div className="flex flex-row-reverse gap-2 justify-start items-center">
                                    {/* Sender Message */}
                                    <img
                                      src={message?.user?.profile_pic_url}
                                      className="rounded-full w-9 h-9"
                                      alt=""
                                    />
                                    <div className="flex flex-col items-end">
                                      {message.media &&
                                      message.media.length > 0 ? (
                                        <div>
                                          <img
                                            style={{
                                              height: 200,
                                              width: 200,
                                              maxWidth: "100%",
                                              objectFit: "cover",
                                            }}
                                            src={message.media[0]?.url}
                                            alt=""
                                          />
                                        </div>
                                      ) : (
                                        message.text && (
                                          <div className="bg-[#C7DFFF] text-gray px-3 py-2 rounded-md my-2 max-w-xs break-words">
                                            {message.text}
                                          </div>
                                        )
                                      )}
                                      <div className="text-[#C4C4C4] text-xs">
                                        {message.timestamp
                                          ? format(
                                              new Date(message.timestamp),
                                              "hh:mm"
                                            )
                                          : "Invalid Date"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="mb-3">
                                  {message.text ||
                                  (message.media &&
                                    message.media.length > 0) ? (
                                    <div className="flex flex-row gap-2 justify-start items-center">
                                      {/* Receiver Message */}
                                      <img
                                        src={message?.user?.profile_pic_url}
                                        className="rounded-full w-9 h-9"
                                        alt=""
                                      />
                                      <div className="flex flex-col items-start">
                                        {message.media &&
                                        message.media.length > 0 ? (
                                          <div>
                                            <img
                                              style={{
                                                height: 200,
                                                width: 200,
                                                maxWidth: "100%", // Ensures image doesn't overflow
                                                objectFit: "cover",
                                              }}
                                              src={message.media[0]?.url}
                                              alt=""
                                            />
                                          </div>
                                        ) : null}
                                        {message.text && (
                                          <div className="bg-[#F3F3F3] text-gray px-3 py-2 rounded-md my-2 max-w-xs break-words">
                                            {message.text}
                                          </div>
                                        )}
                                        <div className="text-[#C4C4C4] text-xs">
                                          {message.timestamp
                                            ? format(
                                                new Date(message.timestamp),
                                                "MM/dd hh:mm"
                                              )
                                            : "Invalid Date"}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Message Input */}
                      <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 flex items-center justify-between">
                        <SendMessageBox
                          inputMessage={inputMessage}
                          setInputMessage={setInputMessage}
                          handleSendValue={() => sendMessageMutation.mutate()}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default PrivateInstagramMessages;
