import React from "react";
import Header from "../../components/LandingPage/Header";
import MainBanner from "../../layouts/Banner";
import Problem from "../../layouts/Problem";
import Solution from "../../layouts/Solution";
import WorkMechanism from "../../layouts/WorkMechanism";
import Footer from "../../layouts/Footer";

const LandingPage = () => {
  return (
    <div>
      <div className="relative ">
        <Header className="sticky top-0 z-50" />

        <div className=" w-full h-full ">
          <MainBanner />
        </div>
        <div className="max-w-[1440px] mx-auto lg:px-[60px]">
          <div className="relative w-full px-[20px] lg:px-0  pt-[60px] lg:pt-[90px] lg:pb-[60px]">
            <Problem />
          </div>

          <div className="relative w-full px-[20px] lg:px-0  ">
            <Solution />
          </div>
          <div className="relative w-full px-[20px] lg:px-0  pt-[20px] lg:pt-[120px] ">
            <WorkMechanism />
          </div>
        </div>
        <div className="mt-[50px] lg:mt-[120px] ">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
