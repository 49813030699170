export const PRIVACY_TERMS = [
  {
    title: "Introduction",
    content:
      "Recant is an eSafety app committed to protecting users from abusive messages while respecting privacy and adhering to legal obligations. This policy outlines our data management practices, user rights, and compliance measures.",
  },
  {
    title: "Information Collection",
    content:
      "We collect personal information, such as names, contact details, and message content flagged as abuse or spam, to prevent abuse and optimise our service. This includes technical data, language preferences, timezone, and communication preferences. Photos are collected only if shared by the user. Data may also be sourced via social login options, like Facebook Login.For paid services, we collect payment and billing information, including credit card details, and track service usage, such as subscription types and transaction data.",
  },
  {
    title: "Use of Personal Information",
    content:
      "Data is used to provide safety services, analyse trends, enhance user experience, and comply with legal requests. The legal basis for processing includes consent, contractual necessity, legal obligations, and our legitimate interests.",
  },
  {
    title: "Information Sharing and Disclosure",
    content:
      "We share data with third parties only for operational purposes, under strict conditions. Public authority disclosures are made in response to lawful requests, with transparency and adherence to legal standards.",
  },
  {
    title: "Pseudonymity and Anonymity",
    content:
      "Users may interact with us using a pseudonym, or anonymously where feasible.",
  },
  {
    title: "Handling Public Authority Requests",
    content:
      "We verify the legality of data requests, challenge unlawful demands, disclose minimal necessary data, and document all requests to ensure compliance and protect user privacy.",
  },
  {
    title: "User Rights",
    content:
      "Users have rights to access, correct, delete their data, and object to processing. Specific rights under GDPR for EU/EEA residents include data portability and consent withdrawal.",
  },
  {
    title: "Data Security",
    content:
      "Robust security measures are implemented to protect against unauthorised access and data breaches. We recognise that no system is entirely secure.",
  },
  {
    title: "International Data Transfers",
    content:
      "International data transfers comply with stringent privacy standards, including GDPR.",
  },
  {
    title: "Children’s Privacy",
    content:
      "We do not knowingly collect data from children without parental consent, complying with child privacy laws like COPPA.",
  },
  {
    title: "Data Retention and Accuracy",
    content:
      "Personal data is retained as long as necessary for the purposes outlined, and we take steps to keep it accurate and up-to-date.",
  },
  {
    title: "Cookie Policy",
    content:
      "We use cookies and similar technologies, detailed in our separate Cookie Policy, including types, purposes, and user control options.",
  },
  {
    title: "Data Protection Officer",
    content:
      "Our Data Protection Officer (DPO) can be contacted at cam@recant.ai for privacy-related inquiries.",
  },
  {
    title: "Breach Notification",
    content:
      "Procedures are in place for notifying users and regulators about data breaches in accordance with legal requirements.",
  },
  {
    title: "Changes to the Policy",
    content:
      "Policy updates will be communicated, reflecting changes in legal or operational practices.",
  },
  {
    title: "Access and Update Your Personal Information",
    content:
      "We provide access to your Personal Information upon request, as legally permitted. Requests for access or correction can be directed to cam@recant.ai . We may charge a fee for access to cover our costs.",
  },
  {
    title: "Contact Information",
    content:
      "For privacy concerns, contact us at cam@recant.ai or +61 480 494 834.",
  },
];
