import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import Loader from "../Loader";
import useDisconnectProfile from "../DisconnectProfile";
import { apiClient } from "../../config/axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ModalField from "../../common/ModalField";
import SideOverlayDrawer from "../../common/SideOverlayDrawer";
import ProfileImage from "../../assets/images/girl.png";
import { MdDelete } from "react-icons/md";
import { SensitivityParameter } from "../../pages";

const UserInformation = () => {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const sensitivityParamRef = useRef(null);
  const { isLoading, showModal, setShowModal, handleDisconnectProfile } =
    useDisconnectProfile();
  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = (profile) => {
    setSelectedProfile(profile);
    setShowModal(true);
  };

  const [isWhiteListUserFetched, setIsWhiteListUserFetched] = useState(false);

  const { register, handleSubmit } = useForm({});

  const onSaveSubmit = (data) => {
    console.log(data, "data");
  };

  const fetchUserDetails = async () => {
    const { data } = await apiClient.get("api/user");
    return data;
  };

  const [openSideOverlayDrawer, setOpenSideOverlayDrawer] = useState(false);

  const {
    data: connectedProfile,
    error,
    isLoading: connectedProfileLoading,
  } = useQuery("[connectedProfile]", fetchUserDetails);

  useEffect(() => {
    error && toast.error(error?.response?.data?.message || "An error occurred");
  }, [error]);

  const handleFetchWhiteList = () => {
    setIsWhiteListUserFetched(true);
    setOpenSideOverlayDrawer(true);
  };

  return (
    <OnBoardingLayout>
      {/* Overlay div that appears when drawer is open */}
      {openSideOverlayDrawer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      )}

      <div
        className={`bg-white w-full px-3 pt-3 pb-6 ${
          openSideOverlayDrawer && " z-50 inset-0 bg-black bg-opacity-0 "
        }`}
      >
        <h1 className="text-grey text-2xl font-sans font-semibold mt-[55px] flex items-center justify-center">
          User Profile
        </h1>

        <div className="lg:!mx-20 sm:mx-2 mt-6 grid lg:grid-cols-2 grid-cols-1 gap-4">
          {/* Personal Information */}
          <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4 shadow-md">
            <h6 className="text-[#000] font-semibold mb-12">
              Personal Information
            </h6>
            <form onSubmit={handleSubmit(onSaveSubmit)}>
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-3">
                  <img
                    src={ProfileImage}
                    className="w-24 h-24 rounded-full object-cover"
                  />
                  <div className="flex flex-col text-sm">
                    <div>
                      {connectedProfile?.lastName} {connectedProfile?.firstName}
                    </div>
                    <div>{connectedProfile?.email}</div>
                    <div>{connectedProfile?.role}</div>
                  </div>
                </div>
                {/* Form Fields */}
                <div className="mt-4 flex flex-col gap-3">
                  {/* First Name */}
                  <div className="flex gap-4 items-center">
                    <div className="w-[80px]">FirstName:</div>
                    <div className="flex-grow">
                      <input
                        name="firstName"
                        value={connectedProfile?.firstName || ""}
                        {...register("firstName")}
                        placeholder="Enter FirstName"
                        className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f7f6f6] text-[14px]"
                        readOnly={!editPersonalInfo}
                      />
                    </div>
                  </div>
                  {/* Last Name */}
                  <div className="flex gap-4 items-center">
                    <div className="w-[80px]">LastName:</div>
                    <div className="flex-grow">
                      <input
                        name="lastName"
                        value={connectedProfile?.lastName || ""}
                        {...register("lastName")}
                        placeholder="Enter LastName"
                        className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f7f6f6] text-[14px]"
                        readOnly={!editPersonalInfo}
                      />
                    </div>
                  </div>
                  {/* Email */}
                  <div className="flex gap-4 items-center">
                    <div className="w-[80px]">Email:</div>
                    <div className="flex-grow">
                      <input
                        name="email"
                        value={connectedProfile?.email || ""}
                        {...register("email")}
                        placeholder="Enter Email"
                        className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f7f6f6] text-[14px]"
                        readOnly={!editPersonalInfo}
                      />
                    </div>
                  </div>
                  {/* Role */}
                  <div className="flex gap-4 items-center">
                    <div className="w-[80px]">Role:</div>
                    <div className="flex-grow">
                      <input
                        name="role"
                        value={connectedProfile?.role || ""}
                        {...register("role")}
                        placeholder="Enter Role"
                        className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f7f6f6] text-[14px]"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          {/* User Settings */}
          <div
            className={`border-[1px] border-[#e6dede] rounded-[8px] p-4 shadow-md ${
              !editPersonalInfo && "opacity-70"
            }`}
          >
            <div className="flex justify-between items-center mb-8">
              <h6 className="text-[#000] font-semibold ">User Settings</h6>
              <div className="flex gap-1 text-sm">
                {editPersonalInfo ? (
                  <button
                    onClick={() => {
                      setEditPersonalInfo(!editPersonalInfo);
                      if (sensitivityParamRef.current) {
                        sensitivityParamRef.current.submit();
                      }
                    }}
                    className="px-3 py-1 bg-primaryColor text-white rounded-[10px] hover:bg-hoverPrimary"
                  >
                    Save
                  </button>
                ) : (
                  <button
                    onClick={() => setEditPersonalInfo(!editPersonalInfo)}
                    disabled={editPersonalInfo}
                    className={`px-3 py-1 bg-primaryColor text-white rounded-[10px] hover:bg-hoverPrimary ${
                      !editPersonalInfo && "!opacity-100"
                    }`}
                  >
                    Edit
                  </button>
                )}
              </div>
              {console.log(editPersonalInfo, "editPersonalInfo")}
            </div>
            <div>
              <SensitivityParameter
                ref={sensitivityParamRef}
                isEdit={editPersonalInfo}
              />

              {/* <button
                className="px-3 py-2 bg-primaryColor text-white rounded-[10px] mt-2 hover:bg-hoverPrimary"
                // onClick={() => navigate("/sensitivity-parameter")}
              >
                Adjust sensitivity settings
              </button> */}
            </div>
          </div>
        </div>

        {/* Connected User Profiles */}
        <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4 shadow-md lg:!mx-20 sm:mx-2 mt-6">
          <h6 className="text-[#000] font-semibold mb-3">
            Connected User Profiles
          </h6>

          <div>
            <SideOverlayDrawer
              openSideOverlayDrawer={openSideOverlayDrawer}
              setOpenSideOverlayDrawer={setOpenSideOverlayDrawer}
              isWhiteListUserFetched={isWhiteListUserFetched}
            />
          </div>

          {connectedProfileLoading ? (
            <Loader />
          ) : connectedProfile?.userProfile?.length !== 0 ? (
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
              {connectedProfile?.userProfile?.map((profile, index) => (
                <div
                  className="flex items-center mb-4 justify-between border shadow-sm px-3 rounded py-4"
                  key={index}
                >
                  <div>{capitalizeFirstLetter(profile?.platform)}</div>
                  <div className="flex gap-3">
                    <div className="bg-[#f44336] p-1 rounded">
                      <MdDelete
                        className="cursor-pointer text-[white]"
                        onClick={() => handleShowModal(profile)}
                        fontSize={26}
                      />
                    </div>
                    {profile?.platform === "twitter" && (
                      <button
                        className="px-2 py-2 bg-greenC text-white rounded text-sm cursor-pointer"
                        onClick={handleFetchWhiteList}
                      >
                        White-List Users
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div>
              <div>No connected profiles</div>
              <Link to="/dashboard">
                <button className="px-3 py-2 bg-primaryColor mb-2 text-white rounded-[2px] mt-3 font-semibold hover:bg-hoverPrimary">
                  Connect Profile
                </button>
              </Link>
            </div>
          )}
        </div>
        <ModalField
          confirmButtonTitle={"Disconnect"}
          modalContent={
            "Are you sure you want to disconnect the following profile?"
          }
          modalHeader={"Confirm Profile Deletion"}
          handleCloseModal={handleCloseModal}
          isLoading={isLoading}
          showModal={showModal}
          onClickConfirmButton={() => handleDisconnectProfile(selectedProfile)}
          selectedProfile={selectedProfile}
        />
      </div>
    </OnBoardingLayout>
  );
};

export default UserInformation;
