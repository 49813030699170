export const WhyAmIGettingThisOptions = [
  {
    question: "Why am I receiving a message from Recant.ai?",
    answer: [
      "If you have received an auto response from Recant.ai in a message thread, it means the person you have messaged has the Recant.ai Agent installed across one or more of their social media accounts or communications platforms",
      "Folks who install our Agent usually do so because they have challenges with receiving unwanted messages or attention, including abuse and harassment or behaviors they think are ‘creepy’, or generally out of line.",
      "When they sign up, they set a range of parameters relating to their communications preferences, and our Recant.ai tool does its best to help them manage accordingly.",
      "If you have received a response from the Recant.ai Agent - it means the Agent has detected activity that breaches these parameters. Errors can be made, though it’s highly likely if Recant.ai Agent activity has been triggered it is in response to something explicitly unwanted, or communications that have stepped into ‘unwanted’ territory.",
    ],
  },
  {
    question:
      "Is the service fee for Recant.ai’s short behavioral management course which, upon completion, addresses and improves the impact of my communications, considered blackmail?",
    answer: {
      title:
        "No, and here are the 5 elements that need to be satisfied for something to be blackmail:",
      answer: [
        {
          points:
            "Unwarranted demand: Someone makes a demand that lacks reasonable justification or legal entitlement.",
          description:
            "Reasonable justification may apply if your communication involved unlawful elements such as threatening, harassing, or abusive content.",
        },
        {
          points:
            "Menaces: The demand is accompanied by threats of harm or adverse consequences.",
          description:
            "No threats are made, only an offer for a service to reconsider your behaviour going forward to help mitigate potential external consequences, such as police reporting or civil claims, that may arise independently of our involvement.",
        },
        {
          points:
            "Intention to gain or cause loss: They intend to obtain a benefit, or to cause a loss to you.",
          description:
            "Not unlike insurance products, you’re being offered a service to promote better communication practices and reduce the likelihood of negative outcomes arising from unlawful or harmful behavior. It’s a voluntary service designed to provide significant value to you after considering the ramifications of this situation.",
        },
        {
          points:
            "Absence of lawful justification: The demands are not supported by any legal right or authority.",
          description:
            "Recant.ai does not make any demand. You are simply offered a service not unlike roadside assistance.",
        },
        {
          points:
            "Coercion or exploitation: You feel pressured or forced to comply with the demand due to fear of the consequences.",
          description:
            "Participation in the service is entirely voluntary. Declining to use the service does not result in any automatic consequences unless your communication is deemed unlawful under existing legal standards.",
        },
      ],
      footer:
        "It is our mission to improve the rhetoric online so people feel respected and we can make the world a better place. If you have any suggestions on how to solve the problem of online abuse, particularly towards women and children, we’d love to hear from you. You can get in touch any time.",
    },
  },
];
