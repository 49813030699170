import React from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Stack,
  Grid,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import AdminLayout from "./AdminLayout";
import PeopleIcon from "@mui/icons-material/People";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { apiClient } from "../../config/axios";

const Home = () => {
  const navigate = useNavigate();

  const fetchTotalDmCounts = async () => {
    const { data } = await apiClient.get(
      "api/message-interception/admin/message-stats"
    );
    return data;
  };

  const { data } = useQuery("[TotalDmCounts]", fetchTotalDmCounts);

  return (
    <AdminLayout>
      <Box px={4} sx={{ width: "100%" }} marginTop={10}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Box textAlign={{ xs: "center", sm: "left" }}>
              <div>
                <Typography
                  variant="h5"
                  fontSize={26}
                  fontWeight="bold"
                  letterSpacing={2}
                  lineHeight={2}
                >
                  Hi Admin,
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight="semi-bold"
                  fontSize={26}
                  lineHeight={2}
                >
                  Welcome back!
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={18}
                  letterSpacing={2}
                  marginTop={3}
                >
                  This page is designed to give some important information about
                  the application.
                </Typography>
              </div>
              <div className="flex">
                <Box mt={2} marginTop={8}>
                  <div
                    className="px-[50px] py-[28px] bg-primaryColor flex gap-[15px] items-center text-[#fff] cursor-pointer hover:bg-hoverPrimary rounded-[5px] font-bold"
                    onClick={() => navigate("/admin-user")}
                  >
                    <PeopleIcon /> View Users
                    <ArrowForwardIosIcon fontSize="1" />
                  </div>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card sx={{ height: "100%" }} marginTop={30}>
              <CardHeader title="Statistics" />
              <CardContent>
                <Stack spacing={2} alignItems="center">
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 2,
                      backgroundColor: "#dad5d5",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="normal">
                      Total messages
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {data?.totalMessageCount}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 2,
                      backgroundColor: "#dad5d5",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="normal">
                      Total messages Intercepted
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {data?.totalInterceptedMessageCount}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 2,
                      backgroundColor: "#dad5d5",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body1" fontWeight="normal">
                      Total messages Recanted
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      {data?.totalRecantedMessageCount}
                    </Typography>
                  </Paper>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
};

export default Home;
