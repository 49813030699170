import React from 'react';
import Modal from 'react-modal';

const TwitterConsentModal = ({ isOpen, onRequestClose, handleTwitterLogin }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Twitter Permissions Disclaimer"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: '9999',
        },
        content: {
          padding: '30px',
          borderRadius: '12px',
          maxWidth: '600px',
          margin: 'auto',
          border: 'none',
          boxShadow: '0 15px 30px rgba(0, 0, 0, 0.3)',
          fontFamily: 'Arial, sans-serif',
          backgroundColor: '#f9f9f9',
        },
      }}
    >
      <h2 style={{
        fontSize: '28px',
        fontWeight: '700',
        color: '#1a1a1a',
        marginBottom: '15px',
        textAlign: 'center',
        textTransform: 'uppercase',
        letterSpacing: '1px',
      }}>
        Important Notice
      </h2>

      <p style={{
        fontSize: '16px',
        lineHeight: '1.8',
        color: '#333',
        marginBottom: '20px',
        textAlign: 'center',
      }}>
        Please note that, although Twitter says on the following page that we can perform these actions:
      </p>

      <ul style={{
        listStyleType: 'disc',
        paddingLeft: '20px',
        marginBottom: '20px',
        color: '#444',
      }}>
        {[
          'See Tweets from your timeline and Lists',
          'See and update your Twitter profile information',
          'See accounts you follow, mute, or block',
          'Follow/unfollow accounts',
          'Post, delete, like, retweet, or reply to Tweets',
          'Mute, block, and report accounts',
          'Send and manage Direct Messages'
        ].map((item, index) => (
          <li key={index} style={{
            marginBottom: '10px',
            fontSize: '16px',
          }}>
            {item}
          </li>
        ))}
      </ul>

      <p style={{
        fontSize: '15px',
        lineHeight: '1.6',
        color: '#666',
        marginBottom: '30px',
        textAlign: 'center',
        fontWeight: 'bold',  // Making this section bold
        backgroundColor: '#ffeb3b',  // Highlighting with yellow
        padding: '10px',
        borderRadius: '8px',
      }}>
        WE WILL NOT PERFORM ANY OF THESE ACTIONS WITHOUT YOUR EXPLICIT CONSENT. OUR APP ONLY FILTERS DMs DEEMED INAPPROPRIATE (E.G., THREATS) BY YOUR SETTINGS.
      </p>

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '30px',
      }}>
        <button
          onClick={onRequestClose}
          style={{
            padding: '12px 24px',
            backgroundColor: '#e0e0e0', // Neutral grey
            color: '#333',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#d6d6d6')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
        >
          Close
        </button>
        <button
          onClick={() => {
            handleTwitterLogin();
            onRequestClose();
          }}
          style={{
            padding: '12px 24px',
            backgroundColor: '#e0e0e0', // Neutral grey
            color: '#333',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#d6d6d6')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#e0e0e0')}
        >
          Proceed to Twitter
        </button>
      </div>
    </Modal>
  );
};

export default TwitterConsentModal;
