import { z } from "zod";

export const NotTrustSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .transform((str) => str.trim())
    .refine((email) => z.string().email().safeParse(email).success, {
      message: "Invalid email address",
    }),
  username: z.string().min(1, { message: "Username is required" }),
  message: z.string().min(1, { message: "Message is required" }),
  photos: z.string().min(1, { message: "Photos is required" }),
});
