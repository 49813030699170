import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import FbUser from "../../../images/fbuser.png";
// import "../SelectProfile.css";
import Loader from "../../../components/Loader";
import { RiArrowLeftLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { apiClient } from "../../../config/axios";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import SocialMediaPage from "../../../components/SocialMediaPage";
import defaultImage from "../../../assets/images/defaultImage.png";

const profileDetails = {
  image: FbUser,
  name: "Profile Name",
  link: "fb.com/pageid-198827468293",
  followers: "4.5k Followers",
};

const FaceBookConnectedProfile = () => {
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [profile, setProfiles] = useState({ business_profiles: [] });
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const getBusinessProfile = () => {
    apiClient
      .get(`/api/facebook/business-profile`, {
        headers: {
          token: accessToken,
        },
      })
      .then((res) => {
        setProfiles(res.data);
        setLoading(false);
      })
      .catch((error) => {
        toast("Something went wrong");
        setLoadError(true);
      });
  };

  useEffect(() => {
    getBusinessProfile();
  }, []);

  const handleback = () => {
    navigate(-1);
  };

  return (
    <OnBoardingLayout selected={1}>
      <div className="flex flex-col bg-white w-full h-screen lg:px-3 sm:px-1 px-2 pt-3">
        <div
          className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 m-4"
          onClick={handleback}
        >
          <RiArrowLeftLine />
          Back
        </div>
        <div className="flex flex-row gap-4 m-5">
          <img
            src={profile.platformProfileUrl || defaultImage}
            className="h-16 w-16"
            alt=""
          />
          <div className="flex flex-col">
            <h5 className="mb-0">{profile.platformUsername}</h5>
            <span className="text-sm">{profileDetails.link}</span>
            <span className="text-sm">{profileDetails.followers}</span>
          </div>
        </div>
        <div className="">
          <div className=" lg:m-4 sm:m-0">
            {profile && profile.business_profiles.length > 0 ? (
              <>
                <h4 className="mb-3">Select Page</h4>
                <p className="mb-3">
                  To send or receive a message on the specific page, just click
                  the designated button
                </p>
              </>
            ) : (
              ""
            )}
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div>
                {profile && profile.business_profiles.length === 0 ? (
                  <div className=" flex flex-col justify-center text-black font-medium text-base uppercase">
                    <h4 className="mb-4">Page not added.</h4>
                    <Link
                      to={"/facebook/addpage"}
                      className="flex flex-row gap-2 items-center  py-2 px-3 text-white rounded-sm cursor-pointer w-fit"
                    >
                      Add page?
                    </Link>
                  </div>
                ) : (
                  <div>
                    {profile &&
                      profile.business_profiles.map((profile, index) => (
                        <Link
                          to={`/facebook/messages/${profile.pageId}`}
                          key={index}
                          className="no-underline"
                        >
                          <SocialMediaPage
                            socialMediaPage={profile?.pageName}
                            imageUrl={profile?.pagePicUrl}
                          />
                        </Link>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default FaceBookConnectedProfile;
