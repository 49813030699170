import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./TwitterConnectedProfiles.css";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import { RiArrowLeftLine } from "react-icons/ri";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { apiClient } from "../../../config/axios";
import "react-responsive-modal/styles.css";
import { useQuery } from "react-query";
import { useMediaQuery, useTheme } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";

const TwitterConnectedProfile = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const {
    isLoading: isTwitterLoading,
    data: twitterData,
    error,
  } = useQuery({
    queryKey: ["twitter-connected-profiles"],
    queryFn: async () => {
      return await apiClient.get("/api/auth/twitter/users");
    },
  });

  useEffect(() => {
    error && toast(error.response.data.message);
  }, [error]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="flex flex-col bg-white w-full h-screen px-3 pt-3">
        <div
          className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 m-4"
          onClick={() => navigate(-1)}
        >
          <RiArrowLeftLine />
          Back
        </div>

        <div className="">
          <div className="m-4 ">
            <h4 className="mb-3">Connected Profiles</h4>
            {isTwitterLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                <div className="">
                  {twitterData?.data?.profile &&
                    twitterData?.data?.profile?.map((page, index) => (
                      <Link
                        to={`/twitter/${page.platformId}/messages`}
                        className="no-underline"
                      >
                        <div
                          key={index}
                          // className={`item flex items-center w-full justify-between gap-2 mb-3 text-[#868383] `}
                        >
                          <div
                            className={` flex items-center w-full justify-between  gap-2 mb-3 lg:px-10 sm:!px-[18px] py-3 bg-[#eff6f6] hover:shadow-md
      `}
                          >
                            <div
                              className={`flex flex-row gap-3  items-center`}
                            >
                              <img
                                src={page.platformPicUrl}
                                className="h-16 w-16"
                                alt=""
                              />
                              <div className="text-[#000]">
                                <span className="text-sm">
                                  Twitter Id: <b>@{page.platformUsername}</b>
                                </span>
                                <br />
                                <span className="text-sm">
                                  Connected at:{" "}
                                  {format(
                                    new Date(page.createdAt),
                                    "yyyy-MM-dd"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="flex flex-row gap-2 items-center font-bold">
                              <div className="px-[26px] py-2  text-[#54bc64]  text-[17px]  gap-2 flex items-center border-[1px] border-[#54bc64]">
                                {isDesktop ? " Open Messages" : " Open "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
                <div>
                  <h4 className="mb-3 mt-5">Connected Messages</h4>
                  <div className="pt-2 ">
                    <div className="relative">
                      <div className="max-h-[calc(100vh-20rem)] min-h-24 overflow-y-auto    pt-4 ">
                        {twitterData?.data?.users === 0 ? (
                          <div className="text-[17px] font-semibold">
                            No data Found!
                          </div>
                        ) : (
                          <div className="flex flex-col items-start lg:w-1/2 border-[1px] border-[#dddddd] rounded-[20px] shadow-md py-2">
                            {twitterData?.data?.users?.map((page, index) => (
                              <div
                                key={index}
                                className="flex items-center w-full  px-4 py-3 gap-3 border-b border-[#dddddd] last:border-none"
                              >
                                <div className="relative flex flex-row gap-3 items-center">
                                  <img
                                    src={page.profileImageUrl}
                                    className="h-8 w-8 rounded-full"
                                    alt=""
                                  />
                                  <div className="absolute bottom-0 right-0 bg-white rounded-full p-0.5">
                                    <FaCheckCircle
                                      className="text-[#41964e]"
                                      fontSize={14}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <span className="text-sm">
                                    Twitter Id: <b>@{page.username}</b>
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default TwitterConnectedProfile;
