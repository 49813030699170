import React from "react";
import { format } from "date-fns";
import defaultImage from "../../assets/images/defaultImage.png";

const MessageList = ({
  messages,
  selectedUser,
  pagePic,
  recipient,
  messageListRef,
}) => {
  return (
    <div className="message-content overflow-y-auto" ref={messageListRef}>
      <div className="flex flex-col flex-grow p-3 justify-end">
        <div className="text-center mb-2">Today, October 11</div>
        {messages.map((message, index) => (
          <div key={index}>
            {message.recipientId === recipient ? (
              <div className="mb-3">
                <div className="flex flex-row-reverse gap-2 justify-start items-center">
                  <img
                    src={pagePic || defaultImage}
                    className="rounded-full w-9 h-9"
                    alt="Page"
                  />
                  <div className="flex flex-col items-end">
                    {message.messageImageUrl ? (
                      <img
                        style={{ height: 150 }}
                        src={message.messageImageUrl || defaultImage}
                        alt="Message"
                      />
                    ) : (
                      <p
                        className="bg-[#C7DFFF] text-gray px-3 py-2 rounded-md my-1 w-fit lg:max-w-[420px] max-w-xs whitespace-pre-wrap"
                        dangerouslySetInnerHTML={{ __html: message.message }}
                      />
                    )}
                  </div>
                </div>
                <div className="flex flex-row-reverse items-baseline gap-3 font-bold">
                  <span className="font-normal text-sm mr-12">
                    {format(new Date(message.messageCreatedAt), "MM/dd hh:mm")}
                  </span>
                </div>
              </div>
            ) : (
              <div className="mb-2">
                <div className="flex flex-row gap-2 items-center">
                  <img
                    src={selectedUser.profile_pic || defaultImage}
                    className="rounded-full w-9 h-9"
                    alt="User"
                  />
                  <div className="flex flex-col">
                    {message.messageImageUrl ? (
                      <img
                        style={{ height: 150 }}
                        src={message.messageImageUrl || defaultImage}
                        alt="Message"
                      />
                    ) : (
                      <div className="bg-[#C7DFFF] text-gray px-3 py-2 rounded-md my-1 w-fit lg:max-w-[420px] max-w-xs">
                        {message.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-baseline gap-3 font-bold">
                  <span className="font-normal text-sm ml-12">
                    {format(new Date(message.messageCreatedAt), "MM/dd hh:mm")}
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MessageList;
