import React from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineMailOpen } from "react-icons/hi";

const VerifyEmail = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-[#def7ef] py-3 flex justify-center items-center min-h-screen">
      <div className=" border border-transparent rounded-3xl shadow-[0px_4px_30px_0px_rgba(0,0,0,0.25)] p-8 z-[9999] bg-[rgba(233,232,232,0.199)] backdrop-blur-[10px] !bg-[#fff] min-w-[300px] lg:min-w-[500px] max-w-[500px] lg:max-w-[700px]">
        <form className="flex flex-col items-center">
          <div className="text-sm lg:!text-xl lg:font-light">
            Verify Your Email
          </div>
          <HiOutlineMailOpen fontSize={26} className="my-2 text-primaryColor" />
          <p className="text-[11px]  lg:!text-[15px] lg:!mb-6">
            Check your email & click the link to activate your account
          </p>
          <button
            className="px-12 py-2 lg:!px-24 hover:bg-hoverPrimary rounded-[10px] bg-primaryColor text-[#fff] font-normal mt-3  text-sm lg:!text-lg lg:font-semibold"
            onClick={() => navigate("/login")}
          >
            GO TO LOGIN
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyEmail;
