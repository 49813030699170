import Navbar from "react-bootstrap/Navbar";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/recantLogo.png";
import { useAuth } from "../../../config/useAuth";
import TopNavBar from "../../../layouts/OnBoardingLayout/TopNavBar";

function NavScrollExample() {
  const user = useAuth();
  const navigate = useNavigate();
  const navigateToSignUp = () => {
    // 👇️ navigate to /contacts
    navigate("/signup");
  };

  return (
    <Navbar
      bg="white"
      expand="lg"
      className=" flex justify-between items-center container !z-50"
    >
      <a className="nav-logo text-yellow  mt-[10px]" href="/">
        <img src={logo} width={120} />
      </a>
      <Navbar.Toggle aria-controls="navbarScroll" className="!text-[15px]" />
      <Navbar.Collapse id="navbarScroll" className="justify-end ">
        {user ? (
          <div>
            <TopNavBar color={"text-yellow"} />
          </div>
        ) : (
          <div className="flex gap-4 items-center">
            {/* <a className="text-yellow cursor-pointer" href="waiting-list">
        Join Waiting List
      </a> */}
            {/* <a
              href="/about-us"
              className="text-yellow font-semibold no-underline"
            >
              About Us
            </a> */}
            <a href="/login" className="text-yellow font-semibold no-underline">
              Login
            </a>
            <button
              onClick={navigateToSignUp}
              className="bg-yellow text-white font-semibold px-7 py-2 rounded-full hover:bg-[#eeb560]"
            >
              Sign Up
            </button>
          </div>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavScrollExample;
