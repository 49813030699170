import AdminLayout from "./AdminLayout";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { users } from "../../components/Admin/UserTable";
import { useQuery } from "react-query";
import { apiClient } from "../../config/axios";
import Loader from "../../components/Loader";

const headCells = [{ id: "user", align: "left", label: "User" }];

export const fetchWaitingUserList = async () => {
  const response = await apiClient.get(`api/waiting-list/admin/waiting/users`);
  return response?.data?.users;
};

const AdminWaitingList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const {
    data: waitingList,
    isLoading,
    error,
  } = useQuery("[waitingUserList]", () => fetchWaitingUserList());

  console.log("waitingList", waitingList);
  if (isLoading) {
    return (
      <AdminLayout>
        <div className="h-[70vh]">
          <Loader />
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-[70vh]">
          <div className="text-base lg:!text-xl font-poppins font-[600]">
            Data Not Found!
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <h4 className="font-bold  textSecondary mb-[40px]">Waiting List</h4>
      <TableContainer>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.align}>
                  {headCell.label}
                </TableCell>
              ))}
              {/* <TableCell align="right">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {waitingList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell padding="checkbox">
                    {/* <Checkbox color="primary" /> */}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(`/admin-waiting-list/${user.id}`);
                    }}
                    className="cursor-pointer"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                      <Box ml={2}>
                        <Typography variant="subtitle1">
                          {`${user.lastName} ${user.firstName}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {user.email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </AdminLayout>
  );
};

export default AdminWaitingList;
