import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { InviteTokenProvider } from "./context/useInviteToken";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <InviteTokenProvider>
    <App />
  </InviteTokenProvider>
);
