import React from "react";
import PlainHeader from "../../common/PlainHeader";
import { AboutUsData } from "../../constant/AboutUs";
import Footer from "../../layouts/Footer";

const AboutUs = () => {
  return (
    <>
      <PlainHeader />
      <div className="container pt-32 pb-12">
        <h5 className="mb-8 flex justify-center">About Us</h5>
        <section className="flex flex-col gap-6">
          {AboutUsData?.map((item, outerIndex) => {
            return (
              <div key={outerIndex} className="">
                <div className="ml-3">{item}</div>
              </div>
            );
          })}
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
