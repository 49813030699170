import React from "react";
import PlainHeader from "../../common/PlainHeader";
import Footer from "../../layouts/Footer";

const MainLayout = ({ children, footerMarginTop = "30px" }) => {
  return (
    <div className={`flex flex-col h-screen `}>
      <PlainHeader />
      <main className={`flex-1 container mx-auto pt-[130px] `}>{children}</main>
      <div className={`mt-[${footerMarginTop}]`}>
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
