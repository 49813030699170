import React from "react";

const Landing_Page_Problem = [
  {
    content: [
      {
        title: "Impersonal",
        content:
          "The anonymity of the internet often leads to a lack of regard for others.",
      },
      {
        title: "Abusive",
        content:
          "Women and public figures face constant abuse and threats online.",
      },
      {
        title: "Dehumanized",
        content:
          "The lack of face-to-face makes people forget there's a real human on the other side of the screen.",
      },
    ],
  },
];
const Problem = () => {
  return (
    <div className="md:!px-[90px] sm:!px-[60px] lg:!px-0">
      <div className=" flex flex-col items-center justify-center mb-12">
        <div className="lg:text-3xl font-semibold font-poppins mb-2">
          Problem
        </div>
        <div className="text-lg font-DMSans">
          Online interactions have become
        </div>
      </div>
      <div className="">
        {Landing_Page_Problem.map((problem, index) => (
          <div key={index} className="">
            <div className="flex flex-col lg:!flex-row mt-[10px] lg:!gap-8">
              {problem.content.map((problem, problemIndex) => (
                <ul
                  key={problemIndex}
                  className="bg-[#e0e0e0]  border-[1px] border-[#f0f0f0] rounded-[15px] px-4 py-5 font-poppins "
                >
                  <li className="font-semibold text-lg mb-2">
                    {problem.title}
                  </li>
                  <li className="text-base">{problem.content}</li>
                </ul>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Problem;
