import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { useParams, useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";

const ReportedTrollListDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Fetching Troll List Details
  const fetchTrollListDetails = async () => {
    const response = await apiClient.get(`api/troll-register/admin/${id}`);
    return response.data;
  };

  const {
    data: trollList,
    isLoading,
    error,
  } = useQuery(["trollList", id], fetchTrollListDetails);

  const [status, setStatus] = useState("");
  const [verify, setVerify] = useState("");

  useEffect(() => {
    if (trollList) {
      setStatus(trollList.status);
      setVerify(trollList.verify);
    }
  }, [trollList]);

  // Mutation to update troll list details
  const updateTrollDetails = useMutation(
    async (updatedData) => {
      await apiClient.patch(
        `api/troll-register/admin/${id}/status`,
        updatedData
      );
    },
    {
      onSuccess: () => {
        toast.success("Troll details updated successfully");
        queryClient.invalidateQueries(["trollList", id]);
      },
      onError: () => {
        toast.error("Failed to update troll details");
      },
    }
  );

  const handleUpdate = (e) => {
    e.preventDefault();
    updateTrollDetails.mutate({ status, verify });
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading troll list</div>;

  return (
    <AdminLayout>
      <div className="bg-white w-full px-6 pt-6 h-[90.8vh]">
        <div className="lg:mx-8 mx-4">
          <div
            className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 mb-4 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine />
            <span>Back</span>
          </div>
          <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4">
            <h4 className="text-lg font-semibold mb-4 text-gray-800">
              Troll List Information:{" "}
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-greenC text-white">
                {trollList?.type}
              </span>
            </h4>
            <form onSubmit={handleUpdate} className="space-y-4">
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="text-sm text-gray-600">Name</label>
                  <div className="font-semibold text-gray-800">
                    {trollList?.name}
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-600">Platform</label>
                  <div className="font-semibold text-gray-800">
                    {trollList?.platform}
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-600">Social Media</label>
                  <div className="font-semibold text-gray-800">
                    {trollList?.socialMedia}
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-600">Date</label>
                  <div className="font-semibold text-gray-800">
                    {trollList?.date
                      ? new Date(trollList?.date).toLocaleDateString()
                      : "N/A"}
                  </div>
                </div>
              </div>
              {/* Full-row for Description */}
              <div className="grid grid-cols-1">
                <div>
                  <label className="text-sm text-gray-600">Description</label>
                  <div className="font-semibold text-gray-800">
                    {trollList?.description || "No description provided"}
                  </div>
                </div>
              </div>
              {/* Full-row for Generated Message */}
              <div className="grid grid-cols-1">
                <div>
                  <label className="text-sm text-gray-600">
                    Generated Message
                  </label>
                  <div className="font-semibold text-gray-800">
                    {trollList?.generatedMessage || "No message generated"}
                  </div>
                </div>
              </div>
              {/* Upload Photos */}
              <div className="grid grid-cols-1">
                <div>
                  <label className="text-sm text-gray-600">Upload Photos</label>
                  <div className="flex gap-4 mt-2">
                    {trollList?.trollsPhotosUrls &&
                    trollList.trollsPhotosUrls.length > 0 ? (
                      trollList.trollsPhotosUrls.map((item, index) => (
                        <img
                          key={index}
                          src={item.url}
                          alt="Uploaded"
                          className="w-64 h-64 object-cover rounded-md border border-gray-300 shadow-sm"
                        />
                      ))
                    ) : (
                      <span className="text-gray-600">No photo uploaded</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="text-sm text-gray-600">Status</label>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="approved">Approved</option>
                    <option value="unapproved">Unapproved</option>
                  </select>
                </div>
                <div>
                  <label className="text-sm text-gray-600">Verify</label>
                  <select
                    value={verify}
                    onChange={(e) => setVerify(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="verified">Verified</option>
                    <option value="unverified">Unverified</option>
                  </select>
                </div>
              </div>
              <button
                type="submit"
                className="mt-4 px-2 py-2 bg-greenC text-white rounded text-sm cursor-pointer"
              >
                Update Details
              </button>
            </form>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ReportedTrollListDetails;
