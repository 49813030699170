import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import React from "react";
import AdminLayout from "./AdminLayout";
import { Card, CardHeader, CardContent, Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { apiClient } from "../../config/axios";

const AdminProfile = () => {
  const fetchUserDetails = async () => {
    const { data } = await apiClient.get("api/user");
    return data;
  };

  const {
    data: connectedProfile,
    error,
    isLoading: connectedProfileLoading,
  } = useQuery("[connectedProfile]", fetchUserDetails, {
    staleTime: 1000,
  });

  return (
    <AdminLayout>
      <div className="!font-DMSans">
        <h4 className="font-bold mb-[80px] textSecondary">Profile</h4>

        <Grid container spacing={2} className=" ">
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Avatar
                sx={{
                  mb: 3,
                  height: 160,
                  width: 160,
                }}
              >
                <PersonIcon sx={{ fontSize: 120 }} />
              </Avatar>
              <Typography component="div" variant="h4">
                {connectedProfile?.lastName} {connectedProfile?.firstName}
              </Typography>
              <Typography variant="body2">{connectedProfile?.role}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card className="">
              <CardHeader title="Profile Information" />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="p" className="!mb-4">
                      Last Name:
                    </Typography>
                    <Box
                      component="div"
                      sx={{
                        marginBottom: 2,
                        padding: 2,
                        backgroundColor: "grey.100",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="body1" component="p">
                        {connectedProfile?.lastName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="p" className="!mb-4">
                      First Name:
                    </Typography>
                    <Box
                      component="div"
                      sx={{
                        marginBottom: 2,
                        padding: 2,
                        backgroundColor: "grey.100",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="body1" component="p">
                        {connectedProfile?.firstName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="p" className="!mb-4">
                      Email:
                    </Typography>
                    <Box
                      component="div"
                      sx={{
                        marginBottom: 2,
                        padding: 2,
                        backgroundColor: "grey.100",
                        borderRadius: 1,
                      }}
                    >
                      <Typography variant="body1" component="p">
                        {connectedProfile?.email}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </AdminLayout>
  );
};

export default AdminProfile;
