import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdDelete } from "react-icons/md";
import { LuDownload } from "react-icons/lu";
import { toast } from "react-toastify";

const FileUpload = ({
  onFilesChange,
  photosError,
  placeholder,
  isMultiplePicture,
}) => {
  const [fileObjects, setFileObjects] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles?.length) {
        const newFiles = acceptedFiles.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }));

        // Handle single or multiple uploads based on the isMultiplePicture prop
        if (isMultiplePicture) {
          setFileObjects((previousFiles) => [...previousFiles, ...newFiles]);
          onFilesChange([...fileObjects.map((f) => f.file), ...acceptedFiles]); // Pass files to parent
        } else {
          setFileObjects(newFiles);
          onFilesChange(acceptedFiles); // Single file upload
        }
      }
    },
    [fileObjects, onFilesChange, isMultiplePicture]
  );

  const onDropRejected = useCallback((fileRejections) => {
    fileRejections.forEach(({ file, errors }) => {
      errors.forEach((error) => {
        if (error.code === "file-too-large") {
          toast.error(`File size is too large: ${file.name}`);
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: 1024 * 1000 * 5, // 5MB
    onDrop,
    onDropRejected,
    multiple: isMultiplePicture, // Control multiple file upload based on prop
  });

  useEffect(() => {
    return () =>
      fileObjects.forEach(({ preview }) => URL.revokeObjectURL(preview));
  }, [fileObjects]);

  const removeFile = (preview) => {
    const updatedFiles = fileObjects.filter(
      (fileObj) => fileObj.preview !== preview
    );
    setFileObjects(updatedFiles);
    onFilesChange(updatedFiles.map((f) => f.file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fileObjects?.length) {
      toast.error("No files to upload");
      return;
    }

    const formData = new FormData();

    fileObjects.forEach(({ file }) => {
      formData.append("files", file);
    });

    formData.append("upload_preset", "friendsbook");

    try {
      const URL = process.env.NEXT_PUBLIC_CLOUDINARY_URL;
      if (!URL) {
        throw new Error("Cloudinary URL is not set in environment variables");
      }

      const response = await fetch(URL, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        let errorMessage = "Failed to upload files";
        if (response.status === 413) {
          errorMessage = "File size too large";
        } else if (response.status === 400) {
          errorMessage = "Bad request";
        }
        throw new Error(errorMessage);
      }

      const data = await response.json();
      toast.success("Upload successful");
    } catch (error) {
      toast.error(error.message || "An error occurred");
      console.error("Error uploading files:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="cursor-pointer">
      <div
        {...getRootProps({})}
        className="border-[1px] py-[40px] border-[#e0e0dc] pl-[30px] rounded-[10px]"
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center gap-4">
          <LuDownload />

          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p className="text-[#949492]">{placeholder}</p>
          )}
        </div>
      </div>
      {photosError && (
        <p style={{ color: "#CC2828", marginTop: "10px" }}>{photosError}</p>
      )}
      <section className="mt-10">
        <ul className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-10">
          {fileObjects.map(({ preview }, index) => (
            <li
              key={index}
              className="relative h-32 rounded-md shadow-lg mb-[60px]"
            >
              <img
                src={preview}
                alt={`File preview ${index}`}
                width={100}
                height={100}
                className="h-full w-full object-contain rounded-md"
              />
              <button
                type="button"
                className="w-7 h-7 border border-secondary-400 bg-secondary-400 rounded-full flex justify-center items-center absolute -top-3 -right-3 hover:bg-white transition-colors"
                onClick={() => removeFile(preview)}
              >
                <MdDelete className="text-red" />
              </button>
              <p className="mt-2 text-neutral-500 text-[12px] font-medium">
                {`File ${index + 1}`}
              </p>
            </li>
          ))}
        </ul>
      </section>
    </form>
  );
};

export default FileUpload;
