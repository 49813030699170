import { z } from "zod";

export const PaymentSummarySchema = z.object({
  email: z.string().min(1, { message: "Email is required" }).email(),
  firstName: z.string().min(1, { message: "First Name is required" }),
  lastName: z.string().min(1, { message: "Last Name is required" }),
  terms_and_conditions: z.boolean().refine((value) => value === true, {
    message: "You must accept the terms and conditions",
  }),
});
