import React from "react";
import { MdDelete } from "react-icons/md";

const SearchBar = ({ searchQuery, setSearchQuery }) => {
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClear = () => {
    setSearchQuery(""); // Clear the search query
  };

  console.log("SearchBar rendered", searchQuery);
  return (
    <div className=" flex items-center">
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearch}
        placeholder="Search users by name or username"
        className="w-full min-w-56 p-2 border rounded-l text-sm focus:outline-none focus:ring-2 focus:ring-primaryColor"
      />
      <button
        onClick={handleClear}
        className=" p-[10.6px] bg-red text-white rounded-r hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
        aria-label="Clear search"
      >
        <MdDelete />
      </button>
    </div>
  );
};

export default SearchBar;
