import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./index.css";
import { socialMediaOptions } from "./defaultValue";
import { WaitingListSchema } from "./validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient } from "../../config/axios";
import { useMutation } from "react-query";
import PlainHeader from "../../common/PlainHeader";
import { OverlayTrigger, Popover } from "react-bootstrap";

const WaitingList = () => {
  const navigate = useNavigate();
  const [loadingTriggerAPI, setLoadingTriggerAPI] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // const [rangeValue, setRangeValue] = useState(1);
  const [socialMediaData, setSocialMediaData] = useState(
    socialMediaOptions.map((option) => ({
      value: option.value,
      label: option.label,
      // socialMediaFollower: "",
      socialMediaUsername: "",
    }))
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      socialMedia: [{ value: "", socialMediaUsername: "" }],
      // publishing_account_for: "",
      gender: "",
      // worst_outcome: "",
      // damage_level: "1",
      email: "",
      firstName: "",
      lastName: "",
    },
    resolver: zodResolver(WaitingListSchema),
  });

  const mutation = useMutation((formData) =>
    apiClient.post(`api/waiting-list/user`, formData)
  );

  const filteredSocialMediaData = socialMediaData
    .filter((item) => item.isChecked)
    .map((item) => ({
      // socialMediaFollower: item.socialMediaFollower,
      socialMediaUsername: item.socialMediaUsername,
      value: item.value,
    }));

  const onSubmit = async (formData) => {
    const newFormData = {
      ...formData,
      socialMedia: filteredSocialMediaData,
    };
    setLoadingSubmit(true);
    try {
      await mutation.mutateAsync(newFormData);
      toast.success("Data added successfully");
      navigate("/");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoadingSubmit(false);
  };

  const triggerAPI = async (linkUrl) => {
    const formData = getValues();
    const newFormData = {
      ...formData,
      socialMedia: filteredSocialMediaData,
    };
    setLoadingTriggerAPI(true);
    try {
      await mutation.mutateAsync(newFormData);
      if (linkUrl === "individual") {
        navigate("/individual-survey");
      } else {
        window.open("https://forms.gle/ZFMhtkS6nhAkDLA27", "_blank");
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoadingTriggerAPI(false);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSocialMediaData((prevState) =>
      prevState.map((item) =>
        item.value === name ? { ...item, isChecked: checked } : item
      )
    );
  };

  const handleInputChange = (key, field, value) => {
    setSocialMediaData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item.value === key) {
          return { ...item, [field]: value };
        }
        return item;
      });
      return updatedData;
    });
  };

  const WaitingListPopover = (
    <Popover id="popover-basic" className="w-full">
      <Popover.Header
        as="h3"
        className="flex flex-col gap-3 px-6 py-2 text-left "
      >
        <p className="b-0 ml-[12px]">Are you?</p>
      </Popover.Header>
      <Popover.Body className="flex flex-col gap-3 p-1">
        <div className="w-full">
          <button
            className="hover:bg-baseColor px-6 py-2 hover:text-white font-semibold text-[#515050] w-full text-left"
            type="button"
            onClick={handleSubmit(() => triggerAPI("individual"))}
          >
            Individual or influencer
          </button>
        </div>
        <div className="w-full">
          <button
            className="hover:bg-baseColor px-6 py-2 hover:text-white font-semibold text-[#515050] w-full text-left"
            type="button"
            onClick={handleSubmit(() => triggerAPI("company"))}
          >
            Agency or someone who manages accounts for others.
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <PlainHeader />
      <div className="bg-[#fff] container py-[20px] text-[#000] ">
        <div className=" border rounded-[3px] p-4 max-w-5xl mx-auto mt-[69px]">
          <div className=" font-[200px] text-lg lg:!text-2xl text-primaryColor lg:!mb-[78px] mb-[40px] flex items-center justify-center">
            Waiting List
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="text-[14px]">
            <div className="row mb-[50px]  gap-1">
              <div className="col-sm">
                <div className="font-bold sm:text-[14px]">First Name</div>
                <input
                  {...register("firstName")}
                  type="text"
                  name="firstName"
                  className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
                  placeholder="Enter first name"
                ></input>
                {errors?.firstName && (
                  <p
                    style={{ color: "#CC2828", marginTop: "15px" }}
                    className="sm:text-[13px]"
                  >
                    {errors?.firstName?.message}
                  </p>
                )}
              </div>
              <div className="col-sm">
                <div className="font-bold sm:text-[14px]">Last Name</div>
                <input
                  {...register("lastName")}
                  type="text"
                  name="lastName"
                  className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
                  placeholder="Enter last name"
                ></input>
                {errors?.lastName && (
                  <p
                    style={{ color: "#CC2828", marginTop: "15px" }}
                    className="sm:text-[13px]"
                  >
                    {errors?.lastName?.message}
                  </p>
                )}
              </div>
            </div>
            <div className="row mb-[50px]  gap-1">
              <div className="col-sm">
                <div className="font-bold sm:text-[14px]">Email</div>
                <input
                  {...register("email")}
                  type="email"
                  name="email"
                  className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
                  placeholder="Enter email"
                ></input>
                {errors?.email && (
                  <p
                    style={{ color: "#CC2828", marginTop: "15px" }}
                    className="sm:text-[13px]"
                  >
                    {errors?.email?.message}
                  </p>
                )}
              </div>
            </div>

            <div className="row mb-[50px]">
              <div className="mb-[16px] font-bold sm:text-[14px]">
                Please mark the social/publishing sites you have an account on?
              </div>
              <div className="flex flex-wrap gap-[10px]">
                {socialMediaData.map((item) => (
                  <div
                    key={item.value}
                    className="flex items-center w-full flex-wrap"
                  >
                    <label className="sm:text-[13px] flex items-center w-[90px]">
                      <input
                        type="checkbox"
                        name={item.value}
                        checked={item.isChecked || false}
                        onChange={handleCheckboxChange}
                        className="mr-[11px]"
                      />
                      {item.label}
                    </label>
                    {item.isChecked && (
                      <div className="flex  gap-2 flex-wrap ">
                        <input
                          type="text"
                          className="text-[12px] bg-[#f3f3f0] p-1 w-[200px]"
                          name={item.value}
                          value={item.socialMediaUsername}
                          onChange={(e) =>
                            handleInputChange(
                              item.value,
                              "socialMediaUsername",
                              e.target.value
                            )
                          }
                          placeholder={`Enter ${item.label} username...`}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {errors?.socialMedia && (
                <p
                  style={{ color: "#CC2828", marginTop: "15px" }}
                  className="sm:text-[13px]"
                >
                  {errors?.socialMedia?.message}
                </p>
              )}
            </div>
            <div className="row mb-[50px]">
              <div className="mb-[16px] font-bold sm:text-[14px]">
                Would you like to complete a short, optional survey to help us
                understand more about the experience of online bullying and
                abuse and assist with product development improvements?
              </div>

              <div className=" w-14">
                <OverlayTrigger
                  placement="top"
                  overlay={WaitingListPopover}
                  trigger={"click"}
                >
                  <button
                    className="px-10 py-[9px] hover:bg-primaryColor border-[1px] border-primaryColor flex items-center justify-center gap-2  text-primaryColor hover:!border-primaryColor hover:text-white"
                    type="button"
                  >
                    {loadingTriggerAPI && (
                      <div class="spinner-border " role="status"></div>
                    )}
                    <div>Take a survey</div>
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <button
                disabled={loadingSubmit}
                className="submit-btn bg-primaryColor flex items-center justify-center gap-2 hover:bg-white hover:!border-[2px] hover:!border-primaryColor hover:text-primaryColor"
                type="submit"
              >
                {loadingSubmit && (
                  <div class="spinner-border" role="status"></div>
                )}
                <div>Submit</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default WaitingList;
