import React from "react";
import AdminLayout from "./AdminLayout";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { RiArrowLeftLine } from "react-icons/ri";
import { fetchWaitingUserList } from "./waitingList";
import Table from "react-bootstrap/Table";

import {
  genderOptions,
  directMessageOption,
  socialMediaPeople,
  messageFrequency,
  messageType,
} from "../../components/WaitingList/defaultValue";

const AdminWaitingListDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: waitingList,
    isLoading,
    error,
  } = useQuery("[waitingUserList]", () => fetchWaitingUserList());

  const waitingListDetail = waitingList?.find((list) => list.id === id);

  if (!waitingListDetail) {
    return <div>User not found</div>;
  }

  function filterObjectByKey(obj, key) {
    if (!obj || !obj[key]) {
      return {};
    }

    return Object.fromEntries(
      Object.entries(obj[key]).filter(([_, value]) => value)
    );
  }
  function isEmpty(obj) {
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        obj[key] !== undefined &&
        obj[key] !== null &&
        obj[key] !== ""
      ) {
        return false;
      }
    }
    return true;
  }

  return (
    <AdminLayout>
      <div className="bg-white w-full   h-[90.8vh]">
        <div className="lg:mx-8  mx-4 ">
          <div
            className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3  mb-4"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine />
            Back
          </div>

          <div className="row mb-[50px]  gap-1">
            <div className="col-sm">
              <div className="font-bold sm:text-[14px]">First Name</div>
              <input
                className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
                disabled
                value={waitingListDetail?.firstName}
              ></input>
            </div>
            <div className="col-sm">
              <div className="font-bold sm:text-[14px]">Last Name</div>
              <input
                disabled
                value={waitingListDetail?.lastName}
                className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
              ></input>
            </div>
          </div>
          <div className="row mb-[50px]  gap-1">
            <div className="col-sm">
              <div className="font-bold sm:text-[14px]">Email</div>
              <input
                disabled
                className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
                value={waitingListDetail?.email}
              ></input>
            </div>
            <div className="col-sm">
              <div className="w-[100%]">
                <div className="mb-[16px] font-bold sm:text-[14px]">Gender</div>
                <div className="mb-4">
                  <div className="grid gap-[10px]  sm:grid-cols-2 md:grid-cols-3">
                    {genderOptions.map((option) => (
                      <div key={option.value} className="]">
                        <label className="box sm:text-[13px] ">
                          <input
                            type="radio"
                            checked={
                              waitingListDetail?.gender === option?.value
                            }
                            className="mr-[11px] "
                          />
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm">
              <div className="font-bold sm:text-[14px]">Username</div>
              <input
                disabled
                value={waitingListDetail?.username}
                className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
              ></input>
            </div> */}
          </div>
          {/* <div className="mb-[40px]">
            <div className="font-bold sm:text-[14px]">Location</div>

            <input
              className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px] rounded-[4px]"
              // onPlaceSelected={onPlaceSelected}
              disabled
              value={waitingListDetail?.location}
            />
          </div> */}
          <div className="row mb-[36px]">
            {/* <div className="mb-[16px] font-bold sm:text-[14px]"> Age</div>
            <div className="">
              <div className="grid gap-[10px]  sm:grid-cols-2 md:grid-cols-3">
                {ageOptions.map((option) => (
                  <div key={option.value} className="">
                    <label className="sm:text-[13px]">
                      <input
                        type="radio"
                        className="mr-[11px]"
                        checked={waitingListDetail?.age === option?.value}
                      />
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
          <div className="row mb-[50px]">
            <div className="mb-[16px] font-bold sm:text-[14px]">
              Please mark the social/publishing sites you have an account on?
            </div>
            <div className="flex flex-wrap gap-[10px]">
              {waitingListDetail?.socialMedia.map((item) => (
                <div
                  key={item.value}
                  className="flex items-center w-full flex-wrap"
                >
                  <label className="sm:text-[13px] flex items-center w-[90px]">
                    <input
                      type="checkbox"
                      checked={item.value === item.value}
                      className="mr-[11px]"
                    />
                    {item.value}
                  </label>

                  <div className="flex  gap-2 flex-wrap ">
                    {/* <input
                      type="text"
                      className="text-[12px] bg-[#f3f3f0] p-1 w-[200px]"
                      name={item.value}
                      value={item.socialMediaFollower}
                      placeholder={`Enter ${item.label} username...`}
                    /> */}
                    <input
                      type="text"
                      className="text-[12px] bg-[#f3f3f0] p-1 w-[200px]"
                      name={item.value}
                      value={item.socialMediaUsername}
                      placeholder={`Approx. number of followers...`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row mb-[50px]">
            <div className="mb-[16px] font-bold sm:text-[14px]">
              I use my social media or publishing account for?
            </div>
            <div className="">
              <div className="grid gap-[10px] flex-wrap md:grid-cols-2">
                {mediaUsesOptions.map((option) => (
                  <div key={option.value} className="rounded-[3px]">
                    <label className="sm:text-[13px]">
                      <input
                        type="radio"
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.publishing_account_for ===
                          option?.value
                        }
                      />
                      {option.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* direct unsolicited message scetion */}
          <div className="mb-[50px]">
            <div className="mb-[16px] font-bold sm:text-[14px]">
              Have you ever been sent a direct message on any social platform
              you would consider unsolicited, unexpected or unwanted from?
            </div>
            <Table responsive>
              <thead>
                <tr className="sm:text-[13px]">
                  <th></th>
                  {directMessageOption.map((data, index) => (
                    <th key={index}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="sm:text-[13px]">
                {socialMediaPeople.map((data, index) => (
                  <tr key={index}>
                    <th scope="row">{data?.label}</th>
                    <td>
                      <input
                        type="radio"
                        className="mr-[11px] "
                        checked={
                          waitingListDetail?.unsolicited_unexpected_unwanted?.[
                            data.value
                          ] === directMessageOption[0]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        className="mr-[11px] "
                        checked={
                          waitingListDetail?.unsolicited_unexpected_unwanted?.[
                            data.value
                          ] === directMessageOption[1]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.unsolicited_unexpected_unwanted?.[
                            data.value
                          ] === directMessageOption[2]
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {/* Message type scetion */}
          <div className="mb-[50px]">
            <div className="mb-[16px] font-bold sm:text-[14px]">
              How often have you received sexually explicit or abusive contact
              types of messages from?
            </div>
            <Table responsive>
              <thead>
                <tr className="sm:text-[13px]">
                  <th></th>
                  {messageFrequency.map((data, index) => (
                    <th key={index}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="sm:text-[13px]">
                {messageType.map((data, index) => (
                  <tr key={index}>
                    <th scope="row">{data?.label}</th>
                    <td>
                      <input
                        type="radio"
                        name={`explicit_types_of_messages.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.explicit_types_of_messages?.[
                            data.value
                          ] === messageFrequency[0]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`explicit_types_of_messages.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.explicit_types_of_messages?.[
                            data.value
                          ] === messageFrequency[1]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`explicit_types_of_messages.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.explicit_types_of_messages?.[
                            data.value
                          ] === messageFrequency[2]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`explicit_types_of_messages.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.explicit_types_of_messages?.[
                            data.value
                          ] === messageFrequency[3]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`explicit_types_of_messages.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.explicit_types_of_messages?.[
                            data.value
                          ] === messageFrequency[4]
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          {/* Response section
          <div className="mb-[50px]">
            <div className="mb-[16px] font-bold sm:text-[14px]">
              If/when this occurs to you, what is your sense of this and how do
              you respond?
            </div>
            <Table responsive>
              <thead>
                <tr className="sm:text-[13px]">
                  <th></th>
                  {responseAction.map((data, index) => (
                    <th key={index}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="sm:text-[13px]">
                {responseMessage.map((data, index) => (
                  <tr key={index}>
                    <th scope="row">{data?.label}</th>
                    <td>
                      <input
                        type="radio"
                        name={`sense_of_respond.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.sense_of_respond?.[data.value] ===
                          responseAction[0]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`sense_of_respond.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.sense_of_respond?.[data.value] ===
                          responseAction[1]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`sense_of_respond.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.sense_of_respond?.[data.value] ===
                          responseAction[2]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`sense_of_respond.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.sense_of_respond?.[data.value] ===
                          responseAction[3]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`sense_of_respond.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.sense_of_respond?.[data.value] ===
                          responseAction[4]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`sense_of_respond.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.sense_of_respond?.[data.value] ===
                          responseAction[5]
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="mb-[50px]">
            <div className="font-bold sm:text-[14px]">
              What is the worst outcome or example you're aware of in
              circumstances where unsolicited or unwanted sexual or violent or
              bullying content has been sent in direct messages on social media
              platforms for yourself or someone you know personally?
            </div>
            <input
              disabled
              className="bg-[#eeecec] h-[42px] w-[100%] mt-[20px] pl-[6px] sm:text-[13px]"
              value={waitingListDetail?.worst_outcome}
            ></input>
          </div>
          
          <div className="mb-[50px]">
            <div className="mb-[16px] font-bold sm:text-[14px]">
              Have you ever tried any filters or tools to moderate your incoming
              social media messages?
            </div>
            <Table responsive>
              <thead>
                <tr className="sm:text-[13px]">
                  <th></th>
                  {filterReason.map((data, index) => (
                    <th key={index}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody className="sm:text-[13px]">
                {filterResponse.map((data, index) => (
                  <tr key={index}>
                    <th scope="row">{data?.label}</th>
                    <td>
                      <input
                        type="radio"
                        name={`moderate_tools.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.moderate_tools?.[data.value] ===
                          filterReason[0]
                        }
                      />
                    </td>
                    <td>
                      <input
                        name={`moderate_tools.${data?.value}`}
                        className="mr-[11px]"
                        type="radio"
                        checked={
                          waitingListDetail?.moderate_tools?.[data.value] ===
                          filterReason[1]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`moderate_tools.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.moderate_tools?.[data.value] ===
                          filterReason[2]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`moderate_tools.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.moderate_tools?.[data.value] ===
                          filterReason[3]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`moderate_tools.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.moderate_tools?.[data.value] ===
                          filterReason[4]
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        name={`moderate_tools.${data?.value}`}
                        className="mr-[11px]"
                        checked={
                          waitingListDetail?.moderate_tools?.[data.value] ===
                          filterReason[5]
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="mb-[50px]">
            <div className="font-bold mb-[30px] sm:text-[14px] ">
              My perception of the level of damage caused by unsolicited or
              unwanted sexual, violent or bullying content sent online, to
              individuals and our culture is...
            </div>
            <div className="flex justify-between  items-center flex-wrap">
              <div className="max-w-[200px] sm:text-[13px]">
                No damage at all - this isn't at all problematic and the status
                quo is fine
              </div>
              <div>
                <RangeSlider
                  className="w-[200px] py-[10px]"
                  min={1}
                  max={10}
                  value={waitingListDetail?.damage_level}
                />
              </div>
              <div className="max-w-[200px] sm:text-[13px]">
                Extremely high level of damage - it's a widespread problem for
                youth and adults, and is corrosive to the culture
              </div>
            </div>
          </div>    */}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminWaitingListDetail;
