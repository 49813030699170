import React, { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";

const WhiteListUserItem = ({
  userDetail,
  onWhiteListChange,
  checkedData,
  uncheckedData,
}) => {
  const [isWhiteListed, setIsWhiteListed] = useState(false);

  useEffect(() => {
    const isChecked = checkedData.some(
      (user) => user.username === userDetail.username
    );
    const isUnchecked = uncheckedData.some(
      (user) => user.username === userDetail.username
    );

    setIsWhiteListed(isChecked);
  }, [checkedData, uncheckedData, userDetail]);

  const handleWhiteList = (event) => {
    const isChecked = event.target.checked;
    setIsWhiteListed(isChecked);
    onWhiteListChange(userDetail, isChecked);
  };

  return (
    <div className="flex bg-white justify-between p-[9px] rounded-[10px] items-center">
      <div className="flex items-center gap-3">
        <img
          src={userDetail?.profileImageUrl}
          alt="user"
          className="w-[40px] h-[40px] rounded-full"
        />
        <div className="flex flex-col text-[10px]">
          <div>{userDetail?.name}</div>
          <div className="font-bold">{userDetail?.username}</div>
        </div>
      </div>
      <div>
        <Checkbox
          className="text-primaryColor"
          checked={isWhiteListed}
          onChange={handleWhiteList}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
    </div>
  );
};

export default WhiteListUserItem;
