import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../Onboarding/TwitterConectedProfiles/TwitterConnectedProfiles.css';
import { RiArrowLeftLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import 'react-responsive-modal/styles.css';
import { useQuery } from 'react-query';
import { useMediaQuery, useTheme } from '@mui/material';
import { FaCheckCircle } from 'react-icons/fa';
import OnBoardingLayout from '../../layouts/OnBoardingLayout';
import { apiClient } from '../../config/axios';
import Loader from '../../components/Loader';
import { useUserDetails } from '../../hooks/useUserDetails';

// Component for Profile List Item
const ProfileListItem = ({ profile, isDesktop }) => (
  <Link to={`/instagram/${profile.id}/messages`} className="no-underline">
    <div className="flex items-center justify-between gap-2 mb-3 lg:px-10 sm:!px-[18px] py-3 bg-[#eff6f6] hover:shadow-md">
      <div className="flex flex-row gap-3 items-center">
        {console.log('imageUrl', profile?.platformPicUrl)}
        <img
          src={profile?.platformPicUrl}
          className="h-8 w-8 "
          alt={'profile'}
          onError={(e) => {
            e.target.src = 'https://via.placeholder.com/150'; // Fallback image
          }}
        />
        <div className="text-[#000]">
          <span className="text-sm">
            Instagram Id: <b>@{profile.platformUsername}</b>
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center font-bold">
        <div className="px-[26px] py-2 text-[#54bc64] text-[17px] gap-2 flex items-center border-[1px] border-[#54bc64]">
          {isDesktop ? 'Open Messages' : 'Open'}
        </div>
      </div>
    </div>
  </Link>
);

// Component for Connected Messages
const ConnectedMessages = ({ participantData }) => (
  <div className="pt-2">
    <div className="relative">
      <div className="max-h-[calc(100vh-20rem)] min-h-24 overflow-y-auto pt-4">
        {participantData?.data?.length ? (
          <div className="flex flex-col items-start lg:w-1/2 border-[1px] border-[#dddddd] rounded-[20px] shadow-md py-2">
            {participantData.data.map((page, index) => (
              <div
                key={index}
                className="flex items-center w-full px-4 py-3 gap-3 border-b border-[#dddddd] last:border-none"
              >
                <div className="relative flex flex-row gap-3 items-center">
                  <img
                    src={page.profile_pic_url}
                    className="h-8 w-8 rounded-full"
                    alt={page.username}
                  />
                  <div className="absolute bottom-0 right-0 bg-white rounded-full p-0.5">
                    <FaCheckCircle className="text-[#41964e]" fontSize={14} />
                  </div>
                </div>
                <div>
                  <span className="text-sm">
                    Instagram Id: <b>@{page.username}</b>
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-[17px] font-semibold">No data found!</div>
        )}
      </div>
    </div>
  </div>
);

const PrivateInstagramConnectedProfile = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const {
    data: connectedProfile,
    isLoading: isUserLoading,
    error: isUserError,
  } = useUserDetails();

  const {
    isLoading: isThreadLoading,
    error: threadError,
    isSuccess: isThreadSuccess,
  } = useQuery(
    'instagram-thread-data',
    async () => await apiClient.get('api/instagram-private-api/v2/get-threads')
  );

  const {
    isLoading: isParticipantLoading,
    data: participantData,
    error: participantsError,
  } = useQuery(
    'instagram-connected-profiles',
    async () => await apiClient.get('api/instagram-private-api/participants'),
    { enabled: isThreadSuccess }
  );

  // Handle errors and display messages
  useEffect(() => {
    if (threadError)
      toast(threadError.response?.data?.message || 'An error occurred');
    if (participantsError)
      toast(participantsError.response?.data?.message || 'An error occurred');
    if (isUserError)
      toast(isUserError.response?.data?.message || 'An error occurred');
  }, [threadError, participantsError, isUserError]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="flex flex-col bg-white w-full h-screen px-3 pt-3">
        <div
          className="back-btn flex items-center gap-2 py-1 px-3 m-4"
          onClick={() => navigate(-1)}
        >
          <RiArrowLeftLine /> Back
        </div>

        {isThreadLoading ? (
          <Loader />
        ) : (
          <div className="m-4">
            <h4 className="mb-3">Connected Profiles</h4>

            {isParticipantLoading ? (
              <Loader />
            ) : (
              <div>
                {connectedProfile?.userProfile?.map(
                  (profile, index) =>
                    profile?.platform === 'instagram' && (
                      <ProfileListItem
                        key={index}
                        profile={profile}
                        isDesktop={isDesktop}
                      />
                    )
                )}
              </div>
            )}

            <h4 className="mb-3 mt-5">Connected Messages</h4>
            <ConnectedMessages participantData={participantData} />
          </div>
        )}
      </div>
    </OnBoardingLayout>
  );
};

export default PrivateInstagramConnectedProfile;
