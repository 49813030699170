import React from "react";
import defaultImage from "../../assets/images/defaultImage.png";

const SelectedUserDetails = ({ user }) => {
  return (
    <div className="flex flex-row justify-between items-center p-3 border-b border-lightGrey shadow-sm">
      <div className="flex flex-row gap-2 justify-between items-start rounded-lg py-2 cursor-pointer">
        <img
          className="rounded-full w-9 h-9"
          src={user.profile_pic || defaultImage}
          alt="User"
        />
        <div className="flex flex-col flex-grow">
          <div className="font-bold">
            {user.first_name} {user.last_name}
          </div>
          <div className="text-grey text-sm">last seen today at 12:02 PM</div>
        </div>
      </div>
    </div>
  );
};

export default SelectedUserDetails;
