import React, { createContext, useContext, useState, useEffect } from "react";
import { apiClient } from "../config/axios";

const InviteTokenContext = createContext();

export const useInviteToken = () => {
  return useContext(InviteTokenContext);
};

export const InviteTokenProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [invitedToken, setInvitedToken] = useState(null);
  const [inviterUserId, setInviterUserId] = useState(null);
  const [inviteTokenValidated, setInviteTokenValidated] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [linkInvited, setLinkInvited] = useState(null);

  useEffect(() => {
    const inviteToken = localStorage.getItem("inviteToken");

    if (inviteToken) {
      apiClient
        .get(`invites/validate?token=${inviteToken}`)
        .then((response) => {
          const invitedTo = response?.data?.invite?.invitedTo;
          const inviterId = response?.data?.invite?.inviter?.id;
          const token = response?.data?.invite?.token;
          setInvitedToken(token);
          setInviterUserId(inviterId);
          setMessage(response.data.message);
          setInviteTokenValidated(true);
          setLoading(false);
          localStorage.removeItem("inviteToken");
          if (invitedTo) {
            setRedirectTo(invitedTo);
          }
        })
        .catch(() => {
          setMessage("Invalid or expired invite token.");
          setInviteTokenValidated(true);
          setLoading(false);
        });
    } else {
      setMessage("No invite token found.");
      setInviteTokenValidated(true);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (inviteTokenValidated && invitedToken && !linkInvited) {
      apiClient
        .post(`invites/accept/${invitedToken}`, { inviteeId: inviterUserId })
        .then((response) => {
          const status = response.data.status;
          setLinkInvited(status);
          setMessage("Invitation accepted. Points have been added.");
        })
        .catch(() => {
          setMessage("Failed to accept the invitation. Please try again.");
        });
    }
  }, [inviteTokenValidated, invitedToken, inviterUserId]);

  useEffect(() => {
    if (inviteTokenValidated && redirectTo) {
      window.location.href = redirectTo;
    }
  }, []);

  return (
    <InviteTokenContext.Provider
      value={{ invitedToken, inviterUserId, message, loading }}
    >
      {children}
    </InviteTokenContext.Provider>
  );
};
