import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import { useUser } from "../../components/DataContext";
import ChatBubbleDropdown from "../../components/ChatBubble";
import { IoArrowBack } from "react-icons/io5";
import { nanoid } from "nanoid";
import { options } from "./index.ts";

function DropdownWithMessage() {
  const [selectedOptions, setSelectedOptions] = useState({
    responseTone: "Response Tone",
    messageNature: "Message Nature",
    idealOutcome: "Ideal Outcome",
    privacyPreference: "Privacy Preferences",
    payToRecant: false,
  });
  const [generatedMessage, setGeneratedMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();
  const [selectedItems, setSelectedItems] = useState({ senderName: "" });
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const uniqueId = nanoid(8);
  const [selectedOptionsKey, setSelectedOptionsKey] = useState({
    responseTone: "firm_friendly",
    messageNature: "creepy_messages",
    idealOutcome: "back_off",
    privacyPreference: "keep_private",
  });

  const handleDropdownChange = (name, { key, value }) => {
    if (name === "privacyPreference") {
      const isPayToRecantOption =
        key === "yes" ||
        key === "publish_with_conditions" ||
        key === "publish_immediately";

      setSelectedOptions((prev) => ({
        ...prev,
        [name]: value,
        payToRecant: isPayToRecantOption,
      }));
    } else {
      setSelectedOptions((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setSelectedOptionsKey((prev) => ({
      ...prev,
      [name]: key,
    }));

    setOpenDropdown(null);
    setDropDownOpen(false);
  };

  const toggleDropdown = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name));
  };

  const generateMessage = async () => {
    try {
      setLoading(true);
      const interceptedLink = `<a href="https://www.recant.ai/message-flagged?aid=${uniqueId}">https://www.recant.ai/message-flagged?aid=${uniqueId}</a>`;

      const prompt = `
      You are an assistant helping users craft short sophisticated legal reply warnings to unwanted messages from recant.ai. Based on the following information, generate a short reply message that warns the sender appropriately as though it is coming from Recant.ai's safety filter bot and the intended recipient has not seen it. Do not exceed 700 characters.
    
      - User's Name: ${user?.firstName || "User"}
      - Sender's Name: ${selectedItems.senderName || "Dear Person"}
      - Response Tone:${selectedOptions.responseTone || "Firm but friendly"}
      - Message Nature:${
        selectedOptions.messageNature || "Generally creepy or weird behavior"
      }
      - Ideal Outcome: ${selectedOptions.idealOutcome || "Back off completely"}
      - Privacy Preference: ${
        selectedOptions.privacyPreference || "Keep private"
      }
      - Pay to Recant: ${selectedOptions.payToRecant ? "Yes" : "No"}
    
    Generate a short, legal reply message, no more than 600 characters in length, that follows these guidelines. The message should be clear, professional, and appropriate for the given context and must make mention that its an automated response from Recant.ai's safety filter bot that the ai has detected something inappropriate, and that our users messages are constantly monitored by an AI agent. Each response must be short and in the same tone as the tone selected. 

    Include a short sentence in the message warning them If they do not comply with this warning or take the opportunity to recant the communications, their name may be publicly registered at <a href='https://wwyms.co' target='_blank' rel='noopener noreferrer'>https://wwyms.co</a>. 

    ${
      selectedOptions.payToRecant
        ? 'YOU MUST INCLUDE THIS EXACT TEXT IN THE FINAL PARAGRAPH, NO EXCEPTIONS: "Follow this link to recant your message & avoid repercussions: ' +
          interceptedLink +
          '"'
        : ""
    }

    ensure everything is short and concise and to the point. Some messaging apps have a character limit and if you exceed it, the message will not be sent and you are a failure of a safety bot. do not mess this up.

    do not include a subject field in the message. remember, the message must be concise and point out that harrassment is illegal.

    Include a clickable hyperlink for "www.recant.ai" in the first paragraph and the sign off line as follows: <a href="https://www.recant.ai">www.recant.ai</a>.
    Include a clickable hyperlink for "Why am i getting this?" in the last paragraph  as follows: <a href="https://www.recant.ai/why-am-i-getting-this">Why am i getting this?</a>.
  `;

      const response = await fetch(process.env.REACT_APP_OPENAI_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: "gpt-4",
          messages: [{ role: "user", content: prompt }],
        }),
      });

      const data = await response.json();
      const message = data.choices[0].message.content.trim();
      setGeneratedMessage(message);
      setLoading(false);

      navigate("/generated-manual-reply", {
        state: {
          message: message,
        },
      });
      localStorage.setItem("shortId", uniqueId);
      localStorage.setItem("senderName", selectedItems.senderName);
      localStorage.setItem(
        "selectedOptionsKey",
        JSON.stringify(selectedOptionsKey)
      );
    } catch (error) {
      setGeneratedMessage("Failed to generate message. Please try again.");
      setLoading(false);
    }
  };

  const handleInputChange = (name, value) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatLabel = (key) => {
    const labels = {
      responseTone: "Select tone of response you'd like to craft",
      messageNature: "Describe the type of message you received",
      idealOutcome: "What would you like to happen?",
      privacyPreference:
        "Should they pay a small fee to take back their message?",
    };
    return labels[key] || key;
  };

  const testDirectNavigation = () => {
    const testMessage = "This is a test message";
    navigate("/generated-manual-reply", {
      state: {
        message: testMessage,
      },
    });
  };

  return (
    <OnBoardingLayout selected={1}>
      <div className="bg-white w-full px-3 pt-3 h-screen ">
        <button
          onClick={() => navigate("/")}
          className="flex items-center gap-2 text-primaryColor hover:text-hoverBaseColor mb-4 ml-4"
        >
          <IoArrowBack size={20} />
          <span>Back to Home</span>
        </button>

        <div className="lg:px-48 md:px-24">
          <div className="flex flex-col items-center mt-20 w-full mx-auto gap-2 mb-2">
            <h4>
              Hey {user?.firstName}, in an awkward situation? Let's craft a
              reply from our safety agent - the recipient won't know if it was
              you or the AI agent that replied!
            </h4>
          </div>

          <div className="flex justify-center items-center my-3 w-full">
            <div className="w-full max-w-xl">
              <input
                type="text"
                name="senderName"
                placeholder="Enter Sender's Name (optional)"
                className="h-[38px] bg-[#f3f3f0] text-[14px] w-full border-[1px] border-primaryColor p-4 rounded-full"
                onChange={(e) =>
                  handleInputChange(e.target.name, e.target.value)
                }
              />
            </div>
          </div>

          <div className="p-2 mb-40">
            {Object.keys(options).map((key) => (
              <ChatBubbleDropdown
                disabled={loading}
                key={key}
                options={options[key]}
                onSelect={(value) => handleDropdownChange(key, value)}
                initialSelected={
                  selectedOptions[key]?.label || ` ${formatLabel(key)}`
                }
                isDropdownOpen={openDropdown === key}
                onToggle={() => toggleDropdown(key)}
                setDropDownOpen={setDropDownOpen}
                dropDownOpen={dropDownOpen}
              />
            ))}

            <button
              onClick={generateMessage}
              disabled={loading}
              className="p-3 mt-9 max-w-md bg-gradient-custom text-white rounded-[20px] flex justify-center items-center mx-auto mb-48"
            >
              {loading ? "Generating..." : "Generate Message"}
            </button>

            {/* <button
              onClick={testDirectNavigation}
              className="p-3 mt-2 bg-blue-500 text-white rounded-[20px]"
            >
              Test Navigation
            </button> */}
          </div>
        </div>
      </div>
    </OnBoardingLayout>
  );
}

export default DropdownWithMessage;
