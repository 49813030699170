import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { HiMiniUserCircle } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../../components/DataContext";
import { RiApps2Fill } from "react-icons/ri";
import { HiOutlineLogout } from "react-icons/hi";
import { useTheme, useMediaQuery } from "@mui/material";
import { WWYMS_URL } from "../../../global";
import useLogout from "../../../hooks/useLogoutUsers";
import JoinBetaButton from "../../../components/LandingPage/NavItem";
import WWYMSButton from "../../../components/LandingPage/WWYMSButton";
import ResponsiveDrawer from "../../../components/Admin/Drawer";
import { landingPageDrawer } from "../../../components/LandingPage/Header";
import { RxHamburgerMenu } from "react-icons/rx";

const TopNavBar = ({ color }) => {
  const navigate = useNavigate();
  const currentUrl = useLocation();
  const { user } = useUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const logoutUser = useLogout();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const excludedPaths = [
    "/login",
    "/forgot-password",
    "/reset-password",
    "/signup",
  ];

  const filteredLandingPageDrawer = landingPageDrawer.filter((item) => {
    if (user) {
      return (
        item.title === "Dashboard" ||
        item.title === "WWYMS" ||
        item.title === "Logout"
      );
    } else {
      return (
        item.title === "Login" ||
        item.title === "Join Beta" ||
        item.title === "WWYMS"
      );
    }
  });

  const showDropdown = user && !excludedPaths.includes(currentUrl.pathname);

  // Function to toggle the drawer
  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div className="w-full flex items-center justify-between py-[1px] z-10 cursor-pointer">
      <div
        className={`flex items-center ${
          color ? "text-primaryColor" : "text-baseColor"
        }`}
      >
        {/* Always show hamburger menu on mobile */}
        {!isDesktop && !user && (
          <button onClick={handleDrawerToggle} className="lg:hidden">
            <RxHamburgerMenu className="mt-1" size={20} />
          </button>
        )}

        {/* Show greeting message and dropdown only if user is logged in */}
        {user ? (
          <>
            {isDesktop && user?.firstName && (
              <span
                className={`mr-3 font-bold ${
                  color ? "text-primaryColor" : "text-baseColor"
                }`}
              >
                Hi, {user.firstName}
              </span>
            )}

            {showDropdown && (
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                  <HiMiniUserCircle
                    className={`object-contain w-10 h-10 ${
                      color ? "text-primaryColor" : "text-baseColor"
                    }`}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="px-10 w-48 !left-[-290%]">
                  {user.role !== "admin" &&
                    currentUrl.pathname !== "/dashboard" && (
                      <div
                        className="dropdown-item !flex items-center gap-2"
                        onClick={() => navigate("/dashboard")}
                      >
                        <RiApps2Fill /> Dashboard
                      </div>
                    )}
                  {user.role === "admin" && (
                    <div
                      className="dropdown-item !flex items-center gap-2"
                      onClick={() => navigate("/admin")}
                    >
                      <RiApps2Fill /> Admin Dashboard
                    </div>
                  )}

                  <div
                    className="dropdown-item !flex items-center gap-2 !text-[#606060]"
                    onClick={logoutUser}
                  >
                    <HiOutlineLogout /> Logout
                  </div>

                  <div className="dropdown-item !flex items-center gap-2">
                    <a
                      href={WWYMS_URL}
                      className="!text-[#606060] no-underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WWYMS
                    </a>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        ) : (
          // Show the drawer if logged out and screen is mobile-sized
          <ResponsiveDrawer
            open={openDrawer}
            handleDrawerToggle={handleDrawerToggle}
            sideBarItems={filteredLandingPageDrawer}
          />
        )}

        {/* Show login, join beta, and WWYMS buttons if logged out */}
        {!user && isDesktop && (
          <div className="flex items-center gap-4">
            <button
              className={` text-sm text-baseColor cursor-pointer font-bold lg:font-normal lg:!text-base font-poppins hover:font-semibold mr-2`}
              onClick={() => navigate(`/login`)}
            >
              Login
            </button>
            <JoinBetaButton label="Join Beta" />
            <WWYMSButton />
          </div>
        )}
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="flex items-center justify-center w-9 h-9 rounded-full"
  >
    {children}
  </span>
));

export default TopNavBar;
