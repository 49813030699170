import React, { useState } from "react";
import "../../App.css";
import { useForm } from "react-hook-form";
import ButtonField from "../../common/ButtonField";
import FileUpload from "../../common/FileUpload";
import { z } from "zod";
import { useMutation } from "react-query";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { zodResolver } from "@hookform/resolvers/zod";
import AdminLayout from "./AdminLayout";

const reportFormSchema = z.object({
  name: z.string().min(1, { message: "Offender name is required" }),
  socialMedia: z.string().optional(),
  platform: z.string().min(1, { message: "Platform is required" }),
  description: z.string().min(1, { message: "Description is required" }),
  date: z.string().optional(),
});

const ReportWarnedMember = () => {
  const [uploadedProfilePicture, setUploadedProfilePicture] = useState([]);
  const [photosError, setPhotosError] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date());

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(reportFormSchema),
  });
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const onSaveSubmit = (data) => {
    const formData = new FormData();
    const formattedDate = value.toISOString().split("T")[0];
    formData.append("name", data.name);
    formData.append("socialMedia", data.socialMedia);
    formData.append("platform", data.platform);
    formData.append("description", data.description);
    formData.append("date", formattedDate);

    if (uploadedProfilePicture[0]) {
      formData.append("profilePicture", uploadedProfilePicture[0]);
    }

    mutation.mutate(formData);
  };

  const mutation = useMutation(
    (formData) => apiClient.post(`/api/troll-register`, formData),
    {
      onSuccess: () => {
        toast.success("Troll registered successfully");
        navigate("/");
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
    }
  );

  return (
    <AdminLayout>
      <form
        onSubmit={handleSubmit(onSaveSubmit)}
        className="py-10 sm:px-4 md:px-10 xl:px-64 flex flex-col gap-3"
      >
        <div className="px-2">
          <div className="flex flex-wrap gap-4 mb-4">
            <div className="flex-1">
              <label>Name of offender:</label>
              <input
                name="name"
                {...register("name", { required: "Name is required" })}
                placeholder="Enter Name"
                className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
              />
              {errors.name && (
                <span className="text-red !mt-3">{errors.name.message}</span>
              )}
            </div>
            <div className="flex-1">
              <label>Social Media Handle:</label>
              <input
                name="socialMedia"
                {...register("socialMedia", {
                  required: "Handle is required",
                })}
                placeholder="Enter Social Media Handle"
                className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-4 mb-4">
            <div className="flex-1">
              <div>Date of offence</div>
              <DatePicker
                onChange={handleChange}
                value={value}
                className="h-[48px] w-[100%] rounded-[10px] bg-[#f3f3f0] text-[14px] mt-2"
                calendarClassName="rounded-lg shadow-lg"
                clearIcon={null}
                style={{ border: "none" }}
              />
              {errors.date && (
                <span className="text-red !mt-3">{errors.date.message}</span>
              )}
            </div>
            <div className="flex-1">
              <label>Platform:</label>
              <input
                name="platform"
                {...register("platform", {
                  required: "Handle is required",
                })}
                placeholder="Enter Platform where offence took place"
                className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
              />
              {errors.platform && (
                <span className="text-red !mt-3">
                  {errors.platform.message}
                </span>
              )}
            </div>
          </div>

          <div>
            <div className="mb-2">Upload Profile Picture</div>
            <FileUpload
              onFilesChange={setUploadedProfilePicture}
              photosError={photosError}
              placeholder={"Drag & drop a profile picture"}
              isMultiplePicture={false}
            />
          </div>

          <div>
            <div className="mb-2">Describe what happened</div>
            <textarea
              {...register("description", {
                required: "Description is required",
              })}
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            />
            {errors.description && (
              <span className="text-red !mt-3">
                {errors.description.message}
              </span>
            )}
          </div>

          <ButtonField label={"SUBMIT"} isLoading={mutation.isLoading} />
        </div>
      </form>
    </AdminLayout>
  );
};

export default ReportWarnedMember;
