export const termsAndConditionFormattedContent = (text) => {
  const boldPattern = /\*\*(.*?)\*\*/g;
  const urlPattern = /\[url\]\((.*?)\)/g;
  const emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;

  const parts = text.split(
    /(\*\*.*?\*\*|\[url\]\(.*?\)|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})/g
  );

  return parts.map((part, i) => {
    if (boldPattern.test(part)) {
      return <strong key={i}>{part.replace(/\*\*/g, "")}</strong>;
    } else if (urlPattern.test(part)) {
      const url = part.match(/\[url\]\((.*?)\)/)[1];
      return (
        <a
          key={i}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primaryColor"
        >
          {url}
        </a>
      );
    } else if (emailPattern.test(part)) {
      return (
        <a key={i} href={`mailto:${part}`} className="text-primaryColor">
          {part}
        </a>
      );
    } else {
      return <span key={i}>{part}</span>;
    }
  });
};
