import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { apiClient } from "../../config/axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import { useInstagramProfileStatus } from "../../hooks/useInstagramProfileStatus";
import { FiEye, FiEyeOff } from "react-icons/fi";
import InstagramEmulator from "../../common/InstagramEmulator";
import BackButton from "../../common/BackButton";

const schema = z.object({
  username: z.string().min(1, { message: "This field is required" }),
  password: z.string().min(1, { message: "Password is required" }),
});

const PrivateInstagram = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [showEmulator, setShowEmulator] = useState(false);
  const [apiEndpoint, setApiEndpoint] = useState("");
  const [isLoginVisible, setIsLoginVisible] = useState(true);
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const mutation = useMutation(
    (loginData) =>
      apiClient.post("api/instagram-private-api/v2/login", loginData),
    {
      onSuccess: (response) => {
        const {
          success,
          challengeRequired,
          twoFactorRequired,
          twoFactorIdentifier,
          verificationMethod,
          message,
        } = response?.data || {};

        if (success) {
          toast.success("Login successful");
          navigate("/instagram/profiles");
        } else if (challengeRequired || twoFactorRequired) {
          // Ensure two-factor authentication logic is only handled once
          if (twoFactorRequired) {
            localStorage.setItem("isTwoFactor", true);
            localStorage.setItem("twoFactorIdentifier", twoFactorIdentifier);
            localStorage.setItem("verificationMethod", verificationMethod);
          }

          // Set the emulator state once and ensure no duplicate calls
          if (!showEmulator) {
            setApiEndpoint(response?.data?.challengeInfo?.challenge?.url);
            setShowEmulator(true);
            setIsLoginVisible(false);
            localStorage.setItem("isChallengeRequired", true);
          }
        } else {
          toast.error(message);
        }
      },
      onError: (error) => {
        toast.error(`Error: ${error.message}`);
      },
    }
  );

  const onSubmit = (data) => {
    localStorage.setItem("username", data.username);
    localStorage.setItem("password", data.password);
    mutation.mutate(data);
  };

  const { data: isAuthenticated, isLoading } = useInstagramProfileStatus();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  const handleEmulatorClose = () => {
    setShowEmulator(false);
    setIsLoginVisible(true);
  };

  return (
    <div className="container !mt-8 lg:mt-0 ">
      <div className="mt-3">
        <BackButton />
      </div>
      <div className=" flex justify-center items-center bg-gray-100">
        <div className="w-full max-w-sm lg:max-w-md xl:max-w-lg px-6 py-12 bg-white border border-gray-200 rounded-lg shadow-lg sm:px-8 sm:py-16">
          {/* {isLoginVisible && ( */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center space-y-4"
          >
            <h1
              className="text-4xl lg:text-5xl mb-16 font-pacifico"
              style={{ fontWeight: 100 }}
            >
              Instagram
            </h1>

            <input
              type="text"
              placeholder="Phone number, username, or email"
              {...register("username")}
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            {errors.username && (
              <span className="text-red-500">{errors.username.message}</span>
            )}
            <div className="relative w-full">
              <input
                type={passwordType}
                placeholder="Password"
                {...register("password")}
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
              />

              <span
                className="absolute right-[21px] top-[21px] cursor-pointer flex items-center"
                onClick={togglePassword}
              >
                {passwordType === "password" ? <FiEyeOff /> : <FiEye />}
              </span>
            </div>
            {errors.password && (
              <span className="text-red-500">{errors.password.message}</span>
            )}

            {apiEndpoint && !showToast ? (
              <button
                className="w-full p-3 !mt-8 bg-[#3897f0] text-white font-bold rounded-md hover:bg-[#318ce7] transition"
                type="submit"
              >
                Challange required completed
              </button>
            ) : (
              <button
                className="w-full p-3 !mt-8 bg-[#3897f0] text-white font-bold rounded-md hover:bg-[#318ce7] transition"
                type="submit"
              >
                {mutation.isLoading ? "Submitting..." : "Log in"}
              </button>
            )}
          </form>
          {/* )} */}

          {/* {isLoginVisible && ( */}
          <div className="text-center mt-12">
            <span>Don't have an account? </span>
            <a href="#" className="text-[#3897f0] font-bold">
              Sign up
            </a>
          </div>
          {/* )} */}

          {/* {isLoginVisible && ( */}
          <div className="text-center mt-6">
            <span>Get the app</span>
            <div className="flex justify-center space-x-4 mt-4">
              <img
                src="https://www.instagram.com/static/images/appstore-install-badges/badge_android_english-en.png/e9cd846dc748.png"
                alt="android App"
                className="w-24 lg:w-32 cursor-pointer"
              />
              <img
                src="https://www.instagram.com/static/images/appstore-install-badges/badge_ios_english-en.png/180ae7a0bcf7.png"
                alt="ios app"
                className="w-24 lg:w-32 cursor-pointer"
              />
            </div>
          </div>
          {/* )} */}
        </div>

        {showEmulator && (
          <InstagramEmulator
            showToast={showToast}
            setShowToast={setShowToast}
            apiEndpoint={apiEndpoint}
            onClose={handleEmulatorClose}
          />
        )}
      </div>
    </div>
  );
};

export default PrivateInstagram;
