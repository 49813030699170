import React from "react";
import AdminLayout from "./AdminLayout";
import { useParams, useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { fetchUserList } from "../Admin/user";
import { useQuery } from "react-query";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import { fetchCheckoutUserList } from "./Checkout";

const CheckoutUserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery("[checkoutUserList]", () =>
    fetchCheckoutUserList()
  );

  const checkoutUser = !isLoading && data?.data?.find((user) => user.id === id);

  if (!checkoutUser) {
    return <div>Checkout details not found</div>;
  }

  return (
    <AdminLayout>
      <div className="bg-white w-full px-3 pt-3 h-[90.8vh]">
        <div className="lg:mx-8  mx-4 ">
          <div
            className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 mb-4"
            onClick={() => navigate(-1)}
          >
            <RiArrowLeftLine />
            Back
          </div>
          <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4">
            <form
              className="flex justify-between"
              //   onSubmit={handleSubmit(onSaveSubmit)}
            >
              <div className="w-[50%]">
                <h6 className="text-[#000] font-semibold">
                  Checkout Information
                </h6>
                <div>
                  <div>First Name:</div>
                  <div className="font-semibold">{checkoutUser?.firstName}</div>
                </div>
                <div className="flex justify-between flex-wrap gap-2 my-4">
                  <div>
                    <div>Last Name:</div>

                    <div className="font-semibold">
                      {checkoutUser?.lastName}
                    </div>
                  </div>
                </div>
                <div>
                  <div>Email:</div>
                  <div className="font-semibold">{checkoutUser?.email}</div>
                </div>
                <div className="mt-3">
                  <div>Total Amount:</div>
                  <div className="font-semibold">{checkoutUser?.amount}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CheckoutUserDetail;
