import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import "./Messages.css";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { RiQuestionAnswerLine, RiArrowLeftLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { apiClient } from "../../config/axios";
import SendMessageBox from "../../components/Dashboard/SendMessageBox";
import defaultImage from "../../assets/images/defaultImage.png";

const InstagramMessages = () => {
  const BACKEND_URI = process.env.REACT_APP_BACKEND_URI;
  const [socket, setSocket] = useState();
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");
  const params = useParams();
  const [userDetail, setUsers] = useState({ pagePic: "", users: [] });
  const [loading, setLoading] = useState(true);
  const messageListRef = useRef(null);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const [conversationDetail, setConversationDetail] = useState({
    conversations: [],
    selectedUser: {},
  });
  const [inputMessage, setInputMessage] = useState("");
  const [openButtomOverlayDrawer, setOpenButtomOverlayDrawer] = useState(false);

  const handleSendValue = () => {
    const { id: recipient, businessProfileId } =
      conversationDetail.selectedUser;
    setSendMessageLoading(true);
    return apiClient
      .post(`/api/instagram/send-message`, {
        message: inputMessage,
        recipient: recipient,
        businessProfileId: businessProfileId,
      })
      .then(() => {
        setSendMessageLoading(false);
        setInputMessage("");
      })
      .catch((error) => {
        setSendMessageLoading(false);
        toast.error(error?.response?.data?.message || "Error sending message");
      });
  };

  const getUserList = () => {
    return apiClient
      .get(`/api/instagram/conversations/${params.businessId}/user-list`, {
        headers: {
          token: accessToken,
        },
      })
      .then((res) => {
        const { users, businessProfilePic } = res.data;
        if (users.length > 0) {
          getMessages(users[0]);
        }
        setUsers({ users, pagePic: businessProfilePic });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast(error);
      });
  };

  const getMessages = (user) => {
    return apiClient
      .get(
        `/api/instagram/business/${user.businessProfileId}/conversations/${user.conversationId}`,
        {
          headers: {
            token: accessToken,
          },
        }
      )
      .then((res) => {
        setConversationDetail({
          conversations: res.data.conversations,
          selectedUser: user,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log("something went wrong", error);
        setLoading(false);
        toast("something went wrong");
      });
  };

  const messageListner = (message) => {
    // setMessages([...messages, message])
  };

  useEffect(() => {
    const newScoket = io(BACKEND_URI);

    getUserList();
    setSocket(newScoket);

    // return () => {
    //   socket.disconnect();
    // };
  }, [setSocket, BACKEND_URI]);

  useEffect(() => {
    socket?.on("message", (data) => {
      if (data.pageId === params.businessId) {
        const senderDetail = data.senderDetail;
        const selectedUser = conversationDetail.selectedUser;

        if (
          selectedUser.id === undefined ||
          senderDetail.sender.id === selectedUser.id ||
          senderDetail.recipient.id === selectedUser.id
        ) {
          const conversationData = {
            conversations: data.conversations,
            selectedUser:
              Object.keys(selectedUser).length === 0
                ? data.sender.users[0]
                : selectedUser,
          };

          setConversationDetail(conversationData);
        }
        const usersData = {
          users: data.sender.users,
          pagePic: data.sender.businessProfilePic,
        };
        setUsers(usersData);
      }
    });

    return () => {
      socket?.off("message");
    };
  }, [messageListner]);

  const recipient = params.businessId;

  useEffect(() => {
    // Scroll to the bottom of the message list when conversationDetail changes
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [conversationDetail]);

  return (
    <OnBoardingLayout selected={1}>
      <svg width="0" height="0">
        <linearGradient
          id="blue-gradient"
          x1="120%"
          y1="120%"
          x2="-20%"
          y2="-20%"
        >
          <stop stopColor="#9747FF" offset="0%" />
          <stop stopColor="#F8567A" offset="100%" />
        </linearGradient>
      </svg>
      <div className="mid-content flex flex-row w-full bg-[#eaeeff]">
        <div className="flex flex-col min:w-[275px] w-[275px] overflow-y-auto">
          <div className="flex items-center  mt-8  mb-3">
            <button
              className="back-btn flex items-center flex-row py-1 px-2 hover:text-[#000] "
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine className="text-[20px]" />
            </button>

            <div className="text-[17px] ml-3">Messages</div>
          </div>

          {loading ? (
            <div className="side-h flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div>
              {userDetail.users.length === 0 ? (
                <div
                  className={`side-h flex flex-row justify-between items-center text-center rounded-lg p-2 m-3 mt-1`}
                >
                  <div
                    className={`flex flex-row justify-center rounded-lg p-2 m-3 mt-1 bg-white text-rose text-sm w-full`}
                  >
                    No messages found
                  </div>
                </div>
              ) : (
                <div className="mx-2">
                  {userDetail.users.map((user, index) => (
                    <div
                      className={`flex flex-row gap-2 justify-between items-center rounded-lg px-2 py-3 cursor-pointer mb-2 mr-2 ${
                        conversationDetail.selectedUser === user
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => getMessages(user)}
                      key={index}
                    >
                      <div className="">
                        <img
                          className="rounded-full w-8 h-8"
                          src={user.profile_pic || defaultImage}
                          alt="profile"
                        />
                      </div>
                      <div className="flex flex-col flex-grow">
                        <div className="font-bold">
                          {user.first_name} {user.last_name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col flex-grow bg-white ">
          {loading ? (
            <div className="screen-h flex flex-col items-center justify-center text-xl">
              <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
              <div className="text-black font-medium text-base uppercase">
                No chats selected
              </div>
            </div>
          ) : (
            <div>
              {userDetail.users.length === 0 ? (
                <div className="screen-h flex flex-col items-center justify-center text-xl">
                  <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
                  <div className="text-black font-medium text-base uppercase">
                    No chats selected
                  </div>
                </div>
              ) : (
                <div>
                  {conversationDetail.selectedUser.id && (
                    <div className="flex flex-col flex-grow bg-white ">
                      {/* Selected User details */}
                      <div className="flex flex-row justify-between items-center p-3 border-b">
                        <div className="flex flex-row gap-2 justify-between items-start rounded-lg py-2 cursor-pointer">
                          <div className="">
                            <img
                              className="rounded-full w-9 h-9"
                              src={
                                conversationDetail.selectedUser.profile_pic ||
                                defaultImage
                              }
                              alt="convo"
                            />
                          </div>
                          <div className="flex flex-col flex-grow">
                            <div className="font-bold">
                              {conversationDetail.selectedUser.first_name}{" "}
                              {conversationDetail.selectedUser.last_name}
                            </div>
                            <div className="text-grey text-sm">
                              last seen today at 12:02 PM
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Message List */}
                      <div
                        className="message-content overflow-y-auto"
                        ref={messageListRef}
                      >
                        <div className=" flex flex-col flex-grow p-3 justify-end">
                          <div className="text-center mb-2">
                            Today, October 11
                          </div>

                          {conversationDetail.conversations.map((message) => (
                            <div>
                              {message.recipientId === recipient ? (
                                <div className="mb-3">
                                  <div className="flex flex-row-reverse gap-2  justify-start items-center">
                                    {/* Receipient Message */}
                                    <img
                                      src={userDetail.pagePic || defaultImage}
                                      className="rounded-full w-9 h-9"
                                      alt="userdetail"
                                    />
                                    <div className="flex flex-col items-end">
                                      <div className="flex flex-row-reverse items-baseline gap-3 font-bold ">
                                        {/* <div>{message.from}</div> */}
                                      </div>

                                      {message.messageImageUrl ? (
                                        <div>
                                          <img
                                            style={{ height: 150 }}
                                            src={
                                              message.messageImageUrl ||
                                              defaultImage
                                            }
                                            alt="message
                                          "
                                          />
                                        </div>
                                      ) : (
                                        <p
                                          className="bg-[#C7DFFF] text-gray px-3 py-2 rounded-md my-1 w-fit lg:max-w-[420px] max-w-xs whitespace-pre-wrap"
                                          dangerouslySetInnerHTML={{
                                            __html: message.message,
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex flex-row-reverse items-baseline gap-3 font-bold ">
                                    <span className="font-normal text-sm mr-12">
                                      {format(
                                        new Date(message.messageCreatedAt),
                                        "MM/dd hh:mm"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className="mb-3">
                                  <div className="flex flex-row gap-2  items-center">
                                    {/* Sender Message */}
                                    <img
                                      src={
                                        conversationDetail.selectedUser
                                          .profile_pic || defaultImage
                                      }
                                      alt="conversation"
                                      className="rounded-full w-9 h-9"
                                    />
                                    <div className="flex flex-col items-center">
                                      <div className="flex flex-row items-baseline gap-3 font-bold">
                                        {/* <div>{message.from}</div> */}
                                      </div>
                                      {message.messageImageUrl ? (
                                        <div>
                                          <img
                                            style={{ height: 150 }}
                                            src={
                                              message.messageImageUrl ||
                                              defaultImage
                                            }
                                            alt="image
                                          "
                                          />
                                        </div>
                                      ) : (
                                        <div className="bg-[#C7DFFF] text-gray px-3 py-2 rounded-md my-1 w-fit lg:max-w-[420px] max-w-xs ">
                                          {message.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <span className="font-normal text-sm ml-12">
                                    {/* {format(message.updatedAt, "yyyy/MM/dd kk:mm:ss")} */}
                                    {format(
                                      new Date(message.messageCreatedAt),
                                      "MM/dd hh:mm"
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <SendMessageBox
                    loading={sendMessageLoading}
                    inputMessage={inputMessage}
                    setInputMessage={setInputMessage}
                    handleSendValue={handleSendValue}
                    openButtomOverlayDrawer={openButtomOverlayDrawer}
                    setOpenButtomOverlayDrawer={setOpenButtomOverlayDrawer}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default InstagramMessages;
