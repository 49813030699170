import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PlainHeader from "../../common/PlainHeader";
import { MULTIPLY_PAYMENT } from "../../constant/MultiplyPayment";
import ButtonField from "../../common/ButtonField";
import { zodResolver } from "@hookform/resolvers/zod";
import { PaymentSummarySchema } from "../../validation/paymentSummaryValidation";
import BackButton from "../../common/BackButton";

const PaymentSummary = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(PaymentSummarySchema),
  });

  const location = useLocation();
  const [paymentSummaryPayload, setPaymentSummaryPayload] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split("?")[1];

    if (queryString) {
      const params = new URLSearchParams(queryString);
      const paramNames = Array.from(params.keys());
      setPaymentSummaryPayload(paramNames[0]);
    }
  }, [location]);

  const recantAmount = paymentSummaryPayload === MULTIPLY_PAYMENT ? 50 * 3 : 50;

  const onSubmit = (formData) => {
    localStorage.setItem("firstName", formData.firstName);
    localStorage.setItem("lastName", formData.lastName);
    localStorage.setItem("email", formData.email);
    localStorage.setItem("amount", recantAmount);
    navigate("/payment-checkout");
  };

  return (
    <>
      <PlainHeader />
      <form
        className="text-[#5F6388] pt-32 container "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="font-DMSans  mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5  items-stretch">
            <div className="w-full md:w-auto ">
              <div className="w-full md:w-auto h-full flex flex-col items-stretch">
                <BackButton />
                <div className="flex-grow mt-7">
                  <div className="text-2xl font-bold mb-10 text-[#5F6388]">
                    User Information
                  </div>
                  <div>
                    <div className="mb-3">
                      <label className="mb-[5px]">First Name</label>
                      <input
                        type="name"
                        name="firstName"
                        {...register("firstName")}
                        placeholder="Enter First Name"
                        className="h-[38px] w-full rounded-[4px] px-[12px] bg-[#f3f3f0] text-[14px]"
                      />
                      {errors?.firstName?.message && (
                        <p style={{ color: "#CC2828", marginTop: "5px" }}>
                          {errors?.firstName?.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="mb-[5px]">Last Name</label>
                      <input
                        type="name"
                        name="lastName"
                        {...register("lastName")}
                        placeholder="Enter Last Name"
                        className="h-[38px] w-full rounded-[4px]  px-[12px] bg-[#f3f3f0] text-[14px]"
                      />
                      {errors?.lastName?.message && (
                        <p style={{ color: "#CC2828", marginTop: "5px" }}>
                          {errors?.lastName?.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="mb-[5px]">Email</label>
                      <input
                        type="email"
                        name="email"
                        {...register("email")}
                        placeholder="Enter Email"
                        className="h-[38px] w-full rounded-[4px]  px-[12px] bg-[#f3f3f0] text-[14px]"
                      />
                      {errors?.email?.message && (
                        <p style={{ color: "#CC2828", marginTop: "5px" }}>
                          {errors?.email?.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative z-10 w-full md:w-auto">
              <div className="rounded-3xl shadow border border-purple-500 backdrop-blur-2xl pt-[42px] px-10 pb-[20px]">
                <div>
                  <div className="text-2xl font-bold mb-10 text-[#5F6388]">
                    Payment Summary
                  </div>
                  <div className="text-xl flex justify-between text-[#5F6388] font-medium mb-[15px]">
                    <span>Course Fee</span>
                    <span>${recantAmount}</span>
                  </div>
                  <div className="border-grey border-b border-dashed my-8" />
                  <div className="flex justify-between text-black text-xl font-bold text-[#1E1F25]">
                    <span>Grand Total</span>
                    <span>${recantAmount}</span>
                  </div>
                  <div className="border-grey border-b border-dashed my-7" />
                </div>
                <div className="w-full  md:mx-auto">
                  <div className="flex flex-col text-grey mb-2">
                    <span className="text-[#5F6388] font-bold text-base mb-2">
                      Payment Method
                    </span>
                    <select className="p-2 rounded shadow">
                      <option>Credit Card</option>
                    </select>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-[1px] h-full mt-6">
                    <div className="flex gap-2">
                      <input
                        className="mt-[5px] h-4 w-4 cursor-pointer"
                        type="checkbox"
                        {...register("terms_and_conditions")}
                      />
                      <label className="">
                        I have read and I accept all the{" "}
                        <a
                          href="/terms"
                          className={`${
                            errors?.terms_and_conditions?.message
                              ? "!text-[#CC2828]"
                              : "text-primaryColor"
                          } mx-1 text-primaryColor`}
                        >
                          Terms & Conditions
                        </a>
                        and{" "}
                        <a
                          href="/privacy-policy"
                          className={`${
                            errors?.terms_and_conditions?.message
                              ? "!text-[#CC2828]"
                              : "text-primaryColor"
                          } mx-1 text-primaryColor`}
                        >
                          Privacy Policy
                        </a>{" "}
                        .
                      </label>
                    </div>
                    <div className="flex">
                      {errors?.terms_and_conditions?.message && (
                        <p className="pt-2 mb-0" style={{ color: "#CC2828" }}>
                          {errors?.terms_and_conditions?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <ButtonField label={"CONTINUE"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentSummary;
