import { useState, useRef } from "react";
import SocialMediaConnection from "../../common/SocialMediaConnection";
import Stepper from "../../common/Stepper";
import SensitivityParameter from "../SensitivityParameter";
import { useNavigate } from "react-router-dom";
import { useFacebookProfileStatus } from "../../hooks/useFacebookProfileStatus";
import { useTwitterProfileStatus } from "../../hooks/useTwitterProfileStatus";
import { useInstagramProfileStatus } from "../../hooks/useInstagramProfileStatus";

export default function App() {
  const list = [<Example2 />, <Example1 />];

  return (
    <div className=" bg-lightGrey  h-screen pt-1 ">
      <div className="">
        <div className="font-sans text-center p-4 sm:p-12 ">
          <Stepper list={list} />
        </div>
      </div>
    </div>
  );
}

const Example1 = ({ onPrev, onNext }) => {
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const { data: connectTwitterStatus } = useTwitterProfileStatus();
  const { data: connectFBStatus } = useFacebookProfileStatus();
  const { data: connectInstagramStatus } = useInstagramProfileStatus();

  const isSocialMediaConnected =
    connectTwitterStatus || connectFBStatus || connectInstagramStatus;

  const handleSocialMediaSubmit = () => {
    if (!isSocialMediaConnected) {
      setHasError(true);
      return;
    }
    navigate("/dashboard");
    setHasError(false);
  };
  return (
    <div className="flex flex-col items-center">
      <div
        className={`min-h-[780px] lg:min-h-[530px] rounded-[10px]  w-full lg:w-1/2 mx-auto flex flex-col shadow-lg bg-white ${
          hasError
            ? "!border-red border-[1px]"
            : "border-[#c1c1c1] border-[1px]"
        }`}
      >
        <h1 className="text-xl sm:text-2xl py-4">
        Connect the social media accounts that you want protected, or select to initiate your own replies below
        </h1>
        <SocialMediaConnection />
      </div>
      {hasError && (
        <p className="text-red mt-4 text-sm">
          *Please connect to atleast one social media account.
        </p>
      )}
      <div className="mt-4 flex !flex-col sm:!flex-row items-center justify-center gap-3 w-full2  ">
        <button
          className=" shadow-lg bg-gray-500 text-primaryColor px-6 py-2 rounded w-full sm:w-auto lg:w-[150px] sm:mr-2 border-2 border-primaryColor hover:bg-primaryColor hover:text-white transition-all duration-300"
          onClick={onPrev}
        >
          Prev
        </button>
        <button
          className=" shadow-lg bg-primaryColor text-white px-6 py-2 rounded w-full sm:w-auto lg:w-[150px] border-2 border-primaryColor hover:bg-blue-700 transition-all duration-300"
          onClick={handleSocialMediaSubmit}
        >
          Finish
        </button>
      </div>
    </div>
  );
};

const Example2 = ({ onPrev, onNext }) => {
  const [hasError, setHasError] = useState(false);
  const sensitivityParamRef = useRef(null);

  const handleNext = () => {
    if (sensitivityParamRef.current) {
      sensitivityParamRef.current.submit();
    }
    onNext();
  };

  return (
    <div className="flex flex-col ">
      <div
        className={` min-h-[530px] rounded-[10px]  w-full lg:w-1/2 mx-auto flex flex-col bg-white shadow-lg ${
          hasError ? "border-red border-[1px]" : "border-[#c1c1c1] border-[1px]"
        }`}
      >
        <div className="p-4">
          <SensitivityParameter ref={sensitivityParamRef} isEdit={true} />
        </div>
      </div>
      {hasError && (
        <p className="text-red mt-4 text-sm">
          *Please select the sensitivity level greater than 1 for all
        </p>
      )}
      <div className="mt-4 flex !flex-col sm:!flex-row items-center justify-center gap-3 ">
        <button
          className="bg-gray-500 text-primaryColor px-6 py-2 rounded w-full sm:w-auto lg:w-[150px] sm:mr-2 border-2 border-primaryColor hover:bg-gray-600 shadow-lg  transition-all duration-300 opacity-30"
          disabled
        >
          Prev
        </button>
        <button
          className="shadow-lg bg-primaryColor text-white px-6 py-2 rounded w-full sm:w-auto lg:w-[150px] border-2 border-primaryColor hover:bg-blue-700 transition-all duration-300"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};
