import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/RecantNewLogo.png";
import useLogout from "../../hooks/useLogoutUsers";
import { MdLogout } from "react-icons/md";
import { useUser } from "../DataContext";

export default function ResponsiveDrawer({
  open,
  handleDrawerToggle,
  sideBarItems,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const logoutUser = useLogout();
  const { user } = useUser();

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      classes={{
        paper: "bg-gray-900",
      }}
    >
      <div className="flex justify-between items-center p-4">
        <a className="nav-logo  cursor-pointer " onClick={() => navigate("/")}>
          <img src={logo} width={100} />
        </a>
        <IconButton color="inherit" onClick={handleDrawerToggle}>
          <CloseIcon className="text-black" />
        </IconButton>
      </div>
      <Divider className="bg-gray-600" />
      <List>
        {sideBarItems?.map((item, index) => (
          <ListItem
            key={index}
            button
            to={item.link !== "/logout" ? item.link : "#"}
            component={Link}
            onClick={item.link === "/logout" ? logoutUser : undefined}
            className={`flex items-center !p-[2px] !rounded-lg transition-colors duration-200 !text-baseColor ${
              location.pathname === item.link
                ? "!bg-[#2c505f] text-white"
                : "hover:bg-gray-100"
            }`}
          >
            {item.icon && (
              <ListItemAvatar className="!mr-[-6px] !text-sm">
                <Avatar sx={{ color: "inherit", bgcolor: "transparent" }}>
                  <item.icon />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={item.title}
              className={`${!item.icon && "!ml-[18px]"}`}
            />
          </ListItem>
        ))}
      </List>
      {user && (
        <div className="absolute bottom-6 w-full border-t-[1px] border-[#dcd8d8] mt-4 lg:mt-0 ">
          <button
            onClick={() => logoutUser()}
            className="flex items-center gap-3 font-bold underline !text-primaryColor hover:bg-gray-100 p-3 rounded-lg w-full lg:w-[220px]"
          >
            <MdLogout className="w-5 h-5" />
            <span className=" lg:inline">Logout</span>
          </button>
        </div>
      )}
    </Drawer>
  );
}
