import React, { useState, useEffect } from "react";
import PlainHeader from "../../common/PlainHeader";
import ModalField from "../../common/ModalField";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";

const UserInitiatedMessageFlagged = () => {
  const today = new Date();
  today.setDate(today.getDate() + 3); // Add 3 days to the current date
  const day = today.getDate();
  const monthName = today.toLocaleString("default", { month: "long" });
  const [showRecantModal, setShowRecantModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState("");

  const { data: userDetails } = useQuery(
    "[userDetails]",
    async () => {
      const { data } = await apiClient.get("api/user");
      return data;
    },
    {
      onError: (err) => {
        toast.error(
          err?.response?.data?.message ||
            "An error occurred while fetching user details."
        );
      },
    }
  );

  const recepientName = userDetails?.firstName;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname.includes("message-flagged")) {
      const aid = queryParams.get("aid");
      if (aid) {
        setId(aid);
      }
    }
  }, [location]);

  return (
    <>
      <PlainHeader />
      <div className="container pt-[105px] ">
        <div className="max-w-[1440px] mx-auto">
          <div className="Rectangle23 rounded-3xl lg:shadow border border-purple-500 lg:backdrop-blur-2xl pt-[58px] !px-10 pb-[20px] container !z-1 text-[15px]">
            <div className="w-full lg:w-[800px] md:w-[500px] text-[#000] mx-auto">
              STOP - this is an automated response. The account you have
              messaged has RECANT.AI online harm reduction tools installed.
              <br />
              <br />
              The message you have sent has been flagged by Recant.AI safety
              filters set by {recepientName} and has not yet been received by{" "}
              {recepientName} It is currently quarantined with Recant.AI where
              it will remain hidden from {recepientName} for the next 72 hours.
              <br />
              <br />
              At {day}
              {"  "}
              {monthName} your message will be released into the Recant.AI
              quarantined Inbox of {recepientName} and viewable by them unless
              you request and complete a record deletion process from Recant.AI
              prior to {day}
              {"  "}
              {monthName}.
              <br />
              <br />
              If you know {recepientName} personally, and you are sure they have
              no problem receiving this content from you, or you believe our
              safety filter has flagged your message in error, no action is
              required by you. Your message will in 72 hours be viewable by{" "}
              {recepientName} as you intended - should they ever choose to view
              their Recant.AI quarantined Inbox from
              {day}
              {"  "}
              {monthName}. Occasionally the Recant.AI safety models pick up
              innocuous or contextually appropriate messages out of caution for
              our users - apologies for the interruption.
              <br />
              <br />
              NOTE: In the circumstance where you elect NOT to request and
              complete a deletion process for this message Recant.AI service
              will retain detailed records of this message (and others between
              you and the intended recipient). Recant.AI will NOT otherwise
              share, release, or publish your message, or knowledge of your
              message, UNLESS;
              <br />
              <br />
              <ul>
                <li className="list-disc">
                  in response to a direct and lawful request from law
                  enforcement agencies or,{" "}
                </li>
                <li className="list-disc">
                  to assist {recepientName} in exercising any of their rights
                  including the reporting of your message to law enforcement or
                  regulatory agencies, third party reporting agencies, or
                  republication in any public forum they may have rights to
                  publish.
                </li>
              </ul>
              <br />
              This notice from Recant.AI does not surrender any of the rights of{" "}
              {recepientName} in relation to your communications.{" "}
              {recepientName}
              may after {day}
              {"  "}
              {monthName} retrieve and read your message from Recant.AI
              quarantine inbox, and upon their viewing;
              <br />
              <br />
              <ul>
                <li className="list-disc">
                  your sending and their viewing may then meet the threshold of
                  criminal offense and/or,
                </li>
                <li className="list-disc">claims of civil damage and/or, </li>
                <li className="list-disc">
                  may, depending on the existing nature of your relationship,
                  confer the right to republish your message, for example, using
                  public reporting tools and in public forums, or
                </li>
                <li className="list-disc">
                  may be completely meaningless if the message identified by our
                  models has been erroneously flagged.
                </li>
              </ul>
              <br />
              You may, in the next 72 hours up until {day}
              {"  "}
              {monthName}, request a record and file deletion of this instance
              of your message from Recant.AI safety record keeping service which
              you are able to do after purchasing 'The Risks and Harms of Online
              Abuse' educational resource and donating to our charitable fund,
              which supports organisations that assist remediating damage caused
              by harms of online abuse.
              <br />
              <br />
              The harms & risks of online abuse are real and can be profound. At
              Recant.AI we hope to educate web users about this critical issue,
              and acknowledge these things can be complex, and people can make
              mistakes. We seek to find compassionate and discreet ways to
              de-escalate from abusive dynamics and offer tools that allow web
              users to take better control of their own online safety, and
              Recant the occasional unfortunate message.
              <br />
              <br />
              If you do elect to purchase this digital educational resource and
              donate to our charitable fund, Recant.AI will destroy your message
              prior to release into the Recant.AI quarantined inbox of{" "}
              {recepientName} (along with any other messages filtered and
              pending release into the Recant.AI quarantine Inbox of{" "}
              {recepientName} ). This will prevent the recipient from ever being
              able to access it from their Recant.AI quarantined inbox or any
              other Recant.AI related service. NOTE: If your message has been
              assessed to contain explicit evidence of criminal activity, we may
              be lawfully obligated to retain records or make reports to law
              enforcement or regulatory agencies.
              <br />
              <br />
            </div>

            <div className="flex flex-col lg:!flex-row gap-3 my-4 justify-center w-full font-poppins">
              <div className="border-primaryColor border-[4px] rounded shadow-lg w-full lg:w-1/3">
                <div className="flex items-center justify-center h-full">
                  <button
                    className="text-sm uppercase text-white bg-green-500 w-full hover:bg-green-700 transition-all duration-300 ease-in-out py-3 h-[88px] lg:h-[100px]"
                    onClick={() => setShowRecantModal(true)}
                    style={{ backgroundColor: "#008000" }}
                  >
                    Recant my message and reduce my risks
                  </button>
                </div>
              </div>
            </div>
            <ModalField
              confirmButtonTitle={"Confirm"}
              modalHeader={"Confirm Recant"}
              modalDescription={
                "Your message (and any other pending messages picked up by our filter) will be prevented from reaching your intended recipient, eliminating some risks of legal escalation when you complete the purchase of our online course and make a donation to our charitable partner fund."
              }
              handleCloseModal={() => setShowRecantModal(false)}
              showModal={showRecantModal}
              onClickConfirmButton={() => {
                navigate("/payment-summary");
                localStorage.setItem("aid", JSON.stringify(id));
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInitiatedMessageFlagged;
