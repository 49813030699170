export const TERMS_AND_CONDITION = [
  {
    title: "Definitions",
    content: [
      "**“Affiliate”** means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity, and “control” means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.",
      "**“Application”** means the social media management, monitoring, analytics and communications system made available online as a Software-as-a-Service basis by the Company, or any of its Affiliates and its underlying tools, databases, APIs, and software that make up the system, including any software or technology created by the Company’s Affiliates.",
      "**“Company Data”** means proprietary information gathered or created by Company and provided to or accessed by the User whether as part of the Application or otherwise.",
      "**“Confidential Information”** shall have the meeting set forth in section 4.",
      "**“Designated Third Party”** means any person or entity that is authorized by a User to have access to the User Account and use this Application on the User’s behalf, this includes but is not limited to social media managers, social media management agencies and agents.",
      "**“Intellectual Property Rights”** means current and future worldwide rights under patent, copyright, trademark and trade secret laws, and other similar rights.",
      "**“Malicious Code”** means without limitation any code, files, scripts, agents, worms, viruses or programs intended to do harm.",
      "**“Reporting Service”** means any other platform or service that possesses any functionality allowing Users to report content by posting or uploading it.",
      "**“Terms”** means these Recant.ai Terms and Conditions of Service.",
      "**“Third Party Services”** means any services not provided by the Company, but those the User may elect, in its sole discretion, to access or use in connection with the Application, this includes but is not limited to social media networks such as: X, Facebook, WhatsApp, Instagram and LinkedIn.",
      "**“User”** means the named individual who creates and has the license to use and access a User Account.",
      "**“User Account”** means the specific and individual account licensed to the **User**.",
      "**“User Data”** means data and information in any format, including but not limited to text (including text provided by a third-party to or for the User), files, images, and/or URLs, that is submitted by or for the User or any Designated Third Party to the Application, or collected and processed by or for the User or any Designated Third Party using the Application. This includes any data and information arising from any use of or access to a Third Party Service.",
    ],
  },
  {
    title: "Access and Use",
    content: [
      "Subject to compliance with these Terms, the Company and its Affiliates grants the User a limited, non-exclusive, revocable, non-transferable (other than as set out in these Terms), licence to the User (and any Designated Third Party) for the access and use of the Application via the User Account for the User’s own purposes in accordance with these Terms and User’s Subscription.",
      "The User may grant any Designated Third Party access to their User Account on the Application (e.g., social media managers).",
      "The User is responsible (a) for all activities that occur under their account; (b) for any Designated Third Party’s compliance with these Terms; (c) for the accuracy, quality and legality of User Data and the means by which the User acquired User Data; (d) in its capacity as a data controller, for ensuring that processing of personal data by or using the Application complies with any applicable laws and government regulations; (e) for ensuring that the use of any personal data extracted by User from the Application complies with applicable laws and government regulations and; (f) for use of the Application being in accordance with any valid documents, agreements and all applicable laws and government regulations.",
      "The User (and any Designated Third Party) shall not (a) use any functionality of the Application to do anything that would otherwise violate any applicable civil or criminal law, or government regulation; (b) use the Application to store or transmit Malicious Code; (c) interfere with or disrupt the integrity or performance of the Application or any Third-Party Services; (d) circumvent any features included by Company in the use of the Application, abuse any limitations of the Application, or attempt to gain unauthorized access to the Application or its related software, systems or networks; (e) permit direct or indirect access to or use of any Application in a way that circumvents any contractual usage; (f) frame or mirror any part of the Application; (g) use any functionality of the Application to process, publish or otherwise use Company Data, or User Data in a way that violates any applicable terms, conditions or policies contained in these Terms; (h) use any functionality of the Application to process, publish or otherwise use, User Data owned by or originating from a Third Party Service in any way that contravenes any applicable terms, conditions or polices of that Third Party Service;; (i) place Company Data or User Data owned by or originating from a Third Party Service on, or otherwise make these types of data available to, a search engine or directory without receiving prior written consent from the applicable owner; and (j) modify, create derivative works of, translate, reverse engineer, decompile, or disassemble the Application or otherwise recreate or gain access to the source code (to the extent such restriction is permitted by law).",
      "Where the User enables the Application to access any Third-Party Service, the User acknowledges and accepts: (a) use of any such Third-Party Service and data arising from this use shall be governed solely by the terms and policies of that Third-Party Service, and that they and any Designated Third Party shall comply with all said terms and policies; (b) the Company makes no representations and shall have no liability or obligation to the User in relation to any aspect of a Third Party Service, nor shall the Company or its Affiliates be liable for any loss, disclosure, use of, or change to User Data by any Third Party Service, or any loss or damage a User may suffer in connection with a User’s access, use or enjoyment of any Third Party Service; and (c) that the Company retains the absolute discretion to cease providing access to any features or functionality of the Application without any liability, where a provider of a Third Party Service ceases to make the Third Party Service available for interoperation with any such features or functionality.",
      "Where the User (or any Designated Third Party) utilizes the Application in conjunction with a Related Service they: (a) must adhere to all Terms located in this document and the terms and conditions of the Related Service; and (b) are responsible for their compliance with any applicable terms or conditions of a Third Party Service where any User Data owned by or originating from an aforementioned service has been processed, published or otherwise used.",
      "The User agrees that the Company reserves the right to alter the functionality of the Application from time to time in its sole discretion. The Company will not be liable to you or any third party for any price increases where applicable. Where new features or functionality are introduced, this may require acceptance of additional terms presented within the Application.",
      "User support services will be provided as described in [url](https://recant.ai/support)",
      "The Company will use commercially reasonable efforts to keep the Application available and accessible, however reserves the right to make the Application unavailable for repairs, upgrades, routine and emergency maintenance from time to time. The Company assumes no liability where the Application is unavailable due to these interruptions, or where it is unavailable due to any other interruptions that may be out of our reasonable control (such as outages of Third-Party Services). Any interruption to your use and enjoyment of the Application shall not be relied upon as the basis for termination of a subscription or any demand for refund or credit.",
    ],
  },
  {
    title: "Intellectual Property Rights",
    content: [
      "Where the User already possesses ownership over any Use Data provided to the Company, nothing in these Terms will affect the User’s retention of this ownership. The User grants the Company a nonexclusive, irrevocable, worldwide, limited, royalty-free right to use, replicate, prepare derivate works of, distribute, publish, remove, retain, add, process, or analyze User Data for the sole purpose of providing access and use of the Application to you. In granting this right to use, the User warrants where User Data is owned by a third party, they have obtained any consent or permissions necessary to provide the Company access and use of the relevant User Data.",
      "The Company and its Affiliates owns and retains all rights, title, and interest in and to the Company Data and Application along with all patents, inventions, copyright, trademarks, domain names, trade secrets, know-how and any other related intellectual property and proprietary rights.",
      "Nothing under these Terms grants the User any additional rights in the Application, or any ownership of any Intellectual Property Rights associated with the Application.",
      "The User authorizes the Company’s and its Affiliates’ use of de-identified or aggregated data: (a) to collect usage and performance information related to the Application; (b) to operate, improve and support the Application; (c) to develop and publish benchmarks and other similar reports; or (d) for any other lawful purpose. The Company and its Affiliates will not disclose any such data externally unless said data is de-identified and is incapable of identifying you, any Designated Third Party, or any other person. The Company and its Affiliates retains ownership of all Intellectual Property Rights in all de-identified or aggregated data and any derivative data.",
    ],
  },
  {
    title: "Confidentiality, Privacy, Security and Data Protection",
    content: [
      "For the purposes of these Terms, **“Confidential Information”** means all non-public information disclosed by either party to the other party, either, directly or indirectly, in writing orally, or to which the other party may have access, which: (a) a reasonable person would consider confidential, given the nature of the information and the circumstances of the disclosures; or (b) is marked “confidential” or “proprietary” or some similar designation by the disclosing party.",
      "However, **“Confidential Information”** will not include any information that: (a) was publicly known and made generally available in the public domain prior to the time of disclosure by the Disclosing Party; (b) becomes publicly known and made generally available after disclosure by the disclosing party to the receiving party other than as a result of a violation of these Terms by the receiving party; (c) is already in the possession of the Receiving Party at the time of disclosure by the Disclosing Party; (d) is obtained by the Receiving Party from a third party without a breach of confidentiality obligations; or (e) is independently developed by the Receiving Party without use or of reference to the Disclosing Party’s Confidential Information.",
      "The receiving party shall not disclose, transmit, inform, or make available to any entity or person, any Confidential Information for any purpose outside the scope of these Terms. The receiving party shall in exercising a reasonable level of care, make all reasonable efforts and undertake all actions reasonably necessary and appropriate to prevent the unauthorized disclosure of the Confidential Information. Each party agrees to restrict access to the Confidential Information of the other party to those employees, advisors, agents, and other representatives who require access to perform any relevant obligations under these Terms.",
      "The receiving party shall not be in violation of its confidentiality obligations where it is required to by applicable law or regulation or in response to a valid order by a court or other governmental body, to disclose Confidential Information and it does so, as long as the receiving party provides the disclosing party (to the extent legally permissible) with prior written notice of the disclosure to permit the disclosing party to seek confidential treatment of that information and limits any such disclosure to what is required by said applicable law or legal order.",
    ],
  },
  {
    title: "Representations & Warranties",
    content: [
      "The Application is offered ‘as is’. To the maximum extent permitted by applicable law, The Company expressly disclaims any and all warranties, whether express, implied, statutory or otherwise, including, but not limited to, express or implied warranties of merchantability, design, title, quality, fitness for a particular purpose, non-infringement on behalf of the Company and its Affiliates and its and their licensors and any implied warranties arising from statute, course of dealing, course of performance or usage of trade. The Company does not warrant that the Application will meet customers’ requirements or be uninterrupted, timely, secure, accessible, free from viruses, error-free, and no information or advice obtained by the User from the Company or through the Application shall create any warranty not expressly stated in these Terms. The Company disclaims all liability for nay malfunctioning, impossibility of access, or poor use conditions of the Application due to inappropriate equipment, interruptions, or other issues related to the internet and electronic communications service providers, or any other delay, error, omissions, interruption, deletion, theft, destruction, unauthorized access to, or loss of data on the Application, all of which are not within the Company’s reasonable control. Under no circumstances will the Company be liable for any third-party services, including the failure of any such third-party services.",
    ],
  },
  {
    title: "Indemnification",
    content: [
      "Your failure to comply with any of your obligations outlined in these Terms shall be considered a breach of these Terms.",
      "You will defend, indemnify and hold harmless the Company, its Affiliates, and each of its and its Affiliates’ respective officers, directors, employees, agents, successors, and assigns from any and all third party claims, losses, demands, liabilities, damages, settlements, expenses and costs (including attorney’s fees and costs reasonably incurred), arising from, based on and in connection with: (a) you or any Designated Third Party’s use of the Company Data and/or Application in breach of these Terms; (b) you or any Designated Third Party’s use of the Application in connection with a Related Service; or (c) your or any Designated Third Party’s use of Third Party Services.",
    ],
  },
  {
    title: "Limitation of Liability",
    content: [
      "The User acknowledges and agrees that in no event will the Company or any of its employees, directors, agents, Affiliates, representatives or successors have any liability for any indirect, consequential, punitive, exemplary, special damages, or for loss of goodwill or data or other intangible to the extent prohibited by Law. Under no circumstances will the Company or its Affiliates be liable for any Third-Party Services, activities of third parties in connection to or transmission from the Internet, or for any damage, loss or injury from hacking tampering or theft or other unauthorized access to or use of our Application or your User Account or the information contained in your User Account. The Company will not be liable in any circumstances for the nature of your Content and/or where your usage of such Content is defamatory or otherwise inappropriate.",
      "Our maximum aggregate liability to you for all losses or damages arising out of these Terms or your use of the Application, irrespective of the forum and of whether any action is based in contract, tort, strict liability or any other legal or equitable ground, will not exceed the total amount paid by you to the Company under the applicable subscription plan during the twelve (12) months preceding the event giving rise to the claim where applicable. Nothing in this provision limits your payment obligations contained within these Terms.",
      "Where a jurisdiction does not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, and this results in several of the above limitations not being applicable, each party’s liability in these jurisdictions will be limited to the greatest extent permitted by law.",
    ],
  },
  {
    title: "Subscription",
    content: [
      "Subscriptions for the Application may be offered on a free or fee-based basis. The relevant fees (if any) and specific entitlements a subscription entails are outlined in [url](https://recant.ai/pricing).",
      "Where a subscription is offered on a free basis the Company reserves the right to introduce an ongoing subscription fee from time to time.",
    ],
  },
  {
    title: "Fees and Payment Terms",
    content: [
      "Where a Subscription requires payment of upfront and/or ongoing fees, this is governed by the terms and policies outlined in [url](https://recant.ai/pricing).",
      "Where a Subscription requires any payment, Users will be responsible for paying all applicable taxes on all fees payable to the Company. Applicable local taxes will vary based on the payment method used.",
      "Payment shall be made with a valid credit or debit card and processed using Stripe. Where a User’s chosen payment method expires and the User does not provide the Company with an updated valid payment method or cancel their account, the User acknowledges the Company may terminate this agreement without notice. By submitting their card details to the Company, the User authorizes the Company to store such information with Stripe and to charge the card for payments due under this agreement until the agreement is terminated in accordance with clause 10.",
    ],
  },
  {
    title: "Termination",
    content: [
      "The User may terminate this agreement at any time in accordance with the instructions set out on the Application and/or on our website.",
      "The Company may terminate this agreement at any time without providing notice to the User.",
      "On termination, the User’s access to the User Account and any User Data remaining thereon will automatically cease.",
    ],
  },
  {
    title: "Retention of Funds",
    content: [
      "The User acknowledges and accepts that any and all funds obtained under this agreement from the operation of the Application shall be paid to the Company, and but for the payment of applicable taxes and sponsorship of charities shall be retained by the Company.",
      "Where a disbursement of funds is required under these Terms or any other agreement or document, the Company shall assume the responsibility for distributing said funds to any intended recipients.",
    ],
  },
  {
    title: "Miscellaneous",
    content: [
      "The company may change any part of these Terms at any time by uploading the revised Terms on the Company’s website, and your use of the Application after the effective date of the updated Terms constitutes your agreement to the updated Terms, as applicable. Users should review these Terms before using our Application from time to time. The updated Terms will be effective as of the time of upload, or on such later date as specified in the updated Terms and will apply to your use of the Application thereafter.",
      "The Application, Company Data and derivatives thereof may be subject to export laws and regulations of the US and other jurisdictions. Both parties each represents that it is not on any US government denied-party list. User will not permit any Designated Third Party access to or use of the Application or Company Data in a US-embargoed country or in violation of any export law or regulation. You further represent that you have not received or been offered any illegal bribe, kickback, payment, gift or thing of value in connection with your purchase or use of our Application (excluding any reasonable gifts and entertainment provided in the ordinary course of business).",
      "This agreement is governed by the laws of the State of California, USA. The parties submit to the exclusive jurisdiction of the Courts of that State.",
      "The User may not assign its rights, duties and obligations under these Terms without the other party’s prior written consent, which consent will not be unreasonably withheld or delayed; provided that the Company may assign these terms, and the rights granted to the Company under these Terms, without your consent to a successor (including a successor by way of merger, acquisition, sale of assets, or operation of law) if the successor agrees to assume and fulfill all of the Company’s obligations under these Terms.",
      "Except as otherwise outlined in these Terms, any notices under these Terms must be sent to the Company via email to cam@recant.ai, with a duplicate copy sent via registered mail (return receipt requested) to 19 Whittle Road East Rockingham Western Australia 6168. Any notices under these Terms that are sent to you shall be sent via email to the current named account owner of your User Account. You are responsible for maintaining the accuracy of the email address any other contact information of your named account owner on your User Account on Recant’s application/webpage [url](https://recant.ai/user-information)",
      "The Company is not liable to you or any third party for any failure to perform its obligations under these Terms where that failure results from any cause beyond its control, such as the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power failure; denial of service attacks or similar attacks or other acts of hackers; internet or network failures or interruptions; acts of God and the public enemy; acts of war; acts of terrorism; riots; civil or public disturbances; strikes; lockouts or labor disruptions; any laws, orders, rules, regulations, acts or restraints of any government or governmental body or authority, civil or military, including the orders and judgments of courts; or if another person or entity (including a social media network) changes their services or terms or no longer offers their services to you or the Company on terms the Company believes are reasonable.",
      "The Company is not liable to you or any third party for any failure to perform its obligations under these Terms where that failure results from any cause beyond its control, such as the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power failure; denial of service attacks or similar attacks or other acts of hackers; internet or network failures or interruptions; acts of God and the public enemy; acts of war; acts of terrorism; riots; civil or public disturbances; strikes; lockouts or labor disruptions; any laws, orders, rules, regulations, acts or restraints of any government or governmental body or authority, civil or military, including the orders and judgments of courts; or if another person or entity (including a social media network) changes their services or terms or no longer offers their services to you or the Company on terms the Company believes are reasonable.",
      "The Company is not liable to you or any third party for any failure to perform its obligations under these Terms where that failure results from any cause beyond its control, such as the elements; fire; flood; severe weather; earthquake; vandalism; accidents; sabotage; power failure; denial of service attacks or similar attacks or other acts of hackers; internet or network failures or interruptions; acts of God and the public enemy; acts of war; acts of terrorism; riots; civil or public disturbances; strikes; lockouts or labor disruptions; any laws, orders, rules, regulations, acts or restraints of any government or governmental body or authority, civil or military, including the orders and judgments of courts; or if another person or entity (including a social media network) changes their services or terms or no longer offers their services to you or the Company on terms the Company believes are reasonable.",
      "If a court finds any of these Terms to be invalid, the all remaining valid provisions of these Terms will continue to apply with the minimum changes required to remove the invalid section/s.",
      "All provisions in these Terms relating to disclaimers of warranties, remedies, damages, confidentiality, payment obligations, restrictions of use, and any other terms that either expressly or by their nature should survive, shall survive any termination of these Terms, and shall continue in full force and effect.",
      "These Terms, including the other documents referred to as applicable to the Application in these Terms, is the entire agreement between you and the Company for your use of the Application. Any prior understandings, statements and/or agreements (oral or written) do not apply, including additional terms that you may present (such as terms in a unilateral notice from you to the Company or printed on a purchase order or any other document that has been generated by you). These Terms are binding on the parties and their permitted successors and assigns.",
    ],
  },
];
