import React, { useState } from "react";

const Stepper = ({ list }) => {
  const [step, setStep] = useState(0);

  const handleNext = () => {
    if (step < list.length - 1) setStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 0) setStep(step - 1);
  };

  return (
    <div className="relative w-full mx-auto">
      <div className="relative mb-6">
        {/* Step Indicators */}
        <div className="flex items-center justify-between w-full max-w-[700px] mx-auto mb-9">
          {list.map((_, index) => (
            <div
              className={`flex items-center py-1 px-2 rounded-full border-[1px] gap-1 z-50 ${
                step >= index ? "border-primaryColor" : "border-[#aeadad]"
              }`}
              key={index}
            >
              <div
                className={`w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors duration-200 relative z-10 border-[1px] ${
                  step >= index ? "text-primaryColor" : "text-[#aeadad]"
                }`}
                onClick={() => setStep(index)}
              >
                {index + 1}
              </div>
              <div
                className={`text-[10px] ${
                  step >= index ? "text-primaryColor" : "text-[#aeadad]"
                }`}
              >
                {index === 0 ? (
                  <div>Sensitivity parameters</div>
                ) : (
                  <div>Social media </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Progress Bar */}
        <div className="absolute left-[0.4%] lg:left-[3.4%] right-0 top-[50%] transform -translate-y-1/2 mx-auto w-full max-w-[440px] z-0">
          <div
            className={`h-0.5 transition-all duration-300 bg-primaryColor `}
            style={{ width: `${(step / (list.length - 1)) * 100}%` }}
          />
        </div>
      </div>

      {/* Step Content */}
      <div className="z-30">
        {React.cloneElement(list[step], {
          onPrev: handlePrev,
          onNext: handleNext,
        })}
      </div>
    </div>
  );
};

export default Stepper;
