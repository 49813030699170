import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MouseHoverPopover from "../../common/MouseOverPopover";

const MainBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="   bg-[#e0e0e0]  ">
      <div className="py-[150px] lg:!pt-[250px]  max-w-[1440px] mx-auto  px-[15px] lg:px-[60px]">
        <h1 className="font-poppins text-5xl font-bold leading-[1.3] mb-[24px]">
          Combat online abuse.
        </h1>
        <div className="text-lg font-poppins">
          Introducing your private inbox assistant - a new way
          to filter and respond to abuse.
          </div>
          <div className="text-lg font-poppins mt-[20px] lg:mt-[20px] ">
          Recant.ai protects you from abuse and gives you tools to 
          report and hold offenders accountable.
        </div>
        <div className="text-lg font-poppins mt-[20px] lg:mt-[20px] ">
          Join the waitlist to be the first to enjoy a cleaner social
          media experience.
        </div>
        <div className="flex flex-col md:!flex-row lg:!flex-row  items-center w-full mt-5 lg:mt-7 gap-4 lg:!gap-2">
          <button
            className="w-full  lg:w-auto px-6 lg:px-7 py-3.5 rounded-3xl font-poppins text-[10px] lg:text-sm bg-baseColor text-white hover:bg-hoverBaseColor"
            onClick={() => navigate("/waiting-list")}
          >
            JOIN WAIT LIST
          </button>
          <div
            className="w-full   lg:w-auto px-6 lg:!px-3 py-3.5 justify-center rounded-3xl font-poppins text-[10px] lg:text-sm bg-baseColor text-white hover:bg-hoverBaseColor"
            onClick={() => navigate("/generate-response")}
          >
            <MouseHoverPopover
              popoverData={
                "You can demo our response tool here,\n and integrate your social media accounts\n to automate this later"
              }
              title={"QUICK DEMO?"}
            />
          </div>
          <button
            className=" w-full lg:w-auto px-6 lg:px-7 py-3.5 rounded-3xl font-poppins text-[10px] lg:text-sm bg-baseColor text-white hover:bg-hoverBaseColor"
            onClick={() => navigate("/upload-troll")}
          >
            <MouseHoverPopover
              popoverData={
                "This is where you can upload examples of horrible \nDMs you get – we will upload an anonymized \nversion of them on WWYMS"
              }
              title={"UPLOAD SCREENSHOTS TO BE REPORTED"}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
