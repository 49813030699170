import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { apiClient } from "../../../config/axios";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import { FaFacebook, FaPlus } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { useQuery, useMutation, useQueryClient } from "react-query";
import defaultImage from "../../../assets/images/defaultImage.png";
import { useMediaQuery, useTheme } from "@mui/material";

const FaceBookAddPage = () => {
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [loadingStates, setLoadingStates] = useState({});
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const fetchUserPages = async () => {
    const response = await apiClient.get(`/api/facebook/pages`, {
      headers: {
        token: accessToken,
      },
    });
    return response.data.pages;
  };

  const {
    isLoading,
    error,
    data: facebookPageData,
  } = useQuery("[userPages]", fetchUserPages);

  useEffect(() => {
    error && toast.error(error.response?.data?.message || "An error occurred");
  }, [error]);

  const mutation = useMutation(
    (pages) =>
      apiClient.post(
        `/api/facebook/page`,
        { pages: [pages] },
        {
          headers: {
            token: accessToken,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("[userPages]");
        toast.success("Profile successfully added!");
      },
      onError: (error) => {
        toast.error(error.response?.data?.message || "An error occurred");
      },
    }
  );

  const handleAddPages = (pages) => {
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [pages.id]: true,
    }));
    mutation.mutate(pages, {
      onSuccess: () => {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [pages.id]: false,
        }));
      },
    });
  };

  return (
    <OnBoardingLayout selected={1}>
      <div className="flex flex-col bg-white w-full md:px-40 px-4 pb-8 !overflow-hidden">
        <div
          className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 m-4 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <RiArrowLeftLine />
          Back
        </div>

        <div className="">
          <div className="md:m-4">
            <h4 className="mb-3 flex items-center gap-2">
              <FaFacebook size={27} className="text-[#1877F2]" />
              Facebook pages
            </h4>
            {facebookPageData && (
              <p>
                Here are the {facebookPageData.length} Facebook pages linked to
                the Facebook account
              </p>
            )}

            {isLoading ? (
              <div className="mt-40">
                <Loader />
              </div>
            ) : (
              <div className="pt-8 ">
                <div className="relative">
                  <div className="max-h-[calc(100vh-20rem)] min-h-96 overflow-y-auto bg-[#eff6f6] md:px-12 px-4 pt-4 !pb-16 shadow-inner">
                    {facebookPageData?.length === 0 ? (
                      <div className="text-[17px] font-semibold">
                        No data Found!
                      </div>
                    ) : (
                      facebookPageData?.map((page, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between gap-2 mb-6 px-8 py-3 bg-white shadow-sm border-[3px] border-lightGrey"
                        >
                          <div className="flex flex-row gap-3 items-center">
                            <img
                              src={page.picture?.data?.url || defaultImage}
                              className="h-16 w-16 object-cover"
                              alt=""
                            />
                            <div>
                              <h6 className="text-[#000]">{page.name}</h6>
                              <span className="text-sm">@{page.name}</span>
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 items-center font-bold">
                            {page?.checked ? (
                              isDesktop ? (
                                <button
                                  className="px-4 py-2  rounded-full font-light gap-2 text-white text-[17px] flex items-center border-[1px] bg-[#becec1]"
                                  disabled
                                >
                                  Add <FaPlus fontSize={11} />
                                </button>
                              ) : (
                                <div className="px-2 py-2 rounded-full text-white gap-2 bg-[#becec1] text-[17px] flex items-center border-[1px] ">
                                  <FaPlus fontSize={11} />
                                </div>
                              )
                            ) : (
                              <div className="relative flex items-center justify-center">
                                {loadingStates[page.id] && (
                                  <div className="absolute inset-0 flex items-center justify-center right-3">
                                    <Loader color={"#41964e"} />
                                  </div>
                                )}
                                {isDesktop ? (
                                  <button
                                    onClick={() => handleAddPages(page)}
                                    disabled={loadingStates[page.id]}
                                    className={`px-4 py-2 bg-[#54bc64] rounded-full text-white hover:bg-[#41964e] text-[17px] flex items-center gap-2 font-light ${
                                      loadingStates[page.id]
                                        ? "cursor-not-allowed opacity-50"
                                        : ""
                                    }`}
                                  >
                                    Add <FaPlus fontSize={11} />
                                  </button>
                                ) : (
                                  <button
                                    onClick={() => handleAddPages(page)}
                                    disabled={loadingStates[page.id]}
                                    className={`px-2 rounded-full py-2 bg-[#54bc64] text-white hover:bg-[#41964e] text-[17px] flex items-center gap-2 ${
                                      loadingStates[page.id]
                                        ? "cursor-not-allowed opacity-50"
                                        : ""
                                    }`}
                                  >
                                    <FaPlus fontSize={11} />
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                  {facebookPageData?.length > 0 && (
                    <div className="absolute bottom-0 z-50 w-full">
                      <div className="flex items-center justify-center bg-[#eff6f6] py-6 shadow-sm">
                        <button
                          className="bg-primaryColor w-full md:w-auto md:px-28 py-2 text-white font-bold hover:bg-hoverPrimary"
                          onClick={() => navigate(`/facebook/business-profile`)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default FaceBookAddPage;
