import React from "react";
import { RiCheckLine } from "react-icons/ri";

const PaymentCompletedCard = () => {
  return (
    <>
      <div className=" flex items-center text-2xl  mb-4 gap-2 justify-center text-[#5F6388] font-bold">
        <span className="text-green text-4xl">
          <RiCheckLine />
        </span>
        Payment Received!
      </div>

      {/* <div className="w-full flex flex-row justify-between mb-2 text-2xl text-[#5F6388] font-medium">
        <span className="">
          Welcome to Recant.ai - Learn About Responsible Messaging
        </span>
      </div>
      <div className=" w-full text-sm">
        <span className="text-grey text-sm font-normal">
          Dear [Sender's Name] <br />
          Thank you for your payment. Your message has been successfully
          recanted, meaning it has disappeared.
          <br />
        </span>
        <span className="text-grey text-sm font-normal">
          We invite you to take the next step to ensure your messaging
          experience remains smooth and respectful. By signing up with{" "}
          <a href="" className="text-grey">
            Recant.ai
          </a>
          , you can:
          <br />
        </span>

        <span className="text-grey text-sm font-bold">
          1. Learn About Responsible Messaging:
          <br />
        </span>
        <span className="text-grey text-sm font-normal">
          Make use of our pre-screening keyboard tool to ensure your messages
          align with recipient preferences.
          <br /> Gain access to educational resources and guidelines on
          responsible messaging.
          <br />
          Understand how to respect recipient preferences and avoid future
          repercussions.
          <br />
        </span>

        <span className="text-grey text-sm font-bold">
          2. Stay Informed:
          <br />
        </span>
        <span className="text-grey text-sm font-normal">
          Catfish updates: insights into who our system has flagged for misusing{" "}
          <a href="" className="text-grey">
            Recant.ai
          </a>{" "}
          for soliciting content in an attempt to get them in trouble.
          <br /> Receive updates on platform features, improvements, and user
          tips.
          <br />
          Stay connected with the Recant.ai community.
          <br />
        </span>
        <span className="text-grey text-sm font-bold">
          3. Manage Your Messages:
          <br />
        </span>
        <span className="text-grey text-sm font-normal">
          Easily manage your sent messages, and make informed decisions.
          <br />
          Explore message history and view past interactions.
          <br />
        </span>
        <span className="text-grey text-sm font-bold">
          4. Consequences of Irresponsible Messaging:
          <br />
        </span>
        <span className="text-grey text-sm font-normal">
          Discover the consequences of sending messages that may be considered
          inappropriate or unwanted by recipients.
          <br />
          <br />
        </span>
        <span className="text-grey text-sm font-bold">Your Truely</span>
        <br />
        <a href="" className="text-grey">
          Recant.ai
        </a>
      </div> */}
    </>
  );
};

export default PaymentCompletedCard;
