import React from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div
      className="back-btn flex items-center gap-2 border-[1px] border-[#cdcccc] flex-row py-2 px-3  mb-8 rounded-[4px]   font-semibold hover:bg-primaryColor hover:text-white "
      onClick={() => navigate(-1)}
    >
      <RiArrowLeftLine fontSize={20} />
      Back
    </div>
  );
};

export default BackButton;
