import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../CheckoutForm.js";
import { apiClient } from "../../config/axios.js";
import { useQuery } from "react-query";
import Loader from "../Loader.jsx";
import PlainHeader from "../../common/PlainHeader/index.js";
import { useLocation, useNavigate } from "react-router-dom";
import { MULTIPLY_PAYMENT } from "../../constant/MultiplyPayment.js";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment = () => {
  const location = useLocation();
  const [paymentSummaryPayload, setPaymentSummaryPayload] = useState(null);
  const [paymentCheckoutPayload, setPaymentCheckoutPayload] = useState(null);
  const navigate = useNavigate();

  const { data, isLoading } = useQuery("stripe-connect", async () => {
    try {
      const response = await apiClient.post(
        "/api/stripe/create-payment-intent",
        { amount: 50 }
      );
      console.log("API response:", response.data);
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch payment intent");
      console.error("API Error:", error);
    }
  });

  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split("?")[1];

    if (queryString) {
      const params = new URLSearchParams(queryString);
      const paramNames = Array.from(params.keys());
      setPaymentCheckoutPayload(paramNames[0]);
    }
  }, [location]);

  const mutation = useMutation((formData) =>
    apiClient.post(`api/checkout`, formData)
  );

  const onSubmit = async (formData) => {
    localStorage.setItem("firstName", formData.firstName);
    localStorage.setItem("lastName", formData.lastName);
    localStorage.setItem("email", formData.email);

    try {
      await mutation.mutateAsync(formData);
      navigate(
        paymentSummaryPayload === MULTIPLY_PAYMENT
          ? `/payment-checkout?${MULTIPLY_PAYMENT}`
          : "/payment-checkout"
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const clientSecret = data?.client_secret;

  const recantAmount =
    paymentCheckoutPayload === MULTIPLY_PAYMENT ? 50 * 3 : 50;

  return (
    <>
      <PlainHeader />
      <div className="container pt-[105px] ">
        <div className="max-w-[1440px] mx-auto">
          {isLoading ? (
            <div className="mt-[400px]">
              <Loader />
            </div>
          ) : (
            <div className="row items-center justify-between gap-[50px] flex-wrap ">
              <div className="col-lg ">
                <div className="text-2xl font-bold mb-10 text-[#5F6388] flex items-center ">
                  Payment Summary
                </div>

                <div className="text-xl flex flex-row justify-between text-[#5F6388] font-medium mb-[15px] ">
                  <span>Course Fee</span>
                  <span>${recantAmount}</span>
                </div>

                <div className="border-grey border-b border-dashed my-8" />
                <div className="flex flex-row justify-between text-black text-xl font-bold text-[#1E1F25]">
                  <span className="">Grand Total</span>
                  <span className="">${recantAmount}</span>
                </div>
                <div className="border-grey border-b border-dashed my-7" />
              </div>
              <div className="col-lg ">
                {clientSecret && (
                  <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm />
                  </Elements>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Payment;
