import React from "react";
import AdminLayout from "../Admin/AdminLayout";
import UserTable from "../../components/Admin/UserTable";
import { useQuery } from "react-query";
import { apiClient } from "../../config/axios";
import Loader from "../../components/Loader";

export const fetchUserList = async () => {
  const response = await apiClient.get(`/api/user/admin`);
  return response;
};

function UserList() {
  const { data, isLoading, error } = useQuery("[userList]", () =>
    fetchUserList()
  );

  if (isLoading) {
    return (
      <AdminLayout>
        <div className="h-[70vh]">
          <Loader />
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="flex items-center justify-center h-[70vh]">
          <div className="text-base lg:!text-xl font-poppins font-[600]">
            Data Not Found!
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <UserTable userList={data?.data?.users} />
    </AdminLayout>
  );
}

export default UserList;
