import { useQuery } from "react-query";
import { apiClient } from "../config/axios";

const fetchFacebookProfileStatus = async () => {
  const { data } = await apiClient.get("api/facebook/connect-profile-status");
  return data?.success;
};

export const useFacebookProfileStatus = () => {
  return useQuery("[facebookProfileStatus]", fetchFacebookProfileStatus, {
    staleTime: 1000,
  });
};
