import { Link, useNavigate } from "react-router-dom";

const PaymentModal = ({ closeModal }) => {
  const navigate = useNavigate();
  return (
    <div className="bg-overlay-dark fixed inset-0 flex items-center justify-center z-50">
      <div className=" relative z-10 max-w-[700px] bg-[#fff]  rounded-lg shadow-lg">
        <div className="Rectangle23   shadow border border-purple-500 backdrop-blur-2xl pt-[33px] px-10 pb-4">
          <div className=" flex items-center text-2xl  mb-4 gap-2 justify-center text-[#5F6388] font-bold">
            It's your lucky day!
          </div>

          <div className="w-full flex flex-row justify-between mb-2 text-2xl text-[#5F6388] font-medium"></div>
          <div className=" w-full text-sm">
            <span className="text-grey text-[18px] font-normal">
              Lucky this time, we are still in test mode and we're not yet
              taking payments.
              <br />
              <br />
            </span>
            <span className="text-grey text-[18px] font-normal">
              Please think twice next time and consider how your behavior
              affects others.
              <br />
            </span>
          </div>
          <div className="text-grey text-[18px] font-normal mt-3">
            Feel free to take this free course to help going forward:{" "}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfHGhESee37DqbgYSKMAULvjoTXDYeC5YBOUkVe-ajtWWOTww/viewform"
              target="_blank"
            >
              link
            </a>
          </div>
          <div className="no-underline flex items-center justify-center gap-4">
            <button
              className="bg-[#CC2828]  py-2 px-5 text-white justify-center rounded shadow   my-3 uppercase "
              onClick={closeModal}
            >
              Close
            </button>
            <button
              className="bg-primaryColor  py-2 px-5 text-white justify-center rounded shadow   my-3 uppercase "
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
export default PaymentModal;
