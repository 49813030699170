import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useQuery } from "react-query";
import FbUser from "../../../images/fbuser.png";
import { toast } from "react-toastify";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import Loader from "../../../components/Loader";
import { apiClient } from "../../../config/axios";
import SocialMediaPage from "../../../components/SocialMediaPage";
import defaultImage from "../../../assets/images/defaultImage.png";

const profileDetails = {
  image: FbUser,
  name: "Instagram Profile Name",
  link: "fb.com/pageid-198827468293",
  followers: "4.5k Followers",
};

const InstagramConnectedProfile = () => {
  const accessToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const fetchBusinessProfile = async (accessToken) => {
    const response = await apiClient.get(`/api/instagram/business-profile`, {
      headers: {
        token: accessToken,
      },
    });
    return response.data;
  };

  const {
    data: profile,
    isLoading,
    error,
  } = useQuery("businessProfile", () => fetchBusinessProfile(accessToken));

  useEffect(() => {
    {
      error &&
        toast.error(error.response?.data?.message || "An error occurred");
    }
  }, [error]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="flex flex-col bg-white w-full h-screen px-3 pt-3">
        <div
          className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 m-4"
          onClick={() => navigate(-1)}
        >
          <RiArrowLeftLine />
          Back
        </div>
        <div className="flex flex-row gap-4 m-5">
          <img
            src={profile?.platformProfileUrl || defaultImage}
            className="h-16 w-16"
            alt=""
          />
          <div className="flex flex-col">
            <h5 className="mb-0">{profile?.platformUsername}</h5>
            <span className="text-sm">{profileDetails?.link}</span>
            <span className="text-sm">{profileDetails?.followers}</span>
          </div>
        </div>
        <div className="">
          <div className="m-4">
            {profile && profile.business_profiles.length > 0 ? (
              <h4 className="mb-3">Select Profile</h4>
            ) : (
              ""
            )}
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div>
                {profile && profile.business_profiles.length === 0 ? (
                  <div className=" flex flex-col justify-center text-black font-medium text-base uppercase">
                    <h4 className="mb-4">Profile not added.</h4>
                    <Link
                      to={"/dashboard"}
                      className="flex flex-row gap-2 items-center bg-purple py-2 px-3 text-white rounded-sm cursor-pointer w-fit"
                    >
                      Add Instagram Profile?
                    </Link>
                  </div>
                ) : (
                  <div>
                    {profile &&
                      profile.business_profiles.map((profile, index) => (
                        <Link
                          to={`/instagram/messages/${profile.accountId}`}
                          key={index}
                          className="no-underline"
                        >
                          <SocialMediaPage
                            socialMediaPage={profile?.accountName}
                            imageUrl={profile?.profilePicUrl}
                          />
                        </Link>
                      ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default InstagramConnectedProfile;
