import React, { useState, useEffect } from "react";
import WhiteListUserItem from "../WhiteListUserItem";
import { apiClient } from "../../config/axios";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import SearchBar from "../SearchBar";
import Loader from "../../components/Loader";

const SideOverlayDrawer = ({
  openSideOverlayDrawer,
  setOpenSideOverlayDrawer,
  isWhiteListUserFetched,
}) => {
  const [userData, setUserData] = useState({
    checkedData: [],
    uncheckedData: [],
    originalUserDetails: [],
    searchQuery: "",
    filteredUsers: [],
  });

  const handleWhiteListChange = (userDetail, isChecked) => {
    setUserData((prevState) => {
      const updatedCheckedData = isChecked
        ? [...prevState.checkedData, userDetail]
        : prevState.checkedData.filter(
            (user) => user.username !== userDetail.username
          );

      const updatedUncheckedData = isChecked
        ? prevState.uncheckedData.filter(
            (user) => user.username !== userDetail.username
          )
        : [...prevState.uncheckedData, userDetail];

      return {
        ...prevState,
        checkedData: updatedCheckedData,
        uncheckedData: updatedUncheckedData,
      };
    });
  };

  const fetchUserDetails = async () => {
    const { data } = await apiClient.get("api/auth/twitter/white-list");
    return data;
  };

  const { data: userDetail, isWhiteListFetching } = useQuery(
    "[userDetail]",
    fetchUserDetails,
    {
      staleTime: 1000,
      enabled: isWhiteListUserFetched,
      onSuccess: (data) => {
        const originalCheckedData = data.users.filter((user) => user.isTrusted);
        const originalUncheckedData = data.users.filter(
          (user) => !user.isTrusted
        );
        setUserData({
          originalUserDetails: data.users,
          checkedData: originalCheckedData,
          uncheckedData: originalUncheckedData,
          filteredUsers: data.users,
          searchQuery: "", // Reset search query
        });
      },
    }
  );

  const updateWhiteListUsers = useMutation(
    async () => {
      try {
        const payload = {
          checkedUsers: userData.checkedData.map((user) => ({
            recipientId: user.userId,
          })),
          uncheckedUsers: userData.uncheckedData.map((user) => ({
            recipientId: user.userId,
          })),
        };

        await apiClient.patch(
          "api/message-interception/message-filter/update?type=twitter",
          payload
        );
        setOpenSideOverlayDrawer(false);
        setUserData((prevState) => ({ ...prevState, searchQuery: "" }));
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        toast.success("Data Updated Successfully");
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "An error occurred"
        );
      },
    }
  );

  const handleTwitterWhiteListCancel = () => {
    const originalCheckedData = userData.originalUserDetails.filter(
      (user) => user.isTrusted
    );
    const originalUncheckedData = userData.originalUserDetails.filter(
      (user) => !user.isTrusted
    );

    setUserData((prevState) => ({
      ...prevState,
      checkedData: originalCheckedData,
      uncheckedData: originalUncheckedData,
      filteredUsers: userData.originalUserDetails,
    }));
    setOpenSideOverlayDrawer(false);
  };

  // Filter users based on the search query
  useEffect(() => {
    if (userDetail) {
      const filtered = userDetail.users.filter(
        (user) =>
          user?.name
            ?.toLowerCase()
            .includes(userData.searchQuery.toLowerCase()) ||
          user?.username
            ?.toLowerCase()
            .includes(userData.searchQuery.toLowerCase())
      );
      setUserData((prevState) => ({ ...prevState, filteredUsers: filtered }));
    }
  }, [userData.searchQuery, userDetail]);

  // Update filteredUsers when originalUserDetails change
  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      filteredUsers: prevState.originalUserDetails,
    }));
  }, [userData.originalUserDetails]);

  return (
    <div
      className={`!fixed inset-0 overflow-hidden z-50 transform transition-transform duration-300 ease-in-out ${
        openSideOverlayDrawer ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="absolute inset-0">
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={() => setOpenSideOverlayDrawer(false)}
        ></div>

        <div className="absolute inset-y-0 right-0 max-w-full flex shadow-lg">
          <div className="relative w-screen md:max-w-[700px] max-w-sm bg-white shadow-xl flex flex-col">
            <div className="p-4 flex items-start justify-between shadow-sm border-b-[2px] border-[#e5e4e4]">
              <h2 className="text-lg font-medium text-gray-900">
                White List Twitter Users
              </h2>
              <button
                className="text-gray-400 hover:text-gray-500"
                onClick={handleTwitterWhiteListCancel}
              >
                <span className="sr-only">Close panel</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex-grow mb-[74px] bg-[#f1f1f1]">
              {/* Fixed Search Bar */}
              {isWhiteListFetching ? (
                <Loader color={"#000"} />
              ) : (
                <>
                  <div className="lg:px-20  sticky top-0  z-10  ">
                    <div className="p-4">
                      <SearchBar
                        searchQuery={userData.searchQuery}
                        setSearchQuery={(query) =>
                          setUserData((prev) => ({
                            ...prev,
                            searchQuery: query,
                          }))
                        }
                      />
                    </div>
                  </div>

                  {/* Scrollable Area */}
                  <div className="overflow-y-auto max-h-[calc(100vh-250px)] p-4 ">
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 lg:px-20 ">
                      {userData.filteredUsers.map((user, index) => (
                        <div key={index}>
                          <WhiteListUserItem
                            userDetail={user}
                            onWhiteListChange={handleWhiteListChange}
                            checkedData={userData.checkedData}
                            uncheckedData={userData.uncheckedData}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>

            <footer className="py-3 fixed bottom-0 w-full  md:max-w-[500px] lg:max-w-[700px] z-50 flex items-center justify-center shadow-sm border-t-[2px] border-[#e5e4e4] bg-white">
              <div className="flex gap-3">
                <button
                  className="px-6 bg-red rounded-[10px] py-2 text-white"
                  onClick={handleTwitterWhiteListCancel}
                >
                  Cancel
                </button>
                <button
                  className="px-6 bg-greenC rounded-[10px] py-2 text-white"
                  onClick={() => updateWhiteListUsers.mutate()}
                >
                  Submit
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideOverlayDrawer;
