import { useRef, useState } from "react";
import Step1Image from "../../assets/images/Parameters.png";
import Step2Image from "../../assets/images/Connect.png";
import Step3Video from "../../assets/images/messageVideo.mp4";
import step2 from "../../assets/images/step2.png";
import step1 from "../../assets/images/step1.png";
import Step3Image from "../../assets/images/messagenew.png";
import ReactPlayer from "react-player";

const WorkMechanism = () => {
  const videoRef = useRef(null);

  const steps = [
    {
      imgWidth: "w-full",
      lgImgWidth: "w-6",
      img: Step1Image,
      description:
        "Sign up and set your parameters based on what you feel is appropriate.",
      isVideo: false,
    },
    {
      imgWidth: "w-full",
      lgImgWidth: "w-9",
      img: Step2Image,
      description:
        "Press a button to connect the social media accounts you want protected.",
      isVideo: false,
    },
    {
      imgWidth: "w-full",
      lgImgWidth: "w-full",
      img: Step3Image,
      description:
        "Let your AI Agent filter and handle abuse discreetly in the background – no effort on your part.",
      isVideo: false,
    },
  ];

  return (
    <div className="md:!px-[90px] sm:!px-[60px] lg:!px-0">
      <div className="flex flex-col items-center justify-center mb-8 lg:mb-12">
        <div className="lg:text-3xl font-semibold font-poppins">
          Your space, your rules, in 3 steps...
        </div>
      </div>
      <div className="flex flex-col lg:!flex-row mt-[10px] lg:gap-8">
        {steps.map((step, index) => (
          <div key={index} className="flex-1 flex flex-col">
            <ul
              key={index}
              className="px-4 font-poppins h-full flex flex-col items-center text-center relative"
            >
              <div
                className={`relative ${step.imgWidth} lg:${step.lgImgWidth}`}
              >
                {step.isVideo ? (
                  <ReactPlayer
                    url={step.img}
                    ref={videoRef}
                    className={`w-full h-auto`} // Ensure the video takes the full width and maintains aspect ratio
                    playing={true}
                    muted={true}
                    loop={true}
                    width="100%" // Override the width
                    height="auto" // Maintain aspect ratio
                  />
                ) : (
                  <img
                    src={step.img}
                    alt={`step ${index + 1}`}
                    width="100%" // Override the width
                    height="auto"
                    className={`w-full h-auto`} // Consistent width and aspect ratio with video
                  />
                )}
              </div>
              <li className="font-semibold text-lg mb-2 mt-2">
                {`STEP ${index + 1}`}
              </li>
              <li className="text-base">{step.description}</li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkMechanism;
