import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import "./Messages.css";
import { format } from "date-fns";
import {
  RiMore2Fill,
  RiQuestionAnswerLine,
  RiMic2Fill,
  RiImage2Line,
  RiChatSmile2Fill,
  RiSendPlane2Line,
  RiArrowLeftLine,
} from "react-icons/ri";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { apiClient } from "../../config/axios";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import SendMessageBox from "../../components/Dashboard/SendMessageBox";

const TwitterMessages = () => {
  const queryClient = useQueryClient();
  const messageListRef = useRef(null);
  const params = useParams();
  const senderId = params.platformId;
  const [inputMessage, setInputMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [forceRefetch, setForceRefetch] = useState(false);
  const navigate = useNavigate();

  const {
    isLoading: isTwitterProfileLoading,
    data: userMessages,
    error,
  } = useQuery({
    queryKey: ["twitter-users"],
    queryFn: async () => {
      return await apiClient.get("api/auth/twitter/users-messages");
    },
    cacheTime: 0,
    staleTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    error && toast(error.response.data.message);
  }, [error]);

  useEffect(() => {
    if (userMessages?.data?.users?.length > 0) {
      setSelectedUser(userMessages.data.users[0]);
    }
  }, [userMessages]);

  const TWITTER_QUERY_KEY = ["twitter-message", selectedUser?.userId];

  const {
    error: isTwitterMessageError,
    data,
    refetch,
  } = useQuery({
    queryKey: TWITTER_QUERY_KEY,
    queryFn: async () => {
      return await apiClient.get(
        `/api/auth/twitter/message/${selectedUser?.userId}`
      );
    },
    enabled: !!selectedUser?.userId,
  });

  useEffect(() => {
    if (selectedUser?.userId) {
      refetch();
    }
  }, [selectedUser, forceRefetch, refetch]);

  const handleUserSelection = (user) => {
    setSelectedUser(user);
    setForceRefetch((prev) => !prev);
  };

  useEffect(() => {
    isTwitterMessageError && toast(isTwitterMessageError.response.data.message);
  }, [isTwitterMessageError]);

  const gettingInboxMessage = async () => {
    await apiClient.post("/api/auth/twitter/send-message", {
      message: inputMessage,
      participantId: selectedUser?.userId,
    });
  };

  const sendMessageMutation = useMutation({
    mutationFn: () => gettingInboxMessage(),
  });

  useEffect(() => {
    if (sendMessageMutation.isSuccess) {
      setInputMessage("");
      queryClient.invalidateQueries({ queryKey: TWITTER_QUERY_KEY });
    }
    sendMessageMutation.error && toast.error("Error sending message");
  }, [sendMessageMutation.isSuccess, sendMessageMutation.error]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      sendMessageMutation.mutate();
    }
  };

  useEffect(() => {
    isTwitterMessageError && toast(isTwitterMessageError.response.data.message);
  }, [isTwitterMessageError]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [selectedUser, data]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="mid-content flex flex-row w-full bg-[#eaeeff]">
        <div className="flex flex-col min:w-[275px] w-[275px] overflow-y-auto">
          <div className="flex items-center  mt-3  mb-3">
            <button
              className="back-btn flex items-center fflex-row py-1 px-2 hover:text-[#000] "
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine className="text-[20px]" />
            </button>

            <div className="text-[17px] ml-3 py-4">Messages</div>
          </div>
          {isTwitterProfileLoading ? (
            <div className="side-h flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <div>
              {userMessages?.data?.users?.length === 0 ? (
                <div
                  className={`side-h flex flex-row justify-between items-center text-center rounded-lg p-2 m-3 mt-1`}
                >
                  <div
                    className={`flex flex-row justify-center rounded-lg p-2 m-3 mt-1 bg-white text-rose text-sm w-full`}
                  >
                    No messages found
                  </div>
                </div>
              ) : (
                <div className="mx-2">
                  {userMessages?.data?.users?.map((user, index) => (
                    <div
                      className={`flex flex-row gap-2 justify-between items-center rounded-lg p-2 cursor-pointer mb-2 mr-2 ${
                        selectedUser?.id === user.id ? "selected" : ""
                      }`}
                      onClick={() => handleUserSelection(user)}
                      key={index}
                    >
                      <div className="">
                        <img
                          className="rounded-full w-9 h-9"
                          src={user.profileImageUrl}
                          alt="user-profile"
                        />
                      </div>
                      <div className="flex flex-col flex-grow">
                        <div className="font-bold text-sm">
                          {user.name} <br />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col flex-grow">
          {isTwitterProfileLoading ? (
            <div className="screen-h flex flex-col items-center justify-center text-xl bg-white">
              <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
              <div className="text-black font-medium text-base uppercase">
                No chats selected
              </div>
            </div>
          ) : (
            <div>
              {!selectedUser?.id ? (
                <div className="screen-h flex flex-col items-center justify-center text-xl bg-white">
                  <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
                  <div className="text-black font-medium text-base uppercase">
                    No chats selected
                  </div>
                </div>
              ) : (
                <div>
                  {selectedUser.id && (
                    <div className="flex flex-col flex-grow bg-white justify-between ">
                      {/* Selected User details */}
                      <div className="flex flex-row justify-between items-center p-3 border-b border-[#e8e7e7]">
                        <div className="flex flex-row gap-2 justify-between items-start rounded-lg p-2 cursor-pointer">
                          <div className="">
                            <img
                              className="rounded-full w-12 h-12"
                              src={selectedUser.profileImageUrl}
                              alt="user-profile"
                            />
                          </div>
                          <div className="flex flex-col flex-grow">
                            <div className="font-bold">{selectedUser.name}</div>
                            <div className="text-grey text-sm">
                              last seen today at 12:02 PM
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Message List */}
                      <div
                        className="message-content overflow-y-auto"
                        ref={messageListRef}
                      >
                        <div className="flex flex-col flex-grow p-3 justify-end">
                          <div className="text-center mb-2">
                            Today, October 11
                          </div>

                          {Array.isArray(data?.data?.message) &&
                            data?.data?.message.map((message) => (
                              <div key={message.id}>
                                {message.user_id ===
                                userMessages?.data?.profile.platformId ? (
                                  <div className="mb-3">
                                    <div className="flex flex-row-reverse gap-2 justify-start items-center">
                                      {/* Sender Message */}
                                      <img
                                        src={message.profile_image_url}
                                        className="rounded-full w-9 h-9"
                                        alt=""
                                      />
                                      <div className="flex flex-col items-end">
                                        {message.s3_url ? (
                                          <div>
                                            <img
                                              style={{
                                                height: 200,
                                                width: 200,
                                                maxWidth: "100%", // Ensures image doesn't overflow
                                                objectFit: "cover",
                                              }}
                                              src={message.s3_url}
                                              alt=""
                                            />
                                          </div>
                                        ) : (
                                          <div className="bg-[#C7DFFF] text-gray px-3 py-2 rounded-md my-2 max-w-xs break-words">
                                            {message.text}
                                          </div>
                                        )}
                                        <div className="text-[#C4C4C4] text-xs">
                                          {message.message_created_at
                                            ? format(
                                                new Date(
                                                  message.message_created_at
                                                ),
                                                "hh:mm"
                                              )
                                            : "Invalid Date"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="mb-3">
                                    <div className="flex flex-row gap-2 justify-start items-center">
                                      {/* Receiver Message */}
                                      <img
                                        src={selectedUser.profileImageUrl}
                                        className="rounded-full w-9 h-9"
                                        alt=""
                                      />
                                      <div className="flex flex-col items-start">
                                        <div className="bg-[#F3F3F3] text-gray px-3 py-2 rounded-md my-2 max-w-xs break-words">
                                          {message.text}
                                        </div>
                                        <div className="text-[#C4C4C4] text-xs">
                                          {message.message_created_at
                                            ? format(
                                                new Date(
                                                  message.message_created_at
                                                ),
                                                "MM/dd hh:mm"
                                              )
                                            : "Invalid Date"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* Message Input */}
                      <div className="border-t-2 border-gray-200 px-4 pt-4 mb-2 flex items-center justify-between">
                        <SendMessageBox
                          inputMessage={inputMessage}
                          setInputMessage={setInputMessage}
                          handleSendValue={() => sendMessageMutation.mutate()}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default TwitterMessages;
