import React from "react";
import { FaSpinner } from "react-icons/fa";

function Loader({ color, size }) {
  return (
    <div className="flex items-center justify-center h-full">
      <svg width="0" height="0">
        <linearGradient
          id="blue-gradient"
          x1="120%"
          y1="120%"
          x2="-20%"
          y2="-20%"
        >
          <stop stopColor="#618c80" offset="0%" />
          <stop stopColor="#8f5707" offset="100%" />
        </linearGradient>
      </svg>
      <FaSpinner
        className={`animate-spin text-blue-500  ${size ? size : "text-4xl"}`}
        style={{ fill: color || "#618c80" }}
      />
    </div>
  );
}

export default Loader;
