import React from "react";
import Loader from "../../components/Loader";

const ButtonField = ({ isLoading, onClick, label, bgColor, bgHover }) => {
  return (
    <div className="relative w-full flex items-center justify-center mt-10">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center right-3 top-[1px]">
          <Loader />
        </div>
      )}
      <button
        onClick={onClick}
        type="submit"
        disabled={isLoading}
        className={`px-24 py-[10px] rounded-[10px] font-bold w-full ${
          bgHover ? bgHover : "hover:bg-hoverPrimary"
        } text-white flex justify-center items-center ${
          bgColor ? bgColor : "bg-primaryColor"
        } ${isLoading ? "cursor-not-allowed opacity-50" : ""}`}
      >
        {label}
      </button>
    </div>
  );
};

export default ButtonField;
