export function createFormDataWithRepeatedKeys() {
  const key = "uploadedProfilePicture"; // Update the key here
  const storedData = localStorage.getItem(key);

  if (!storedData) {
    console.warn(`No data found for key: ${key}`);
    return null;
  }

  // Parse JSON to get the array of files
  const filesData = JSON.parse(storedData);
  const formData = new FormData();

  // Function to convert base64 data to a Blob
  function base64ToBlob(base64, mimeType) {
    const byteString = atob(base64.split(",")[1]);
    const arrayBuffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: mimeType });
  }

  // Convert each base64 string back to a File and append it
  filesData.forEach((fileData) => {
    const blob = base64ToBlob(fileData.base64, fileData.type);
    const file = new File([blob], fileData.name, { type: fileData.type });
    formData.append("dankestPhotos", file);
  });

  return formData;
}
