import React from "react";
import { useForm } from "react-hook-form";
import {
  directMessageOption,
  socialMediaPeople,
  messageFrequency,
  messageType,
} from "../WaitingList/defaultValue";
import Table from "react-bootstrap/Table";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient } from "../../config/axios";
import { useMutation } from "react-query";
import PlainHeader from "../../common/PlainHeader";

const InfluencerInformation = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      unsolicited_unexpected_unwanted: {
        from_stranger: "",
        from_someone_you_know: "",
        from_someone_you_work_with: "",
        from_someone_anonymously: "",
        of_sexual_nature: "",
        of_bullying_nature: "",
      },
      explicit_types_of_messages: {
        identifiable_stranger: "",
        known_person: "",
        someone_anonymously: "",
      },
    },
  });

  const mutation = useMutation((formData) =>
    apiClient.post(`api/waiting-list/individual-survey`, formData)
  );

  const onSubmit = async (formData) => {
    try {
      await mutation.mutateAsync(formData);
      toast.success("Data added successfully");
      navigate("/");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <PlainHeader />
      <div className="bg-[#fff] container py-[20px] text-[#000] ">
        <div className=" border rounded-[3px] p-4 max-w-5xl mx-auto mt-[69px]">
          <div className=" font-[200px] text-sm lg:!text-lg text-primaryColor mb-[40px]">
            Thank you for electing to complete this short survey to help us
            understand better the nature of the problem. This survey is
            completely anonymous and any answers will not be linked to your
            identity.
            <br />
            Please note that this short survey contains questions regarding your
            experience with online abuse, which some may find distressing.
            Please only proceed if you are comfortable to do so.
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="text-[14px]">
            <div className="mb-[50px]">
              <div className="mb-[16px] font-bold  sm:text-[14px]">
                Have you ever been sent a direct message on any social platform
                you would consider unsolicited, unexpected or unwanted from?
              </div>
              <Table responsive>
                <thead>
                  <tr className="sm:text-[13px]">
                    <th></th>
                    {directMessageOption.map((data, index) => (
                      <th key={index}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="sm:text-[13px]">
                  {socialMediaPeople.map((data, index) => (
                    <tr key={index}>
                      <th scope="row">{data?.label}</th>
                      <td>
                        <input
                          {...register(
                            `unsolicited_unexpected_unwanted.${data?.value}`
                          )}
                          type="radio"
                          className="mr-[11px] "
                          name={`unsolicited_unexpected_unwanted.${data?.value}`}
                          value={directMessageOption[0]}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `unsolicited_unexpected_unwanted.${data?.value}`
                          )}
                          type="radio"
                          name={`unsolicited_unexpected_unwanted.${data?.value}`}
                          className="mr-[11px] "
                          value={directMessageOption[1]}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `unsolicited_unexpected_unwanted.${data?.value}`
                          )}
                          type="radio"
                          name={`unsolicited_unexpected_unwanted.${data?.value}`}
                          className="mr-[11px]"
                          value={directMessageOption[2]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {errors?.unsolicited_unexpected_unwanted && (
                <p
                  style={{ color: "#CC2828", marginTop: "15px" }}
                  className="sm:text-[13px]"
                >
                  {errors.unsolicited_unexpected_unwanted?.message}
                </p>
              )}
            </div>

            {/* Message type scetion */}
            <div className="mb-[50px]">
              <div className="mb-[16px] font-bold sm:text-[14px]">
                How often have you received sexually explicit or abusive contact
                types of messages from?
              </div>
              <Table responsive>
                <thead>
                  <tr className="sm:text-[13px]">
                    <th></th>
                    {messageFrequency.map((data, index) => (
                      <th key={index}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="sm:text-[13px]">
                  {messageType.map((data, index) => (
                    <tr key={index}>
                      <th scope="row">{data?.label}</th>
                      <td>
                        <input
                          {...register(
                            `explicit_types_of_messages.${data?.value}`
                          )}
                          type="radio"
                          name={`explicit_types_of_messages.${data?.value}`}
                          className="mr-[11px]"
                          value={messageFrequency[0]}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `explicit_types_of_messages.${data?.value}`
                          )}
                          type="radio"
                          name={`explicit_types_of_messages.${data?.value}`}
                          className="mr-[11px]"
                          value={messageFrequency[1]}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `explicit_types_of_messages.${data?.value}`
                          )}
                          type="radio"
                          name={`explicit_types_of_messages.${data?.value}`}
                          className="mr-[11px]"
                          value={messageFrequency[2]}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `explicit_types_of_messages.${data?.value}`
                          )}
                          type="radio"
                          name={`explicit_types_of_messages.${data?.value}`}
                          className="mr-[11px]"
                          value={messageFrequency[3]}
                        />
                      </td>
                      <td>
                        <input
                          {...register(
                            `explicit_types_of_messages.${data?.value}`
                          )}
                          type="radio"
                          name={`explicit_types_of_messages.${data?.value}`}
                          className="mr-[11px]"
                          value={messageFrequency[4]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {errors?.directMessage && (
                <p
                  style={{ color: "#CC2828", marginTop: "15px" }}
                  className="sm:text-[13px]"
                >
                  {errors?.directMessage?.message}
                </p>
              )}
            </div>

            <div className="flex items-center justify-center">
              <button
                disabled={mutation.isLoading}
                className="submit-btn bg-primaryColor flex items-center justify-center gap-2 hover:bg-white hover:!border-[2px] hover:!border-primaryColor hover:text-primaryColor"
                type="submit"
              >
                {mutation.isLoading && (
                  <div class="spinner-border" role="status"></div>
                )}
                <div>Submit</div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default InfluencerInformation;
