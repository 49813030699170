import { z } from "zod";

export const SignUpSchema = z
  .object({
    email: z.string().min(1, { message: "Username is required" }).email(),
    password: z.string().min(1, { message: "Password is required" }).min(7, {
      message: "Password must be at least 7 characters long",
    }),
    confirmPassword: z
      .string()
      .min(1, { message: "Confirm Password is required" })
      .min(7, {
        message: "Password must be at least 7 characters long",
      }),
    firstName: z.string(),
    lastName: z.string(),
    terms_and_conditions: z.boolean().refine((value) => value === true, {
      message: "You must accept the terms and conditions",
    }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });
