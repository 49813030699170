export const options = {
  responseTone: [
    { key: "somewhat_jovial", label: "Keep it fun" },
    { key: "firm_friendly", label: "Firm but kinda friendly" },
    { key: "serious_polite", label: "Serious but polite" },
    { key: "very_serious", label: "Very serious" },
  ],
  messageNature: [
    {
      key: "violent_threats",
      label: "Explicit violent threats or sexual solicitation",
    },
    { key: "creepy_messages", label: "Generally creepy or weird behavior" },
    { key: "crude_banter", label: "Swearing or crude banter" },
  ],
  idealOutcome: [
    { key: "back_off", label: "Back off completely" },
    { key: "beg_for_forgiveness", label: "Beg for forgiveness" },
    { key: "acknowledge_warning", label: "Acknowledge warning" },
  ],
  privacyPreference: [
    {
      key: "no",
      label: "No",
      children: [
        { key: "keep_private", label: "And keep this private" },
        {
          key: "warn_sender",
          label:
            "But warn them about reporting if they continue",
        },
      ],
    },
    {
      key: "yes",
      label: "Yes",
      children: [
        {
          key: "nested_yes",
          label: "Yes",
          children: [
            {
              key: "publish_with_conditions",
              label:
                "No, I'll keep it private, but they must pay to take it back",
            },
            {
              key: "publish_immediately",
              label:
                "Yes, warn that I may report if they don't take it back",
            },
          ],
        },
        {
          key: "nested_no",
          label: "No",
        },
      ],
    },
  ],
};
