import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";
import ModalField from "../../common/ModalField";
import { FaCircleCheck } from "react-icons/fa6";
import { AiFillCloseCircle } from "react-icons/ai";
import PlainHeader from "../../common/PlainHeader";

const MessageInterceptTrust = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [messageFilterPayload, setMessageFilterPayload] = useState(null);
  const [senderName, setSenderName] = useState(null);
  const [showTrustModal, setShowTrustModal] = useState(false);
  const [showDoNotTrustModal, setShowDoNotTrustModal] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname.includes("message-filter")) {
      const payload = queryParams.get("payload");
      const sender = queryParams.get("sender");
      if (payload) {
        setMessageFilterPayload(payload);
      }
      if (sender) {
        setSenderName(sender);
      }
    }
  }, [location]);

  const messageInterceptSuccessful = async (messageFilterPayload, trust) => {
    if (!messageFilterPayload) {
      const errorMessage = "Payload is missing.";
      throw new Error(errorMessage);
    }
    try {
      await apiClient.post("api/message-interception/message-filter", {
        payload: messageFilterPayload,
        trust: trust,
      });
    } catch (error) {
      throw error;
    }
  };
  const trustMutation = useMutation(
    (payload) => messageInterceptSuccessful(payload, true),
    {
      onSuccess: () => {
        toast.success("Allegation trusted successfully");
        navigate("/");
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "An error occurred"
        );
      },
    }
  );

  const donotTrustMutation = useMutation(
    (payload) => messageInterceptSuccessful(payload, false),
    {
      onSuccess: () => {
        toast.success("Allegation denied successfully");
        navigate("/");
      },
      onError: (error) => {
        console.log(error, "error");
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            error ||
            "An error occurred"
        );
      },
    }
  );

  return (
    <>
      <PlainHeader />
      <div className="bg-[#fff] container py-[20px] text-[#000]">
        <div className="w-full mt-32">
          <form className="verified-form container">
            <div>
              <h5 className="flex mb-4 justify-center text-2xl">
                Sender Verification and Message Handling
              </h5>
              <p className="text-base text-center mb-6 text-[#1e1c1ce8] font-light">
                We identified a message from {senderName} that could be abusive
                and harmful. Upon interception of this message, they insisted
                that you know one another and that you are OK with receiving it.
              </p>
              <div className="flex gap-4 mt-6 flex-wrap xl:px-20">
                <div className="flex-1 border-[1px] rounded-[10px] border-[#cac8c8] flex flex-col shadow-lg p-8">
                  <div className="flex flex-col items-center gap-5 h-full">
                    <FaCircleCheck fontSize={24} className="text-[#28a745]" />
                    <p className="text-center flex-grow text-[#1e1c1ce8] font-light">
                      If you agree, this sender will be whitelisted, and our AI
                      will not intercept messages from this user again.
                    </p>
                    <button
                      className="w-full bg-[#28a745] px-12 py-2 rounded-[10px] mt-4 text-white hover:bg-[#218838] border-none font-semibold"
                      onClick={(event) => {
                        event.preventDefault();
                        setShowTrustModal(true);
                      }}
                    >
                      TRUST
                    </button>
                  </div>
                </div>
                <div className="flex-1 border-[1px] rounded-[10px] border-[#cac8c8] flex flex-col shadow-lg p-8">
                  <div className="flex flex-col items-center gap-5 h-full">
                    <AiFillCloseCircle
                      fontSize={28}
                      className="text-[#dc3545]"
                    />
                    <p className="text-center flex-grow text-[#1e1c1ce8] font-light">
                      If you do not, this sender will be both blocked and will
                      receive a higher penalty for lying. If they fail to accept
                      this offer, you will have the chance to report them as
                      serial abusers to both the community and the authorities.
                    </p>
                    <button
                      className="w-full bg-[#dc3545] px-12 py-2 rounded-[10px] mt-4 text-white hover:bg-[#c82333] border-none font-semibold"
                      onClick={(event) => {
                        event.preventDefault();
                        setShowDoNotTrustModal(true);
                      }}
                    >
                      DO NOT TRUST
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <ModalField
            confirmButtonTitle={"Confirm"}
            modalHeader={"Confirm Trust"}
            modalContent={"Are you sure you want to trust?"}
            handleCloseModal={() => setShowTrustModal(false)}
            isLoading={trustMutation.isLoading}
            showModal={showTrustModal}
            onClickConfirmButton={() =>
              trustMutation.mutate(messageFilterPayload, true)
            }
          />
          <ModalField
            confirmButtonTitle={"Confirm"}
            modalHeader={"Confirm Do Not Trust"}
            modalContent={"Are you sure you want to not trust?"}
            handleCloseModal={() => setShowDoNotTrustModal(false)}
            isLoading={donotTrustMutation.isLoading}
            showModal={showDoNotTrustModal}
            onClickConfirmButton={() =>
              donotTrustMutation.mutate(messageFilterPayload, false)
            }
          />
        </div>
      </div>
    </>
  );
};

export default MessageInterceptTrust;
