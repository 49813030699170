import React, { useState, useEffect } from "react";
import PlainHeader from "../../common/PlainHeader";
import { IoDocuments } from "react-icons/io5";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { MULTIPLY_PAYMENT } from "../../constant/MultiplyPayment";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import ButtonField from "../../common/ButtonField";

const DenyAllegation = () => {
  const location = useLocation();
  const [lodgeDisputeShortId, setLodgeDisputeShortId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname.includes("response-allegation")) {
      const short_id = queryParams.get("sid");

      if (short_id) {
        setLodgeDisputeShortId(short_id);
      }
    }
  }, [location]);

  const lodgeDispute = async () => {
    const response = await apiClient.post(
      "api/message-interception/lodge-dispute",
      { short_id: lodgeDisputeShortId }
    );
    return response.data;
  };

  const lodgeDisputeMutation = useMutation(lodgeDispute, {
    onSuccess: () => {
      navigate("/");
      toast.success("Dispute lodge successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  return (
    <>
      <PlainHeader />
      <div className="container ">
        <form className="verified-form container pt-36">
          <div>
            <h5 className="flex mb-4 justify-center text-xl">
              Resolve Your Issue: Choose an Option Below
            </h5>
            <p className="text-base  mb-6 text-[#1e1c1ce8] font-light ">
              The recipient you claim to know and/or has solicited inappropriate
              communications has rejected your claim. As noted previously, you
              are now subject to either lodge a dispute that will be used as
              evidence with the authorities, or settle it now by paying the full
              fee as agreed earlier. You have 7 days to lodge this dispute.
            </p>
            <div className="flex gap-4 mt-6 flex-wrap xl:px-20">
              <div className="flex-1 border-[1px] rounded-[10px] border-[#cac8c8] flex flex-col shadow-lg p-8">
                <div className="flex flex-col items-center gap-5 h-full">
                  <FaMoneyCheckAlt fontSize={28} className=" text-[#28a745]" />
                  <p className="text-center flex-grow text-[#1e1c1ce8] font-light">
                    You can either pay 3 times the fee to settle the issue
                  </p>
                  <button
                    className="w-full bg-greenC px-12 py-2 rounded-[10px] mt-4 text-white hover:bg-hoverGreen  border-none font-semibold"
                    onClick={() =>
                      navigate(`/payment-summary?${MULTIPLY_PAYMENT}`)
                    }
                  >
                    Pay 3x Recant Fee
                  </button>
                </div>
              </div>
              <div className="flex-1 border-[1px] rounded-[10px] border-[#cac8c8] flex flex-col shadow-lg p-8">
                <div className="flex flex-col items-center gap-5 h-full">
                  <IoDocuments fontSize={24} className="text-[#dc3545]" />
                  <p className="text-center flex-grow text-[#1e1c1ce8] font-light">
                    You can lodge a dispute within the next 7 days by uploading
                    the necessary evidence
                  </p>
                  <ButtonField
                    onClick={(e) => {
                      e.preventDefault();
                      lodgeDisputeMutation.mutate();
                    }}
                    label="Lodge a Dispute"
                    isLoading={lodgeDisputeMutation.isLoading}
                    bgColor={"bg-[#dc3545]"}
                    bgHover={"hover:bg-[#c82333]"}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DenyAllegation;
