import React from "react";
import { useForm } from "react-hook-form";
import PlainHeader from "../../common/PlainHeader";

const TwoFactorAuthentication = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
  });

  const securityCode = watch("securityCode", "");

  const onSubmit = (data) => {
    console.log(data, "data");
  };

  const isValidCode = (code) => /^[0-9]{6}$/.test(code);

  return (
    <>
      <PlainHeader />
      <div className="flex items-center justify-center min-h-screen px-20 bg-lightGrey">
        <div className="pt-[74px] border px-12 py-24 bg-white">
          <h5 className="uppercase text-center mb-4">Enter Security Code</h5>
          <div className="text-center mb-4">
            Enter the code that we sent to your account
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col items-center w-full"
          >
            <input
              {...register("securityCode", {
                required: "Security code is required",
              })}
              className="px-12 bg-white py-2 mb-4 border border-gray-300 w-full"
              type="text"
              placeholder="Security Code"
            />
            {errors.securityCode && (
              <span className="text-red-500 text-sm mb-4">
                {errors.securityCode.message}
              </span>
            )}
            <button
              type="submit"
              className={`px-12 bg-primaryColor text-white py-2 w-full ${
                !isValidCode(securityCode)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              disabled={!isValidCode(securityCode)}
            >
              Confirm
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default TwoFactorAuthentication;
