import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import io from "socket.io-client";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import "./Messages.css";
import { useNavigate } from "react-router-dom";
import { RiQuestionAnswerLine, RiArrowLeftLine } from "react-icons/ri";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { apiClient } from "../../config/axios";
import SendMessageBox from "../../components/Dashboard/SendMessageBox";
import SelectedUserDetails from "../../common/SelectedUserDetail";
import MessageUserList from "../../common/MessageUserList";
import MessageList from "../../common/MessageList";
import { useMediaQuery, useTheme } from "@mui/material";
const FacebookMessages = () => {
  const messageListRef = useRef(null);
  const BACKEND_URI = process.env.REACT_APP_BACKEND_URI;
  const [socket, setSocket] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [userDetail, setUsers] = useState({ pagePic: "", users: [] });
  const [loading, setLoading] = useState(true);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("1024"));
  const [inputMessage, setInputMessage] = useState("");
  const [conversationDetail, setConversationDetail] = useState({
    conversations: [],
    selectedUser: {},
  });

  const getUserList = () => {
    return apiClient
      .get(`/api/facebook/conversations/${params.businessId}/user-list`)
      .then((res) => {
        const { users, businessProfilePic } = res.data;
        if (users.length > 0) {
          getMessages(users[0]);
        }
        setUsers({ users, pagePic: businessProfilePic });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred");
      });
  };

  const getMessages = (user) => {
    return apiClient
      .get(
        `/api/facebook/business/${user.businessProfileId}/conversations/${user.conversationId}`
      )
      .then((res) => {
        setConversationDetail({
          conversations: res.data.conversations,
          selectedUser: user,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred");
      });
  };

  const messageListner = (message) => {
    // setMessages([...messages, message])
  };

  useEffect(() => {
    const newScoket = io(BACKEND_URI);

    getUserList();
    setSocket(newScoket);

    // return () => {
    //   socket.disconnect();
    // };
  }, [setSocket]);

  useEffect(() => {
    console.log("these");
    socket?.on("message", (data) => {
      if (data.pageId === params.businessId) {
        const senderDetail = data.senderDetail;
        const selectedUser = conversationDetail.selectedUser;

        if (
          selectedUser.id === undefined ||
          senderDetail.sender.id === selectedUser.id ||
          senderDetail.recipient.id === selectedUser.id
        ) {
          const conversationData = {
            conversations: data.conversations,
            selectedUser:
              Object.keys(selectedUser).length === 0
                ? data.sender.users[0]
                : selectedUser,
          };

          setConversationDetail(conversationData);
        }

        const usersData = {
          users: data.sender.users,
          pagePic: data.sender.businessProfilePic,
        };
        setUsers(usersData);
      }
    });

    return () => {
      socket?.off("message");
    };
  }, [messageListner]);

  const recipient = params.businessId;

  const handleSendValue = () => {
    const {
      id: recipient,
      businessProfileId,
      conversationId,
    } = conversationDetail.selectedUser;
    // Add your logic to send the value to the desired destination
    setSendMessageLoading(true);
    return apiClient
      .post(`/api/facebook/send-message`, {
        message: inputMessage,
        recipient: recipient,
        businessProfileId: businessProfileId,
        conversationId: conversationId,
      })
      .then(() => {
        setSendMessageLoading(false);
        setInputMessage("");
      })
      .catch((error) => {
        setSendMessageLoading(false);
        toast.error(error?.response?.data?.message || "An error occurred");
      });
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [conversationDetail]);

  return (
    <OnBoardingLayout selected={1}>
      <svg width="0" height="0">
        <linearGradient
          id="blue-gradient"
          x1="120%"
          y1="120%"
          x2="-20%"
          y2="-20%"
        >
          <stop stopColor="#9747FF" offset="0%" />
          <stop stopColor="#F8567A" offset="100%" />
        </linearGradient>
      </svg>
      <div className="mid-content flex flex-row w-full bg-white">
        <div className="flex flex-col lg:min:w-[275px] lg:w-[275px] w-[60px] border-r-[1.8px] border-greyLight">
          <div className="flex items-center mt-8 mb-3 ">
            <button
              className="back-btn flex items-center py-1 px-2 hover:text-[#000]"
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine className="text-[20px]" />
            </button>
            {isDesktop && <div className="text-[17px] ml-3">Messages</div>}
          </div>
          {loading ? (
            <div className="side-h flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <MessageUserList
              userDetail={userDetail}
              getMessages={getMessages}
              conversationDetail={conversationDetail}
            />
          )}
        </div>
        <div className="flex flex-col flex-grow">
          {loading ? (
            <div className="screen-h flex flex-col items-center justify-center text-xl bg-white">
              <Loader />
            </div>
          ) : userDetail.users.length === 0 ? (
            <div className="screen-h flex flex-col items-center justify-center text-xl bg-white">
              <RiQuestionAnswerLine className="no-message text-[96px] mb-6 text-primaryColor" />
              <div className="text-baseColor font-medium text-base uppercase">
                No chats selected
              </div>
            </div>
          ) : (
            conversationDetail.selectedUser.id && (
              <div className="flex flex-col flex-grow bg-white">
                <SelectedUserDetails user={conversationDetail.selectedUser} />
                <MessageList
                  messages={conversationDetail.conversations}
                  selectedUser={conversationDetail.selectedUser}
                  pagePic={userDetail.pagePic}
                  messageListRef={messageListRef}
                  recipient={recipient}
                />
                <SendMessageBox
                  loading={sendMessageLoading}
                  inputMessage={inputMessage}
                  setInputMessage={setInputMessage}
                  handleSendValue={handleSendValue}
                />
              </div>
            )
          )}
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default FacebookMessages;
