import React, { useEffect, useRef, useState } from "react";
import PlainHeader from "../../common/PlainHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { apiClient } from "../../config/axios";
import { useQuery, useMutation } from "react-query";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { EmptyData } from "../../common/EmptyData";
import { MULTIPLY_PAYMENT } from "../../constant/MultiplyPayment";
import ModalField from "../../common/ModalField";

export const MessageAllegation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [messageAllegationPayload, setMessageAllegationPayload] =
    useState(null);

  const fetchMessageAllegation = async () => {
    const response = await apiClient.post(
      "api/message-interception/message-allegation",
      {
        payload: messageAllegationPayload,
      }
    );
    return response.data;
  };

  const {
    isLoading,
    data: allegationData,
    isError,
  } = useQuery(
    ["message_allegation", messageAllegationPayload],
    fetchMessageAllegation
  );

  const denyMessageAllegation = async () => {
    const response = await apiClient.post(
      "api/message-interception/deny-allegation",
      { payload: messageAllegationPayload }
    );
    return response.data;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname.includes("message-allegation")) {
      const payload = queryParams.get("payload");
      if (payload) {
        setMessageAllegationPayload(payload);
      }
    }
  }, [location]);

  const denyMutation = useMutation(denyMessageAllegation, {
    onSuccess: () => {
      navigate("/");
      toast.success("Allegation denied successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  const bottomRef = useRef(null);

  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [isLoading]);

  return (
    <div>
      <PlainHeader />
      <div className="pt-[140px] xl:px-40 md:px-10 sm:px-4">
        {isLoading ? (
          <div className="mt-20">
            <Loader />
          </div>
        ) : isError ? (
          <EmptyData message="Allegation not found !" />
        ) : (
          <>
            <div className="border-[1px] flex flex-col gap-3 p-6 border-[#e0e0dc] rounded-[4px]">
              <h4 className="flex justify-center">Notice of Allegation</h4>
              <div>
                This page outlines allegations concerning messages you may or
                may not have sent, as detailed below. You have the option to
                either deny the allegations or make a payment if you are found
                guilty.
              </div>

              <div>
                <div className="font-semibold">Username</div>
                <div className="text-[#555554]">{allegationData?.username}</div>
              </div>
              <div>
                <div className="font-semibold">Email</div>
                <div className="text-[#555554]">{allegationData?.email}</div>
              </div>
              <div>
                <div className="font-semibold">Allegation Description</div>
                <div className="text-[#555554]">{allegationData?.message}</div>
              </div>
              {allegationData?.photos && (
                <div>
                  <div className="font-semibold mb-2">Allegation Images</div>
                  <div className="grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                    {allegationData?.photos?.map((photo, index) => (
                      <img
                        key={index}
                        src={photo?.url}
                        alt={`photo-${index}`}
                        className="w-[400px] h-[300px] object-fit"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="mt-1 flex gap-3 py-10 flex-wrap justify-center w-full border-[1px] border-[#e0e0dc]">
              <div className="relative">
                {denyMutation.isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center right-3">
                    <Loader />
                  </div>
                )}
                <button
                  onClick={() => setShowModal(true)}
                  className={`px-28 py-2 text-white rounded font-bold hover:bg-[#d92d2d] bg-red `}
                >
                  DENY
                </button>
              </div>
              <ModalField
                confirmButtonTitle={"Confirm"}
                modalHeader={"Confirm Denial"}
                modalContent={"Are you sure you want to deny the allegation?"}
                handleCloseModal={() => setShowModal(false)}
                isLoading={denyMutation.isLoading}
                showModal={showModal}
                onClickConfirmButton={() => denyMutation.mutate()}
              />
              <div>
                <button
                  className="px-28 py-2 bg-greenC text-white rounded font-bold hover:bg-hoverGreen"
                  onClick={() =>
                    navigate(`/payment-summary?${MULTIPLY_PAYMENT}`)
                  }
                >
                  PAY
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {/* Ref to the bottom of the content */}
      <div ref={bottomRef}></div>
    </div>
  );
};
