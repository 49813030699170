import React, { useState } from "react";
import PlainHeader from "../../../common/PlainHeader";
import { useForm } from "react-hook-form";
import Loader from "../../Loader";
import { useLocation } from "react-router-dom";
import { apiClient } from "../../../config/axios";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const ResetPassword = () => {
  const location = useLocation();
  const [passwordType, setPasswordType] = useState("password");
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const resetMutation = useMutation((formData) =>
    apiClient.post(`api/auth/reset-password`, formData)
  );

  const onResetSubmit = async (formData) => {
    const resetData = {
      ...formData,
      token: token,
    };
    try {
      await resetMutation.mutateAsync(resetData);
      toast.success("Password reset successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to reset password");
    }
  };

  return (
    <>
      <PlainHeader />
      <div className="login-container">
        <div className="login-wrapper">
          <form className="login-form" onSubmit={handleSubmit(onResetSubmit)}>
            <div className="mt-8">
              <div className="flex flex-col items-center mb-4">
                <h2 className="mb-3">Reset your password</h2>
                <p className="mb-2">Enter your new password below</p>
              </div>
              <div className="w-full max-w-sm mx-auto">
                <div className="mb-4 relative">
                  <input
                    type={passwordType}
                    name="password"
                    {...register("password", {
                      required: "Password is required",
                    })}
                    placeholder="New Password"
                    className="h-12 w-full p-2 rounded-md bg-[#f3f3f0]"
                  />
                  <span
                    className="absolute cursor-pointer flex items-center top-[16px] right-[10px]"
                    onClick={() => togglePassword()}
                  >
                    {passwordType === "password" ? <FiEyeOff /> : <FiEye />}
                  </span>
                  {errors.password && (
                    <p className="text-red-600 mt-2">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-4 w-full max-w-sm mx-auto">
                <div className="relative flex items-center justify-center">
                  {resetMutation.isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Loader />
                    </div>
                  )}
                  <button
                    type="submit"
                    disabled={resetMutation.isLoading}
                    className={`h-12 w-full p-2 rounded-md text-white bg-primaryColor hover:bg-hoverPrimary ${
                      resetMutation.isLoading
                        ? "cursor-not-allowed opacity-50"
                        : ""
                    }`}
                  >
                    Reset Password
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="mt-4">
            Back to{" "}
            <a
              className="text-primaryColor font-semibold cursor-pointer no-underline"
              href="/login"
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
