import { z } from "zod";

export const WaitingListSchema = z.object({
  email: z.string().min(1, "This is a required question").email({
    message: "Invalid email format",
  }),
  // username: z.string().min(1, { message: "This is a required question" }),
  firstName: z.string().min(1, { message: "This is a required question" }),
  lastName: z.string().min(1, { message: "This is a required question" }),
  // unsolicited_unexpected_unwanted: z.object({
  //   from_stranger: z.string().optional(),
  //   from_someone_you_know: z.string().optional(),
  //   from_someone_you_work_with: z.string().optional(),
  //   from_someone_anonymously: z.string().optional(),
  //   of_sexual_nature: z.string().optional(),
  //   of_bullying_nature: z.string().optional(),
  // }),
  // explicit_types_of_messages: z.object({
  //   identifiable_stranger: z.string().optional(),
  //   known_person: z.string().optional(),
  //   someone_anonymously: z.string().optional(),
  // }),
  // sense_of_respond: z.object({
  //   hate: z.string().optional(),
  //   dislike: z.string().optional(),
  //   flattering: z.string().optional(),
  //   infrequent: z.string().optional(),
  //   indifferent: z.string().optional(),
  //   not_applicable: z.string().optional(),
  // }),
  // moderate_tools: z.object({
  //   yes_and: z.string().optional(),
  //   no_and: z.string().optional(),
  //   yes_but: z.string().optional(),
  //   no_but: z.string().optional(),
  // }),
});
