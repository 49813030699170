import React, { useEffect, useState } from "react";

const InstagramEmulator = ({
  apiEndpoint,
  onClose,
  showToast,
  setShowToast,
}) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const openDeviceEmulator = () => {
      const device_settings = {
        height: 600,
        width: 400,
      };

      const { width, height } = device_settings;

      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;

      const left = (screenWidth - width) / 2;
      const top = (screenHeight - height) / 2;

      try {
        const popupWindow = window.open(
          apiEndpoint,
          "_blank",
          `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`
        );

        if (!popupWindow) {
          throw new Error(
            "Failed to open the new window. Please disable your popup blocker."
          );
        }

        popupWindow.document.title = `Instagram`;

        const popupCheckInterval = setInterval(() => {
          if (popupWindow.closed) {
            clearInterval(popupCheckInterval);
            console.log("Popup window closed");
            alert("Accept the challenge required button to proceed");
            onClose();
          }
        }, 500);
      } catch (error) {
        setError(error.message);
        setShowToast(true); // Show the toast on error
      }
    };

    openDeviceEmulator();
  }, [apiEndpoint, onClose]);

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <>
      {/* Backdrop when the toast is visible */}
      {showToast && <div className="fixed inset-0 bg-black opacity-50 z-40" />}

      {/* Toast Notification */}
      {showToast && (
        <div className="fixed top-5 right-5 bg-red text-white p-4 rounded shadow-lg z-50">
          <div className="flex justify-between items-center">
            <p>{error}</p>
            <button
              className="text-white ml-4 hover:underline"
              onClick={closeToast}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InstagramEmulator;
