import { instagramIcon } from "../../assets/icons/icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useState } from "react";
import BeeperComponent from "../../common/BeeperComponent";

const Instagram = ({ connectFBStatus }) => {
  const [openBeeperModal, setOpenBeeperModal] = useState(false);

  const JoinBetaPopOver = (
    <Popover id="popover-basic" className="w-full">
      <Popover.Header
        as="h3"
        className="flex flex-col gap-3 px-6 py-2 text-left "
      >
        <p className="b-0 ml-[12px] text-sm font-poppins">
          Do you have either a business or creator Instagram account?
        </p>
      </Popover.Header>
      <Popover.Body className="flex flex-col gap-5 p-1">
        <div className="text-sm font-poppins w-full">
          <button className=" px-6 py-2 font-semibold text-[#515050] w-full text-left">
            <div className="flex flex-col gap-1">
              <div>
                To participate, you need to have either a business or creator
                Instagram account. If you currently have a personal account, you
                won't be able to connect.
              </div>

              <div>
                Need help switching your account type? Follow the instructions
                below:
                <li className="list-disc">
                  <a
                    href="https://help.instagram.com/502981923235522"
                    className=" font-bold "
                    target="_blank"
                    rel="noreferrer"
                  >
                    Switch to Business Account
                  </a>{" "}
                </li>
                <li className="list-disc">
                  <a
                    href="https://help.instagram.com/2358103564437429"
                    target="_blank"
                    className=" font-bold"
                    rel="noreferrer"
                  >
                    Switch to Creator Account
                  </a>{" "}
                </li>
              </div>
            </div>
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  const handleOpenModal = () => {
    setOpenBeeperModal(true);
  };

  const handleCloseModal = () => {
    setOpenBeeperModal(false);
  };

  return (
    <>
      <OverlayTrigger placement="bottom" overlay={JoinBetaPopOver}>
        <div
          className={`relative rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full ${
            connectFBStatus ? "hover:border-[#bcbaba]" : ""
          } `}
        >
          <div className="flex items-center justify-between w-full">
            <img src={instagramIcon} alt="facebook-icon" className="w-5 h-5" />
            <div className="bg-primaryColor text-white px-1 rounded-[4px] font-zain text-[9px] flex">
              launching in 2 weeks
            </div>
          </div>
          <button
            className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10"
            // onClick={handleOpenModal}
          >
            Connect to Instagram
          </button>
        </div>
      </OverlayTrigger>
      <BeeperComponent
        showModal={openBeeperModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default Instagram;
