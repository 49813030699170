import React from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="flex flex-col mt-40 justify-center items-center">
      <h1 className="text-9xl mb-2">404</h1>
      <h1 className="text-5xl mb-10">Page Not Found</h1>
      <div
        className="flex flex-row gap-2 items-center bg-primaryColor py-2 px-3 text-white rounded-sm cursor-pointer"
        onClick={handleGoBack}
      >
        <RiArrowLeftLine />
        Go Back
      </div>
    </div>
  );
};

export default PageNotFound;
